import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/common/pageWrapper";
import { history } from "../../../helpers/history";
import { APP_URLS } from "../../../navigation";
import QuizTable from "../../../components/quiz/QuizTable";
import Header from "../../../components/common/header";
import MainHeader from "../../../components/MainHeader/MainHeader";
import Footer from "../../../components/Footer/Footer";
import { useAppSelector } from "redux/hooks";
import { AcademicCapIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function QuizList() {
  const [isLoading, setIsLoading] = useState(true);

  const quizzes = useAppSelector((state) => state.quiz.quizzes);
  

  useEffect(() => {
    document.title = "Quizzes | BrainCargo®";
    // Simulate loading
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Header Section */}
          <div
            className="bg-gradient-to-r from-indigo-600 to-purple-600 dark:from-indigo-700 dark:to-purple-700 
                         rounded-t-2xl p-8 shadow-lg"
          >
            <MainHeader title="Quizzes" />
            <p className="text-indigo-50 text-lg mt-2">
              Test your knowledge and track your progress
            </p>
          </div>

          {/* Main Content */}
          <div className="bg-white dark:bg-gray-800 rounded-b-2xl shadow-lg transition-all duration-200">
            <div className="p-6">
              {/* Action Bar */}
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                <div className="max-w-2xl">
                  <p className="text-gray-600 dark:text-gray-300">
                    Manage and take quizzes to test your knowledge. Create new
                    quizzes or attempt existing ones.
                  </p>
                </div>
                <button
                  onClick={() => history.navigate(APP_URLS.QUIZZES_CREATE)}
                  className="inline-flex items-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 
                           dark:bg-indigo-500 dark:hover:bg-indigo-600 text-white rounded-lg 
                           transition-all duration-200 shadow-sm"
                >
                  <PlusIcon className="w-5 h-5 mr-2" />
                  Create Quiz
                </button>
              </div>

              {/* Loading State */}
              {isLoading ? (
                <div className="animate-pulse space-y-4">
                  <div className="h-12 bg-gray-200 dark:bg-gray-700 rounded-lg w-full" />
                  {[1,2,3].map((i) => (
                    <div key={i} className="space-y-3">
                      <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                      <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700">
                  <QuizTable
                    onAdd={() => history.navigate(APP_URLS.QUIZZES_CREATE)}
                  />
                </div>
              )}

              {/* Empty State */}
              {!isLoading && false && (
                <div className="text-center py-12">
                  <div
                    className="mx-auto w-24 h-24 bg-indigo-100 dark:bg-indigo-900/30 
                                rounded-full flex items-center justify-center mb-4"
                  >
                    <AcademicCapIcon className="w-12 h-12 text-indigo-600 dark:text-indigo-400" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    No Quizzes Available
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400 max-w-sm mx-auto mb-6">
                    Create your first quiz to start testing knowledge and
                    tracking progress
                  </p>
                  <button
                    onClick={() => history.navigate(APP_URLS.QUIZZES_CREATE)}
                    className="inline-flex items-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 
                             dark:bg-indigo-500 dark:hover:bg-indigo-600 text-white rounded-lg 
                             transition-all duration-200"
                  >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Create Your First Quiz
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Stats Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            {[
              { title: "Total Quizzes", value: quizzes.count, color: "indigo" },
              { title: "Completed", value: "12", color: "green" },
              { title: "Average Score", value: "85%", color: "blue" },
            ].map((stat, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 
                            transition-all duration-200"
              >
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {stat.title}
                </h3>
                <p
                  className={`text-3xl font-bold mt-2 text-${stat.color}-600 dark:text-${stat.color}-400`}
                >
                  {stat.value}
                </p>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
