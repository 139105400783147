import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createDataSet,
  retrieveDataSets,
  removeDataSet,
  selectDataSets as selectDataSetsService,
} from "../../services/datasetService";
import { PaginatedList, QueryParams } from "../../types/api";
import { DataSet } from "../../types/dataset";
import { ErrorMessages } from "../../types/redux/slice";

interface DataSetsState {
  dataSets: PaginatedList<DataSet>;
  selectedFeedDataSet: DataSet | null;
  errorMessages: ErrorMessages;
  pendingGetDataSets: boolean;
  pendingAddDataSet: boolean;
  pendingDeleteDataSet: boolean;
  pendingSelectDataSets: boolean;
}

const initialState: DataSetsState = {
  dataSets: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  selectedFeedDataSet: null,
  errorMessages: {},
  pendingGetDataSets: false,
  pendingAddDataSet: false,
  pendingDeleteDataSet: false,
  pendingSelectDataSets: false,
};

export const dataSetsSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    setSelectedFeedDataSet: (state, action) => {
      state.selectedFeedDataSet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataSets.pending, (state, action) => {
      state.pendingGetDataSets = true;
      state.errorMessages = {};
    });
    builder.addCase(getDataSets.fulfilled, (state, action) => {
      state.dataSets = action.payload;
      state.pendingGetDataSets = false;
    });
    builder.addCase(getDataSets.rejected, (state, action) => {
      state.pendingGetDataSets = false;
      state.errorMessages = action.payload || {};
    });

    builder.addCase(addDataSet.pending, (state, action) => {
      state.pendingAddDataSet = true;
      state.errorMessages = {};
    });
    builder.addCase(addDataSet.fulfilled, (state, action) => {
      state.pendingAddDataSet = false;
    });
    builder.addCase(addDataSet.rejected, (state, action) => {
      state.pendingAddDataSet = false;
      state.errorMessages = action.payload || {};
    });

    builder.addCase(deleteDataSet.pending, (state, action) => {
      state.pendingDeleteDataSet = true;
      state.errorMessages = {};
    });
    builder.addCase(deleteDataSet.fulfilled, (state, action) => {
      state.pendingDeleteDataSet = false;
    });
    builder.addCase(deleteDataSet.rejected, (state, action) => {
      state.pendingDeleteDataSet = false;
      state.errorMessages = action.payload || {};
    });

    builder.addCase(selectDataSets.pending, (state, action) => {
      state.pendingSelectDataSets = true;
      state.errorMessages = {};
    });
    builder.addCase(selectDataSets.fulfilled, (state, action) => {
      state.pendingSelectDataSets = false;
    });
    builder.addCase(selectDataSets.rejected, (state, action) => {
      state.pendingSelectDataSets = false;
      state.errorMessages = action.payload || {};
    });
  },
});

export const getDataSets = createAsyncThunk<
  PaginatedList<DataSet>,
  { url?: string | null | undefined; queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("datasets/getDataSets", async ({ url = "", queryParams = {} }, thunkApi) => {
  try {
    console.log("url", url);
    console.log("queryParams", queryParams);
    const response = await retrieveDataSets(url, queryParams);
    console.log("response", response);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const addDataSet = createAsyncThunk<
  DataSet,
  FormData,
  { rejectValue: ErrorMessages }
>("datasets/addDataSet", async (data, thunkApi) => {
  try {
    const response = await createDataSet(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const deleteDataSet = createAsyncThunk<
  DataSet,
  string,
  { rejectValue: ErrorMessages }
>("datasets/deleteDataSet", async (data, thunkApi) => {
  try {
    const response = await removeDataSet(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const selectDataSets = createAsyncThunk<
  { data: { user_data_sets: string[] } },
  { data: { user_data_sets: string[] } },
  { rejectValue: ErrorMessages }
>("datasets/selectDataSets", async ({ data }, thunkApi) => {
  try {
    const response = await selectDataSetsService(data);
    thunkApi.dispatch(getDataSets({}));
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const { setSelectedFeedDataSet } = dataSetsSlice.actions;
