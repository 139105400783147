import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { PaginatedList, QueryParams } from "types/api";
import { useDebounce } from "use-debounce";
import { DataTableLoader } from "components/common/ShimmerLoader";
import SearchInput from "components/common/searchInput";
import FeedDataTableHeader from "./FeedDataTableHeader";
import FeedDataTableRow from "./FeedDataTableRow";
import PaginationControls from "./PaginationControls";

interface Props {
  headerText: string;
  defaultOrdering: string;
  paginatedList: PaginatedList<any>;
  fetchThunkFunction: any;
  pending: boolean;
  labels: { [key: string]: string };
  additionalQueryParams?: QueryParams;
  onClickRow: (itemId: string) => void;
  valueOverrides?: { [key: string]: (val: any) => any };
}

/**
 * A table that displays a paginated list of items.
 */
export default function FeedDataTable({
  headerText,
  defaultOrdering,
  paginatedList,
  fetchThunkFunction,
  pending,
  labels,
  additionalQueryParams = {},
  valueOverrides = {},
  onClickRow,
}: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(defaultOrdering);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const dispatch = useAppDispatch();

  function fetchData(page: number, query: string) {
    const queryParams: QueryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      query,
      ordering,
      ...additionalQueryParams,
    };
    dispatch(fetchThunkFunction({ queryParams }));
  }

  useEffect(() => {
    fetchData(page, debouncedQuery);
  }, [page, debouncedQuery, ordering]);

  if (pending) {
    return (
      <div className="p-6">
        <DataTableLoader />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h4 className="text-xl font-semibold text-white">{headerText}</h4>

      <div className="w-full">
        <div className="mb-4">
          <SearchInput value={query} onChange={setQuery} />
        </div>

        <div>
          <div className="overflow-x-auto rounded-lg border border-gray-700/50">
            <table className="w-full text-sm text-left">
              <FeedDataTableHeader
                labels={labels}
                ordering={ordering}
                onOrderingChange={setOrdering}
              />
              <tbody className="divide-y divide-gray-700/50">
                {paginatedList.results.map((elem) => (
                  <FeedDataTableRow
                    key={elem.id}
                    elem={elem}
                    labels={labels}
                    valueOverrides={valueOverrides}
                    onRowClick={onClickRow}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <PaginationControls
            currentPage={page}
            onPageChange={setPage}
            totalResults={paginatedList.count}
            currentResults={paginatedList.results.length}
            hasNextPage={!!paginatedList.next}
            hasPreviousPage={!!paginatedList.previous}
          />
        </div>
      </div>
    </div>
  );
}
