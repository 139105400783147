import React from "react";
import { FeedItem as FeedItemType } from "types/feed";
import { EmptyFeedState } from "./components/EmptyFeedState";
import { FeedItem } from "./components/FeedItem";

interface FeedItemRendererProps {
  feedItems: FeedItemType[];
  currFeedItemIdx: number;
  playing: boolean;
  loading: boolean;
  spotifyIFrameAPI: any;
}

/**
 * Pure presentation component for rendering feed items
 */
export function FeedItemRenderer({
  feedItems,
  currFeedItemIdx,
  playing,
  loading,
  spotifyIFrameAPI,
}: FeedItemRendererProps) {
  if (feedItems.length === 0) {
    return <EmptyFeedState loading={loading} />;
  }

  return (
    <>
      {feedItems.map((feedItem, idx) => (
        <FeedItem
          key={`${feedItem.id}-${idx}`}
          feedItem={feedItem}
          index={idx}
          isPlaying={idx === currFeedItemIdx ? playing : false}
          spotifyIFrameAPI={spotifyIFrameAPI}
        />
      ))}
    </>
  );
} 