import React from 'react';
import { Spinner } from "flowbite-react";
import { TRANSITION_STYLE_PROPERTY, getComponentHeight, SEEK_BAR_HEIGHT } from "./utils";

interface FeedLoadingStateProps {
  loading: boolean;
  overlayRef: React.RefObject<HTMLDivElement>;
}

/**
 * Component to handle loading states in the feed.
 * Displays a loading spinner and manages the overlay height.
 */
export const FeedLoadingState: React.FC<FeedLoadingStateProps> = ({
  loading,
  overlayRef,
}) => {
  return (
    <div
      className="absolute left-0 top-0 right-0 bottom-0 z-40 flex"
      ref={overlayRef}
      draggable
      style={{
        ...TRANSITION_STYLE_PROPERTY,
        height: loading
          ? getComponentHeight()
          : getComponentHeight() - SEEK_BAR_HEIGHT,
      }}
    >
      {loading && (
        <div className="bg-gray-100 justify-center items-center flex w-full h-full">
          <Spinner size="xl" />
        </div>
      )}
    </div>
  );
}; 