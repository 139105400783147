import { StripeNFTProduct } from "../../types/payments";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Modal from "../../components/common/modal";
import {
  fetchCheckoutUrl,
  fetchNFTProducts,
} from "../../redux/payments/paymentsSlice";
import NFTProductClickable from "./NFTProductClickable";
import { isDispatchResponseError } from "../../redux/utils";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  walletAddress: string | undefined | null;
}

export default function NFTProductSelectionModal({
  show,
  setShow,
  walletAddress,
}: Props) {
  const dispatch = useAppDispatch();
  const nftProducts = useAppSelector((state) => state.payments.nftProducts);
  const pendingFetchNFTProducts = useAppSelector(
    (state) => state.payments.pendingFetchNFTProducts
  );
  const [selectedProduct, setSelectedProduct] =
    useState<StripeNFTProduct | null>(null);

  const [processingProductId, setProcessingProductId] = useState<string | null>(
    null
  );

  function onClose() {
    setShow(false);
    setSelectedProduct(null);
  }

  async function onConfirm(product: StripeNFTProduct) {
    setProcessingProductId(product.id);
    setSelectedProduct(product);

    if (!product || !walletAddress) {
      setProcessingProductId(null);
      return;
    }
    const response = await dispatch(
      fetchCheckoutUrl({ priceId: product.price.id, walletAddress })
    );

    setProcessingProductId(null);

    if (!isDispatchResponseError(response)) {
      window.location.href = response.payload as string;
    }
  }

  useEffect(() => {
    dispatch(fetchNFTProducts());
  }, []);

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideFooter
      body={
        <div className="bg-[#1a1b1e]">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-8 text-center">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-3">
              Select NFT to Purchase
            </h2>
            <p className="text-blue-100 text-base sm:text-lg">
              Choose from our collection of exclusive NFTs. Each comes with
              BRAIN tokens as a reward.
            </p>
          </div>

          {/* Content Section */}
          <div className="p-6 sm:p-8">
            {pendingFetchNFTProducts ? (
              <div className="flex flex-col items-center justify-center py-16">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
                <p className="mt-4 text-gray-400">Loading NFT collection...</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6">
                {nftProducts?.map((product: StripeNFTProduct) => (
                  <NFTProductClickable
                    key={product.id}
                    data={product}
                    onClick={() => onConfirm(product)}
                    selected={selectedProduct?.id === product.id}
                    isLoading={processingProductId === product.id}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      }
    />
  );
}
