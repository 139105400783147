import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  clearFeedItems,
  dislikeFeedItem,
  getFeedItem,
  getRandomFeedItems,
  likeFeedItem,
  skipFeedItem,
} from "redux/feed/feedItemsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PlaylistsDrawer from "./playlists/PlaylistsDrawer";
import ScrollableMobileFeedElement from "./ScrollableMobileFeedElement";
import { FeedItem } from "types/feed";
import { isDispatchResponseError } from "redux/utils";
import { initiateSpotifyMobileFeed } from "helpers/spotifyIFrameAPI";
import { handleTouchEnd, handleTouchMove, handleTouchStart } from "./handlers";
import { AddItemToPlaylistPayload, PlaylistItem } from "types/playlist";
import { addItemToPlaylists, getPlaylist } from "redux/playlist/playlistSlice";
import LikePopup from "./popups/LikePopup";
import DislikePopup from "./popups/DislikePopup";
import {
  generateFeedItemHtmlId,
  updateFeedItemZIndexes,
  SEEK_BAR_HEIGHT,
  TRANSITION_STYLE_PROPERTY,
  getComponentHeight,
} from "./utils";
import { Spinner } from "flowbite-react";
import { setCurrFeedItem } from "redux/feed/feedItemReportsSlice";
import { QueryParams } from "types/api";
import { useFeedItemManager } from "./FeedItemManager";
import { useTouchInteractions } from "../../hooks/useTouchInteractions";
import { usePlaylistIntegration } from "../../hooks/usePlaylistIntegration";
import { FeedItemRenderer } from "./FeedItemRenderer";
import { FeedLoadingState } from "./FeedLoadingState";

interface Props {
  // Id of a feed item to be displayed first
  feedItemId?: string;
  // Id of a playlist to play feed items from
  playlistId?: string;
}

/**
 * Scrollable feed for mobile devices that supports gestures.
 */
export default function ScrollableMobileFeed({
  feedItemId,
  playlistId,
}: Props) {
  const dispatch = useAppDispatch();

  const [playing, setPlaying] = useState(false);
  const [currFeedItemIdx, setCurrFeedItemIdx] = useState(0);
  const [showPlaylistsDrawer, setShowPlaylistsDrawer] = useState(false);
  const [spotifyIFrameAPI, setSpotifyIFrameAPI] = useState<any>(null);
  const [showLikePopup, setShowLikePopup] = useState(false);
  const [showDislikePopup, setShowDislikePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstFetchComplete, setFirstFetchComplete] = useState(false);
  const [feedItems, setFeedItems] = useState<FeedItem[]>([]);

  const overlayRef = useRef<HTMLDivElement | null>(null);

  const {
    handleLikeFeedItem,
    handleDislikeFeedItem,
    handleAddToDefaultPlaylist,
    handleSkipFeedItem,
    fetchAdditionalFeedItems
  } = useFeedItemManager({
    feedItemId,
    playlistId,
    onFeedItemsChange: setFeedItems,
    onLoadingChange: setLoading,
    onFirstFetchComplete: () => setFirstFetchComplete(true),
    currFeedItemIdx
  });

  const {
    fetchPlaylistFeedItems,
    defaultPlaylist,
  } = usePlaylistIntegration({
    playlistId,
    onFeedItemsChange: setFeedItems,
    onLoadingChange: setLoading,
    onFirstFetchComplete: () => setFirstFetchComplete(true),
  });

  useTouchInteractions({
    feedItems,
    currFeedItemIdx,
    playing,
    overlayRef,
    setShowPlaylistsDrawer,
    setPlaying,
    setCurrFeedItemIdx,
    setShowLikePopup,
    setShowDislikePopup,
    handleLikeFeedItem,
    handleDislikeFeedItem,
    handleAddToDefaultPlaylist,
    handleSkipFeedItem,
    onNearEnd: () => {
      if (!playlistId && !feedItemId) {
        fetchAdditionalFeedItems();
      }
    }
  });

  /**
   * Retrieve feed items from the backend.
   */
  async function retrieveFeedItems() {
    if (feedItemId) {
      return dispatch(getFeedItem({ id: feedItemId }));
    } else {
      const queryParams: QueryParams = {};
      if (feedFilterTypes) {
        queryParams["types"] = feedFilterTypes.join(",");
      }
      if (selectedFeedDataSet) {
        queryParams["dataset"] = selectedFeedDataSet.id;
      }
      return dispatch(
        getRandomFeedItems({
          queryParams,
        }),
      );
    }
  }

  /**
   * Fetch feed item or items from the backend.
   */
  async function fetchFeedItems() {
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      const payload = response.payload;
      if (Array.isArray(payload)) {
        setFeedItems(payload as FeedItem[]);
      } else {
        setFeedItems([payload as FeedItem]);
      }
    }
    setFirstFetchComplete(true);
  }

  const feedFilterTypes = useAppSelector(
    (state) => state.feedFilterTypes.feedFilterTypes,
  );
  const selectedFeedDataSet = useAppSelector(
    (state) => state.dataSets.selectedFeedDataSet,
  );
  const pendingGetRandomFeedItems = useAppSelector(
    (state) => state.feedItems.pendingGetRandomFeedItems,
  );
  const pendingGetFeedItem = useAppSelector(
    (state) => state.feedItems.pendingGetFeedItem,
  );

  /**
   * Fetch feed items whenever the component mounts and append Spotify setup script.
   */
  useEffect(() => {
    // Append Spotify script used by SpotifyTrack component
    initiateSpotifyMobileFeed(setSpotifyIFrameAPI);
    const script = document.createElement("script");
    script.src = "https://open.spotify.com/embed/iframe-api/v1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Fetch feed items whenever feedFilterTypes and selectedFeedDataSet change.
   */
  useEffect(() => {
    // Reload feed items if feedFilterTypes changes
    setCurrFeedItemIdx(0);
    setPlaying(false);
    dispatch(clearFeedItems());

    // Fetch feed items
    if (playlistId) {
      fetchPlaylistFeedItems();
    } else {
      fetchFeedItems();
    }
  }, [feedFilterTypes, selectedFeedDataSet]);

  /**
   * Stop feed items from playing whenever currFeedItemIdx changes.
   */
  useEffect(() => {
    setPlaying(false);
  }, [currFeedItemIdx]);

  /**
   * Set loading state whenever feed items are fetched.
   */
  useEffect(() => {
    const isEmpty = firstFetchComplete && feedItems.length === 0;
    const newLoading =
      (pendingGetRandomFeedItems || pendingGetFeedItem) && !isEmpty;
    if (newLoading) {
      // Immediately set loading to true in order to display loading indicator
      setLoading(newLoading);
    } else {
      // Add a delay to setting loading to false.
      // It's done in order to hide loading states of embeds.
      setTimeout(() => {
        setLoading(newLoading);
      }, 2500);
    }
  }, [pendingGetRandomFeedItems, pendingGetFeedItem, feedItems, firstFetchComplete]);

  /**
   * Update feedItemReportsSlice -> currFeedItem whenever currFeedItemIdx changes
   * so that ReportFeedItemModal can display the correct feed item.
   */
  useEffect(() => {
    if (currFeedItemIdx < feedItems.length) {
      dispatch(setCurrFeedItem(feedItems[currFeedItemIdx]));
    } else {
      dispatch(setCurrFeedItem(null));
    }
  }, [feedItems, currFeedItemIdx]);

  return (
    <div className="relative h-full overflow-hidden">
      <FeedLoadingState loading={loading} overlayRef={overlayRef} />
      
      {/* Feed Items */}
      <div className="relative h-full">
        <FeedItemRenderer
          feedItems={feedItems}
          currFeedItemIdx={currFeedItemIdx}
          playing={playing}
          loading={loading}
          spotifyIFrameAPI={spotifyIFrameAPI}
        />
      </div>

      {/* Popups and Drawers */}
      {feedItems[currFeedItemIdx] && (
        <PlaylistsDrawer
          currItemId={feedItems[currFeedItemIdx].id}
          show={showPlaylistsDrawer}
          setShow={setShowPlaylistsDrawer}
          incrementCurrFeedItemIdx={() => setCurrFeedItemIdx(currFeedItemIdx + 1)}
          closeMode="drag"
        />
      )}
      <LikePopup showLikePopup={showLikePopup} />
      <DislikePopup showDislikePopup={showDislikePopup} />
    </div>
  );
}
