import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { useParams } from "react-router-dom";
import FeedDataNewsDetailsComponent from "components/admin/feedData/details/FeedDataNewsDetails";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Feed data news details page.
 */
export default function FeedDataNewsDetails() {
  const { newsId } = useParams();

  useEffect(() => {
    document.title = "Feed Data | BrainCargo®";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col bg-[#1a1f2b]">
        {/* Header with blur effect */}
        <div className="bg-[#1e2533] border-b border-gray-700/50">
          <MainHeader title="News Details" />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="max-w-[1400px] mx-auto">
            <div className="bg-[#1e2533]/80 rounded-xl border border-white/5 backdrop-blur-sm">
              <FeedDataNewsDetailsComponent newsId={newsId || ""} />
            </div>
          </div>
        </div>

        {/* Footer with blur effect */}
        <Footer />
      </div>
    </PageWrapper>
  );
}
