import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/common/pageWrapper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { displayErrors } from "../../../helpers/errors";
import QuizResultsComponent from "../../../components/quiz/QuizResults";
import { retrieveQuizzes } from "../../../redux/quiz/quizSlice";
import { Quiz } from "../../../types/quiz";
import Header from "../../../components/common/header";
import MainHeader from "../../../components/MainHeader/MainHeader";
import Footer from "../../../components/Footer/Footer";
import { Spinner } from "flowbite-react";

export default function QuizResults() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const quizzes = useAppSelector((store) => store.quiz.quizzes);
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes
  );
  const quizzesErrorMessages = useAppSelector(
    (state) => state.quiz.quizzesErrorMessages
  );
  const [quiz, setQuiz] = useState<Quiz | null>(null);

  function renderErrors() {
    if (quizzesErrorMessages) {
      let errors = quizzesErrorMessages?.id;
      if (
        quizzesErrorMessages?.id &&
        quizzesErrorMessages.id.includes("Enter a valid UUID.")
      ) {
        errors = ["Couldn't find a quiz with provided id."];
      }
      return (
        <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
          <p className="text-red-600 dark:text-red-400">
            {displayErrors(errors)}
          </p>
        </div>
      );
    }
  }

  useEffect(() => {
    document.title = "Quiz Results | BrainCargo®";
  }, []);

  useEffect(() => {
    if (quizzes.results.length > 0) {
      setQuiz({ ...quizzes.results[0] });
    } else {
      setQuiz(null);
    }
  }, [quizzes]);

  useEffect(() => {
    if (id) {
      dispatch(retrieveQuizzes({ queryParams: { id } }));
    }
  }, [id]);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg transition-all duration-200">
            <div className="border-b dark:border-gray-700">
              {pendingRetrieveQuizzes ? (
                <div className="p-6 flex items-center space-x-3">
                  <Spinner
                    size="sm"
                    className="text-blue-600 dark:text-blue-400"
                  />
                  <span className="text-gray-600 dark:text-gray-300">
                    Loading quiz results...
                  </span>
                </div>
              ) : (
                <MainHeader title={`${quiz?.name || "Unknown Quiz"} Results`} />
              )}
            </div>

            <div className="p-6">
              {Object.keys(quizzesErrorMessages).length === 0 ? (
                <div className="space-y-6">
                  {quiz && (
                    <div className="text-gray-600 dark:text-gray-300">
                      View the results and statistics for {quiz.name}.
                    </div>
                  )}
                  <QuizResultsComponent quizId={id || null} />
                </div>
              ) : (
                renderErrors()
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
