import React from "react";
import { Spinner } from "flowbite-react";

interface EmptyFeedStateProps {
  loading: boolean;
}

/**
 * Component to display when there are no feed items
 */
export function EmptyFeedState({ loading }: EmptyFeedStateProps) {
  return (
    <div
      className="flex justify-center items-center text-lg bg-neutral-800 text-slate-100 h-full text-center"
      role="status"
      aria-live="polite"
    >
      {loading ? (
        <div className="bg-gray-100 justify-center items-center flex w-full h-full">
          <Spinner size="xl" aria-label="Loading feed items" />
        </div>
      ) : (
        "There isn't anything available for you to watch"
      )}
    </div>
  );
} 