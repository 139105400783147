import { PlusCircleIcon } from "@heroicons/react/24/solid";
import ConfirmationPopup from "../../components/common/modal/ConfirmationPopup";
import PaginationV2 from "../../components/common/pagination/PaginationV2";
import { Spinner } from "flowbite-react";
import { history } from "../../helpers/history";
import { APP_URLS } from "../../navigation";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { removeQuiz, retrieveQuizzes } from "../../redux/quiz/quizSlice";
import { QueryParams } from "../../types/api";
import { Quiz } from "../../types/quiz";

interface Props {
  onAdd: () => void;
  fetchDataQueryParams?: QueryParams;
}

interface AdditionalAction {
  onAction: (itemId: string) => void;
  label: string;
  disabled?: (itemId: string) => boolean;
  hidden?: (itemId: string) => boolean;
}

const KEYS = {
  name: "name",
};
const KEYS_TO_DISPLAY = Object.keys(KEYS);
const KEYS_TO_COLUMN_NAMES = { name: "Quiz Name" };

/**
 * Component responsible for rendering a table of quizzes.
 */
export default function QuizTable({ onAdd, fetchDataQueryParams }: Props) {
  console.log("fetchDataQueryParams", fetchDataQueryParams);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] =
    useState(false);
  const [quizToDeleteId, setQuizToDeleteId] = useState<string>("");
  const user = useAppSelector((state) => state.user.user);
  const quizzes = useAppSelector((state) => state.quiz.quizzes);
  console.log("quizzes", quizzes);
  const pendingRetrieveQuizzes = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizzes
  );
  const pendingRemoveQuiz = useAppSelector(
    (state) => state.quiz.pendingRemoveQuiz
  );

  const ADDITIONAL_ACTIONS: AdditionalAction[] = [
    {
      onAction: (itemId: string) => {
        const url = APP_URLS.QUIZZES_DETAILS.replace(":id", itemId);
        history.navigate(url);
      },
      hidden: (itemId: string) => {
        const quiz = quizzes.results.find((item) => item.id === itemId);
        return !!quiz?.been_taken;
      },
      label: "Take Quiz",
    },
    {
      onAction: (itemId: string) => {
        const url = APP_URLS.QUIZZES_DETAILS.replace(":id", itemId);
        history.navigate(url);
      },
      hidden: (itemId: string) => {
        const quiz = quizzes.results.find((item) => item.id === itemId);
        return !quiz?.been_taken;
      },
      label: "Retake Quiz",
    },
    {
      onAction: (itemId: string) => {
        const url = APP_URLS.QUIZZES_RESULTS.replace(":id", itemId);
        history.navigate(url);
      },
      label: "View Results",
    },
  ];

  /**
   * Fetch quizzes
   */
  function fetchData(data: { url?: string; queryParams?: QueryParams }) {
    // Merge fetchDataQueryParams prop and queryParams argument
    const queryParams = {
      ...(data.queryParams || {}),
      ...(fetchDataQueryParams || {}),
    };
    if (Object.keys(queryParams).length > 0) {
      data.queryParams = queryParams;
    }
    dispatch(retrieveQuizzes(data));
  }

  /**
   * Determine if remove button should be disabled for an item.
   */
  function disableSingleRemoveButton(item: Quiz) {
    return item.created_by !== user?.id;
  }

  /**
   * Determine if edit button should be disabled for an item.
   */
  function disableSingleEditButton(item: Quiz) {
    return item.created_by !== user?.id;
  }

  /**
   * Handle displaying remove confirmation popup.
   */
  function onRemove(itemId: string) {
    setShowRemoveConfirmationPopup(true);
    setQuizToDeleteId(itemId);
  }

  /**
   * Handle removing a quiz.
   */
  function onRemoveConfirm() {
    dispatch(removeQuiz(quizToDeleteId)).then(() => {
      fetchData({});
      setQuizToDeleteId("");
      setShowRemoveConfirmationPopup(false);
    });
  }

  /**
   * Navigate to edit quiz page.
   */
  function onEdit(itemId: string) {
    const url = APP_URLS.QUIZZES_EDIT.replace(":id", itemId);
    history.navigate(url);
  }

  /**
   * Retrieve previous page of quizzes.
   */
  const getPreviousPage = () => {
    const url = quizzes.previous;
    if (url !== undefined && url !== null) {
      fetchData({ url });
      setPage(page - 1);
    }
  };

  /**
   * Retrieve next page of quizzes.
   */
  const getNextPage = () => {
    const url = quizzes.next;
    if (url !== undefined && url !== null) {
      fetchData({ url });
      setPage(page + 1);
    }
  };

  /**
   * Render table records.
   */
  const displayRecords = () => {
    return quizzes.results.map((item: any, idx: number) => (
      <tr
        key={idx}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        {KEYS_TO_DISPLAY.map((key) => {
          return (
            <td key={key} className="px-6 py-4">
              <span className={`${item.is_hidden ? "text-gray-400" : ""}`}>
                {item[key]}
              </span>
            </td>
          );
        })}

        <td className="px-6 py-4 flex flex-col md:flex-row md:space-x-4">
          <button
            onClick={() => onRemove(item.id)}
            className="font-medium enabled:text-red-600 enabled:hover:underline"
            disabled={disableSingleRemoveButton(item)}
            title={
              disableSingleRemoveButton(item)
                ? "You can only remove quizzes that you have created."
                : undefined
            }
            aria-label={`Remove quiz ${item.name}`}
          >
            Remove
          </button>

          <button
            onClick={() => onEdit(item.id)}
            className="font-medium enabled:text-blue-600 enabled:hover:underline"
            disabled={disableSingleEditButton(item)}
            title={
              disableSingleEditButton(item)
                ? "You can only edit quizzes that you have created."
                : undefined
            }
            aria-label={`Edit quiz ${item.name}`}
          >
            Edit
          </button>

          {ADDITIONAL_ACTIONS.map((action) => {
            if (action.hidden && action.hidden(item.id)) {
              return null;
            }
            return (
              <button
                key={action.label}
                onClick={() => action.onAction(item.id)}
                className="font-medium enabled:text-blue-600 enabled:hover:underline"
                disabled={action.disabled ? action.disabled(item.id) : false}
                aria-label={`${action.label} for quiz ${item.name}`}
              >
                {action.label}
              </button>
            );
          })}
        </td>
      </tr>
    ));
  };

  /**
   * Retrieve quizzes whenever fetchDataQueryParams prop changes.
   */
  useEffect(() => {
    fetchData({});
  }, [fetchDataQueryParams]);

  return (
    <>
      <div className="flex flex-col sm:flex-row space-y-1 justify-end items-center bg-gray-800/50">
        <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 space-x-2 justify-items-center items-center bg-gray-800/50 ml-0 sm:ml-auto">
          {user?.is_superuser && (
            <button
              onClick={() => onAdd()}
              className="flex items-center space-x-2 justify-center mx-0 pl-4 pr-5 py-2 text-center text-blue-600 bg-white border-[1.5px] border-blue-600 rounded-lg"
              aria-label="Add Quiz"
            >
              <PlusCircleIcon className="w-5 h-5" />
              <span className="text-sm font-semibold">Add Quiz</span>
            </button>
          )}
        </div>
      </div>

      {pendingRetrieveQuizzes ? (
        <div className="flex justify-center">
          <Spinner size="sm" aria-label="Loading quizzes" />
        </div>
      ) : (
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 border ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
            <tr>
              {Object.entries(KEYS_TO_COLUMN_NAMES).map(([key, columnName]) => (
                <th key={key} scope="col" className="px-6 py-3">
                  {columnName}
                </th>
              ))}
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{displayRecords()}</tbody>
        </table>
      )}

      <PaginationV2
        count={quizzes.count}
        page={page}
        previous={quizzes.previous}
        onClickPrev={getPreviousPage}
        next={quizzes.next}
        onClickNext={getNextPage}
      />

      <ConfirmationPopup
        title="Are you sure you want to remove this quiz?"
        show={showRemoveConfirmationPopup && !!quizToDeleteId}
        onClose={() => setShowRemoveConfirmationPopup(false)}
        onConfirm={onRemoveConfirm}
        pendingConfirm={pendingRemoveQuiz}
      />
    </>
  );
}
