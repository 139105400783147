import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ConfirmationPopup from "../../components/common/modal/ConfirmationPopup";
import Pagination from "../../components/common/pagination/Pagination";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";
import {
  deleteDataSet,
  getDataSets,
  selectDataSets,
} from "../../redux/dataSets/dataSetsSlice";
import UploadDataSetFormPopup from "./UploadDataSetFormPopup";
import { DataSet } from "../../types/dataset";

interface Props {
  headerText?: string;
  subHeaderText?: string;
  submitLabelText?: string;
  headerClassName?: string;
  afterDataSetSelect?: () => void;
  requireSelection?: boolean;
  singularSelection?: boolean;
}

/**
 * A component that allows the user to select datasets.
 */
export default function SelectDataSetsTable({
  headerText = "Select Datasets",
  subHeaderText,
  submitLabelText = "Submit Selection",
  headerClassName,
  afterDataSetSelect,
  requireSelection,
  singularSelection,
}: Props) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const dataSets = useAppSelector((state) => state.dataSets.dataSets);
  const pendingSelectDataSets = useAppSelector(
    (state) => state.dataSets.pendingSelectDataSets
  );
  const pendingGetDataSets = useAppSelector(
    (state) => state.dataSets.pendingGetDataSets
  );
  const pendingDeleteDataSet = useAppSelector(
    (state) => state.dataSets.pendingDeleteDataSet
  );

  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false);
  const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string>("");
  const [selectedDataSets, setSelectedDataSets] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    let newSelectedDataSets = [...selectedDataSets];
    if (singularSelection) {
      if (checked) {
        newSelectedDataSets = [id];
      } else {
        newSelectedDataSets = [];
      }
    } else {
      if (checked) {
        newSelectedDataSets.push(id);
      } else {
        newSelectedDataSets = newSelectedDataSets.filter(
          (datasetId) => datasetId !== id
        );
      }
    }

    setSelectedDataSets(newSelectedDataSets);
  };

  /**
   * Displays the confirmation popup.
   */
  const onRemoveClick = (id: string) => {
    setRecordId(id);
    setShowConfirmationPopup(true);
  };

  /**
   * Deletes the dataset.
   */
  const onConfirmDelete = async () => {
    await dispatch(deleteDataSet(recordId));
    setRecordId("");
    setShowConfirmationPopup(false);
    setPage(0);
    dispatch(getDataSets({}));
  };

  /**
   * Submits the selected datasets.
   */
  const onSubmit = async () => {
    const data = {
      user_data_sets : selectedDataSets,
    };
    const success = await dispatch(selectDataSets({ data }));

    // Run optional function
    if (success && afterDataSetSelect) {
      afterDataSetSelect();
    }
  };

  /**
   * Gets previous page of datasets.
   */
  const getPreviousPage = () => {
    dispatch(getDataSets({ url: dataSets.previous }));
    setPage(page - 1);
  };

  /**
   * Gets next page of datasets.
   */
  const getNextPage = () => {
    dispatch(getDataSets({ url: dataSets.next }));
    setPage(page + 1);
  };

  /**
   * Checks if the item was uploaded by the user.
   */
  function isItemUploadedByUser(item: DataSet) {
    return item.uploaded_by === user?.id;
  }

  /**
   * Maps datasets to table rows.
   */
  const displayRecords = () => {
    return dataSets.results.map((item: DataSet, idx: number) => (
      <tr
        key={idx}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={item.id}
              type="checkbox"
              onChange={onSelect}
              checked={selectedDataSets.includes(item.id || "")}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              aria-label={`Select ${item.name}`}
            />
            <label htmlFor={item.id} className="sr-only">
              Checkbox
            </label>
          </div>
        </td>
        <th scope="row" className="px-6 py-4 font-medium text-gray-900">
          <a
            href="#"
            className="text-blue-600 font-medium underline"
            aria-label={`Dataset ${item.name}`}
          >
            {item.name}
          </a>
        </th>

        <td className="px-6 py-4">
          <button
            onClick={() => onRemoveClick(item?.id || "")}
            className="font-medium enabled:text-red-600 enabled:hover:underline"
            disabled={!isItemUploadedByUser(item)}
            title={
              isItemUploadedByUser(item)
                ? undefined
                : "You can only delete your own datasets"
            }
            aria-label={`Remove ${item.name}`}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onRemoveClick(item?.id || "");
              }
            }}
          >
            Remove
          </button>
        </td>
      </tr>
    ));
  };

  /**
   * Checks if the submit button should be disabled.
   */
  function isSubmitDisabled() {
    if (!requireSelection) return false;
    else return !selectedDataSets.length;
  }

  /**
   * Preselects the datasets that the user has already selected.
   */
  useEffect(() => {
    dispatch(getDataSets({}));

    let newSelectedDataSets: string[] = [];
    if (user?.assigned_datasets) {
      newSelectedDataSets = user.assigned_datasets;
    }
    setSelectedDataSets(newSelectedDataSets);
  }, [user]);

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        {(!!headerText || !!subHeaderText) && (
          <div className="flex flex-col">
            {headerText && (
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {headerText}
              </h2>
            )}
            {subHeaderText && (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {subHeaderText}
              </p>
            )}
          </div>
        )}

        <div className="flex items-center gap-3">
          <button
            onClick={() => setShowFormPopup(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium 
                     bg-white dark:bg-gray-800 text-blue-600 dark:text-blue-400 
                     border border-blue-600 dark:border-blue-400 rounded-lg 
                     hover:bg-blue-50 dark:hover:bg-gray-700 
                     transition-all duration-200 group"
          >
            <PlusCircleIcon className="w-5 h-5 mr-2 transition-transform group-hover:scale-110" />
            Add Dataset
          </button>

          <button
            onClick={onSubmit}
            disabled={isSubmitDisabled()}
            className={`
              inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg
              transition-all duration-200
              ${
                isSubmitDisabled()
                  ? "bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-500 cursor-not-allowed"
                  : "bg-blue-600 dark:bg-blue-500 text-white hover:bg-blue-700 dark:hover:bg-blue-600"
              }
            `}
          >
            {pendingSelectDataSets ? (
              <Spinner size="sm" className="mr-2" />
            ) : null}
            {submitLabelText}
          </button>
        </div>
      </div>

      {/* Table Section */}
      {pendingGetDataSets ? (
        <div className="space-y-4 animate-pulse">
          <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded-lg w-full" />
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className="h-16 bg-gray-200 dark:bg-gray-700 rounded-lg w-full"
            />
          ))}
        </div>
      ) : (
        <div
          className="overflow-hidden rounded-xl border border-gray-200 dark:border-gray-700 
                      bg-white dark:bg-gray-800 shadow-sm"
        >
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase bg-gray-50 dark:bg-gray-700/50">
                <tr>
                  <th scope="col" className="p-4">
                    <span className="sr-only">Select</span>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 font-medium text-gray-700 dark:text-gray-200"
                  >
                    Dataset Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 font-medium text-gray-700 dark:text-gray-200"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {displayRecords()}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Pagination */}
      <div className="mt-6">
        <Pagination
          count={dataSets.count}
          page={page}
          previous={dataSets.previous}
          getPreviousPage={getPreviousPage}
          next={dataSets.next}
          getNextPage={getNextPage}
        />
      </div>

      {/* Modals */}
      <ConfirmationPopup
        title="Are you sure you want to delete this dataset?"
        show={showConfirmationPopup && !!recordId}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={onConfirmDelete}
        pendingConfirm={pendingDeleteDataSet}
      />
      <UploadDataSetFormPopup
        show={showFormPopup}
        onClose={() => setShowFormPopup(false)}
      />
    </div>
  );
}
