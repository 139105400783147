import React from 'react';
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

interface FeedDataTableRowProps {
  elem: any;
  labels: { [key: string]: string };
  valueOverrides?: { [key: string]: (val: any) => any };
  onRowClick: (itemId: string) => void;
}

const FeedDataTableRow: React.FC<FeedDataTableRowProps> = ({
  elem,
  labels,
  valueOverrides = {},
  onRowClick,
}) => {
  const renderCell = (key: string, value: any) => {
    // Handle value overrides
    const processedValue = key in valueOverrides ? valueOverrides[key](value) : value;

    // Check if value is a URL
    const valueIsUrl =
      typeof processedValue === "string" &&
      (processedValue.includes("http://") || processedValue.includes("https://"));

    // Check if value is a boolean
    const valueIsBoolean = typeof processedValue === "boolean";

    if (valueIsUrl) {
      return (
        <a
          href={processedValue}
          className="text-blue-600 font-medium underline cursor-pointer"
          target="_blank"
          rel="noreferrer"
          aria-label={`Link to ${processedValue}`}
          onClick={(e) => e.stopPropagation()}
        >
          {processedValue}
        </a>
      );
    }

    if (valueIsBoolean) {
      return processedValue ? (
        <CheckCircleIcon
          className="w-8 text-green-500"
          aria-label="Yes"
        />
      ) : (
        <XCircleIcon 
          className="w-8 text-red-500" 
          aria-label="No" 
        />
      );
    }

    return processedValue;
  };

  return (
    <tr
      key={elem.id}
      className="border-b border-gray-200 hover:cursor-pointer hover:bg-gray-50"
      onClick={() => onRowClick(elem.id)}
      role="button"
      tabIndex={0}
      aria-label={`Row for item ${elem.id}`}
    >
      {Object.entries(labels).map(([key]) => (
        <td className="p-2 text-gray-400" key={key}>
          {renderCell(key, elem[key])}
        </td>
      ))}
    </tr>
  );
};

export default FeedDataTableRow; 