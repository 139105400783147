import React, { useMemo } from "react";
import { TMDBVideo } from "types/feed";

interface Props {
  className?: string;
  video: TMDBVideo | null;
}

/**
 * Video iframe for TMDBMovie component.
 */
export default function TMDBMovieVideo({ className, video }: Props) {
  /**
   * Generate a link to video.
   */
  function getVideoLink(video: TMDBVideo | null) {
    if (!video) {
      return null;
    }

    if (video.site === "YouTube") {
      return `https://www.youtube.com/embed/${video.key}`;
    }

    return null;
  }

  const videoLink = useMemo(() => getVideoLink(video), [video]);
  
  if (!video) return null;
  if (!videoLink) return null;
  
  return (
    <iframe
      className={className}
      src={videoLink}
      title={`Video from ${video.site}`}
      aria-label={`Video from ${video.site}`}
    />
  );
}
