import React, { useEffect } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import MoreInfo from "../../components/common/moreInfo";
import ConnectedAdvertisersAccordion from "../../components/brainCoin/ConnectedAdvertisersAccordion";
import { useAppSelector } from "../../redux/hooks";
import { ConnectWalletModal } from "../../components/wallet/ConnectWalletModal";

// Import icons from heroicons (a popular icon library)
import {
  CurrencyDollarIcon as CoinIcon,
  UserGroupIcon as UsersIcon,
  ChartBarIcon as ChartIcon,
} from "@heroicons/react/24/outline";

interface BrainCoinProps {
  sequenceWalletAddress: string | null | undefined;
  pendingConnect: boolean;
}

export default function BrainCoin({
  sequenceWalletAddress,
  pendingConnect,
}: BrainCoinProps) {
  const advertisers = useAppSelector((state) => state.brainCoin.advertisers);
  const connectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.connectedAdvertisers
  );

  useEffect(() => {
    document.title = "BrainCoin® | BrainCargo®";
  }, []);

  const totalEarnings = advertisers
    .map((item) => item.brain_coin_amount)
    .reduce((a, b) => a + b, 0);
  const averageYield =
    connectedAdvertisers.length > 0
      ? Math.round(
          (connectedAdvertisers.reduce(
            (acc, curr) => acc + (curr.ad_percentage as number),
            0
          ) /
            connectedAdvertisers.length) *
            100
        )
      : 0;

  return (
    <PageWrapper>
      <div className="min-h-screen bg-[#1E2028]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-6">
          {/* Hero Section */}
          <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-blue-600 to-indigo-600">
            <div className="absolute inset-0 bg-grid-white/10 [mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]" />
            <div className="relative px-6 py-12 sm:px-10 sm:py-16">
              <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4">
                Brain Coin Wallet
              </h1>
              <p className="text-lg text-blue-100 max-w-2xl">
                Connect your wallet and manage your advertising preferences to
                earn Brain Coins
              </p>
            </div>
          </div>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-[#252832] rounded-2xl p-6 border border-gray-800 hover:border-blue-500/50 transition-colors">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-500/10 rounded-xl">
                  <CoinIcon className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Total Earnings</p>
                  <p className="text-2xl font-bold text-white mt-1">
                    {totalEarnings} BC
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-[#252832] rounded-2xl p-6 border border-gray-800 hover:border-purple-500/50 transition-colors">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-purple-500/10 rounded-xl">
                  <UsersIcon className="w-6 h-6 text-purple-500" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Active Advertisers</p>
                  <p className="text-2xl font-bold text-white mt-1">
                    {advertisers.length}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-[#252832] rounded-2xl p-6 border border-gray-800 hover:border-green-500/50 transition-colors">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-green-500/10 rounded-xl">
                  <ChartIcon className="w-6 h-6 text-green-500" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Average Yield</p>
                  <p className="text-2xl font-bold text-white mt-1">
                    {averageYield}%
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-[#252832] rounded-2xl border border-gray-800">
            {/* Wallet Section */}
            <div className="p-6 sm:p-8 border-b border-gray-800">
              <h2 className="text-xl font-semibold text-white mb-6 flex items-center">
                Connect Your Wallet
              </h2>
              <div className="flex justify-center">
                <ConnectWalletModal
                  sequenceWalletAddres={sequenceWalletAddress}
                  pendingConnect={pendingConnect}
                />
              </div>
            </div>

            {/* Earn Section */}
            <div className="p-6 sm:p-8">
              <div className="flex items-center gap-3 mb-6">
                <h2 className="text-xl font-semibold text-white">
                  Earn Brain Coins
                </h2>
                <MoreInfo
                  text="Select advertisers you wish to allow to put up ads on your feed. Please note that selected advertisers will gain access to your data."
                  size={20}
                />
              </div>
              <div className="max-w-3xl mx-auto">
                <ConnectedAdvertisersAccordion />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
