import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import AdSenseAdvertMd from "./AdSenseAdvertMd";
import { Spinner } from "flowbite-react";

interface Props {
  className?: string;
}

const ADVERTISER_IDS = {
  GOOGLE: process.env.REACT_APP_GOOGLE_ADVERTISER_ID,
};

/**
 * Component for displaying an ad by a random provider based on a percentage.
 */
export default function AdByRandomProvider({ className = "" }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [adError, setAdError] = useState<string | null>(null);
  const connectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.connectedAdvertisers
  );

  useEffect(() => {
    // Simulate ad loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Add a check for connectedAdvertisers when the component mounts or when it changes
  useEffect(() => {
    if (!connectedAdvertisers || !Array.isArray(connectedAdvertisers) || connectedAdvertisers.length === 0) {
      setAdError("No advertisers available");
    }
  }, [connectedAdvertisers]);

  function selectAdvertiserBasedOnPercentage() {
    const breakPoints: number[] = [];
    let breakPoint = 0;

    if (!connectedAdvertisers || !Array.isArray(connectedAdvertisers) || connectedAdvertisers.length === 0) {
      setAdError("No advertisers available");
      return null;
    }

    connectedAdvertisers.forEach((advertiser) => {
      breakPoint += advertiser.ad_percentage as number;
      breakPoints.push(breakPoint);
    });

    const randomNumber = Math.random();
    let selectedAdvertiserIndex = -1;

    breakPoints.every((breakpoint, index) => {
      if (randomNumber <= breakpoint) {
        selectedAdvertiserIndex = index;
        return false;
      }
      return true;
    });

    if (selectedAdvertiserIndex === -1) {
      setAdError("No advertiser available");
      return null;
    }

    const selectedAdvertiser = connectedAdvertisers[selectedAdvertiserIndex];

    switch (selectedAdvertiser.advertiser) {
      case ADVERTISER_IDS.GOOGLE:
        return (
          <div className="relative">
            <AdSenseAdvertMd className={className} />
          </div>
        );
      default:
        setAdError("Unsupported advertiser");
        return null;
    }
  }

  if (isLoading) {
    return (
      <div
        className="w-full min-h-[200px] rounded-xl bg-gray-800/50 
                    flex items-center justify-center"
      >
        <div className="flex flex-col items-center space-y-3">
          <Spinner size="lg" className="text-blue-500" />
          <p className="text-sm text-gray-400">Loading advertisement...</p>
        </div>
      </div>
    );
  }

  if (adError) {
    return (
      <div
        className="w-full min-h-[200px] rounded-xl bg-gray-800/50 
                    flex items-center justify-center p-6"
      >
        <div className="text-center space-y-2">
          <div
            className="inline-flex items-center justify-center w-12 h-12 
                        rounded-full bg-gray-700 mb-3"
          >
            <svg
              className="w-6 h-6 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <p className="text-gray-400 font-medium">{adError}</p>
          <p className="text-sm text-gray-500">
            Please check your advertising settings
          </p>
        </div>
      </div>
    );
  }

  const adContent = selectAdvertiserBasedOnPercentage();

  if (!adContent) {
    return (
      <div
        className="w-full min-h-[200px] rounded-xl bg-gray-800/50 
                    flex items-center justify-center p-6"
      >
        <div className="text-center space-y-2">
          <div
            className="inline-flex items-center justify-center w-12 h-12 
                        rounded-full bg-gray-700 mb-3"
          >
            <svg
              className="w-6 h-6 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <p className="text-gray-400 font-medium">
            No Advertisement Available
          </p>
          <p className="text-sm text-gray-500">
            Connect with advertisers to see ads here
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative w-full overflow-hidden rounded-xl 
                  bg-gray-800/50 transition-all duration-200"
    >
      {adContent}
    </div>
  );
}
