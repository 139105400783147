import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { CreateFeedbackForm } from "components/feedback/CreateFeedbackForm";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Create feedback page.
 */
export default function CreateFeedback() {
  useEffect(() => {
    document.title = "Feedback | BrainCargo®";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg 
              transition-all duration-200"
            aria-label="Create Feedback Page"
          >
            <div className="border-b dark:border-gray-700">
              <MainHeader
                title="Feedback"
              />
            </div>

            <div className="p-6">
              <div className="mb-6">
                <p className="text-gray-600 dark:text-gray-300">
                  We value your feedback! Please select the type of feedback
                  you'd like to provide and share your thoughts with us.
                </p>
              </div>

              <CreateFeedbackForm />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
