import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ModalEditCreatePlaylist from "../../components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "../../components/playlist/SelectPlaylistPopup";
import PageWrapper from "../../components/common/pageWrapper";
import { isDispatchResponseError } from "../../redux/utils";
import { PlusIcon, ShareIcon } from "@heroicons/react/24/outline";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  ClockIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "../../components/playlist/MyPlaylistList";
import SharedPlaylistList from "../../components/playlist/SharedPlaylistList";
import { retrieveConnectedAdvertisers } from "../../redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "../../components/ads/AdByRandomProvider";
import { getIsSmScreen } from "../../helpers/responsiveness";
import { APP_URLS } from "../../navigation";
import { useIsSmScreen } from "../../hooks/useIsSmScreen";
import { generateYoutubeUrl } from "../../helpers/youtube";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "../../redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "../../types/feed";
import { ReportFeedItemModal } from "../../components/report/ReportFeedItemModal";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";
import { Spinner } from "flowbite-react";

const FALLBACK_IMAGE_URL =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjQ1MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImdyYWQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMWUyNTMzO3N0b3Atb3BhY2l0eToxIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMTYxZDI5O3N0b3Atb3BhY2l0eToxIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSI0NTAiIGZpbGw9InVybCgjZ3JhZCkiLz48Y2lyY2xlIGN4PSIxNTAiIGN5PSIxNTAiIHI9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiMzODRiNzAiIHN0cm9rZS13aWR0aD0iOCIvPjxwYXRoIGQ9Ik0xMjAgMTUwIEwxOTAgMTUwIE0xNTAgMTIwIEwxNTAgMTkwIiBzdHJva2U9IiMzODRiNzAiIHN0cm9rZS13aWR0aD0iOCIvPjx0ZXh0IHg9IjE1MCIgeT0iMjgwIiBmb250LWZhbWlseT0ic3lzdGVtLXVpLC1hcHBsZS1zeXN0ZW0sQmxpbmtNYWNTeXN0ZW1Gb250LFJvYm90byxPeHlnZW4sVWJ1bnR1LENhbnRhcmVsbCxzYW5zLXNlcmlmIiBmb250LXNpemU9IjI0IiBmaWxsPSIjNjQ3NDhiIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIj5ObyBJbWFnZSBBdmFpbGFibGU8L3RleHQ+PHRleHQgeD0iMTUwIiB5PSIzMTAiIGZvbnQtZmFtaWx5PSJzeXN0ZW0tdWksLWFwcGxlLXN5c3RlbSxCbGlua01hY1N5c3RlbUZvbnQsUm9ib3RvLE94eWdlbixVYnVudHUsQ2FudGFyZWxsLHNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMTYiIGZpbGw9IiM0YTU1NmMiIHRleHQtYW5jaG9yPSJtaWRkbGUiPkNsaWNrIHRvIHZpZXcgZGV0YWlsczwvdGV4dD48L3N2Zz4=";

export default function Videos() {
  const dispatch = useAppDispatch();
  const isSmScreen = useIsSmScreen();
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);

  let prevProgressState: OnProgressProps = {
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  };

  /**
   * Increase watched_for time for the current feed item
   */
  function updateWatchedFor() {
    if (feedItem && progressSeconds > 0) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        })
      );
      setProgressSeconds(0);
    }
  }

  /**
   * Fetch random video with loader
   */
  const fetchRandomVideo = async () => {
    setIsVideoLoading(true);
    try {
      await dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } })
      );
    } finally {
      setIsVideoLoading(false);
    }
  };

  /**
   * Handle like with loader
   */
  const handleLikeFeedItem = async () => {
    setIsVideoLoading(true);
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      updateWatchedFor();
      await fetchRandomVideo();
    } else {
      setIsVideoLoading(false);
    }
  };

  /**
   * Handle dislike with loader
   */
  const handleDislikeFeedItem = async () => {
    setIsVideoLoading(true);
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      updateWatchedFor();
      await fetchRandomVideo();
    } else {
      setIsVideoLoading(false);
    }
  };

  /**
   * Handle skip with loader
   */
  const handleSkipFeedItem = async () => {
    setIsVideoLoading(true);
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      updateWatchedFor();
      await fetchRandomVideo();
    } else {
      setIsVideoLoading(false);
    }
  };

  /**
   * Mark feed item as watched
   */
  const onPlay = () => {
    dispatch(watchFeedItem(feedItem?.id || ""));
  };

  /**
   * Update progress seconds
   */
  function onProgress(state: OnProgressProps) {
    const progressIncrease =
      state.playedSeconds - prevProgressState.playedSeconds;
    if (progressIncrease > 0) {
      if (progressIncrease < 1) {
        setProgressSeconds(progressSeconds + progressIncrease);
      }
    }
    prevProgressState = state;
  }

  if (getIsSmScreen() || isSmScreen) {
    // Force the user to mobile-compatible videos view
    window.location.href = APP_URLS.FEED_MOBILE;
  }

  /**
   * Initial data fetch
   */
  useEffect(() => {
    document.title = "Videos | BrainCargo®";

    const initializeData = async () => {
      setIsVideoLoading(true);
      try {
        await Promise.all([
          dispatch(retrieveConnectedAdvertisers()),
          fetchRandomVideo(),
          dispatch(
            getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } })
          ),
          dispatch(
            getDislikedFeedItems({
              queryParams: { types: FEED_ITEM_TYPES.VIDEO },
            })
          ),
        ]);
      } finally {
        setIsVideoLoading(false);
      }
    };

    initializeData();
  }, []);

  /**
   * Increase watch time when user leaves the page
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [progressSeconds]);

  console.log("feedItem_id", feedItem?.youtube_video_id, "::", feedItem);

  useEffect(() => {
    console.log("Buffering", isBuffering);
  }, [isBuffering, isVideoLoading]);

  return (
    <PageWrapper>
      <div className="flex-1 bg-gray-100 dark:bg-gray-900 min-h-screen pt-10">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <MainHeader title="Videos" />

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mt-8">
            {/* Video Player Section */}
            <div className="lg:col-span-3 space-y-8">
              {/* Video Player Card */}
              <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden transition-all duration-200">
                {/* Video Container */}
                <div className="relative">
                  {isVideoLoading ? (
                    <div className="aspect-video flex items-center justify-center bg-gray-50 dark:bg-gray-900 rounded-b-[2rem]">
                      <div className="flex flex-col items-center gap-4">
                        <div className="relative">
                          <div className="w-16 h-16 border-4 border-blue-500/30 border-t-blue-500 rounded-full animate-spin"></div>
                          <div className="absolute inset-0 flex items-center justify-center">
                            <svg
                              className="w-8 h-8 text-blue-500"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                              />
                            </svg>
                          </div>
                        </div>
                        <p className="text-gray-500 dark:text-gray-400 font-medium animate-pulse">
                          Loading your next video...
                        </p>
                      </div>
                    </div>
                  ) : feedItem?.youtube_video_id ? (
                    <div className="aspect-video rounded-b-[2rem] overflow-hidden relative">
                      <ReactPlayer
                        className="w-full h-full"
                        url={generateYoutubeUrl(feedItem.youtube_video_id)}
                        onPlay={onPlay}
                        onProgress={onProgress}
                        onBuffer={() => setIsBuffering(true)}
                        onBufferEnd={() => setIsBuffering(false)}
                        onReady={() => setIsVideoLoading(false)}
                        onError={() => setIsVideoLoading(false)}
                        onPlaybackQualityChange={() => {
                          setIsBuffering(true);
                          setTimeout(() => setIsBuffering(false), 1000);
                        }}
                        playing={!isVideoLoading && !isBuffering}
                        playsinline
                        controls
                        width="100%"
                        height="100%"
                        config={{
                          youtube: {
                            playerVars: {
                              autoplay: 1,
                              modestbranding: 1,
                              rel: 0,
                              showinfo: 0,
                              controls: 1,
                            },
                          },
                        }}
                        aria-label="Video player"
                      />

                      {/* Buffer Loading Overlay */}
                      {isBuffering && (
                        <div className="absolute inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center">
                          <div className="flex flex-col items-center gap-4">
                            <div className="relative">
                              <div className="w-16 h-16 border-4 border-blue-500/30 border-t-blue-500 rounded-full animate-spin"></div>
                              <div className="absolute inset-0 flex items-center justify-center">
                                <svg
                                  className="w-8 h-8 text-blue-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 3l14 9-14 9V3z"
                                  />
                                </svg>
                              </div>
                            </div>
                            <p className="text-white/90 font-medium animate-pulse">
                              Loading video...
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="aspect-video flex items-center justify-center bg-gray-50 dark:bg-gray-900 rounded-b-[2rem]">
                      <div className="flex flex-col items-center gap-4 text-center max-w-md">
                        <svg
                          className="w-16 h-16 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                          />
                        </svg>
                        <div>
                          <p className="text-gray-500 dark:text-gray-400 font-medium">
                            No videos available at this time
                          </p>
                          <button
                            onClick={fetchRandomVideo}
                            className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg
                                     transition-all duration-200"
                          >
                            Try Again
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Action Buttons Container - Separated from video */}
              <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-200 dark:border-gray-700 p-4 transition-all duration-200">
                <div className="flex flex-wrap gap-4 justify-center sm:justify-start">
                  <button
                    onClick={handleLikeFeedItem}
                    disabled={!feedItem}
                    className="flex items-center px-6 py-3 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-medium border border-gray-200 dark:border-gray-600 rounded-2xl transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm hover:shadow-md"
                  >
                    <HandThumbUpIcon className="w-5 h-5 mr-2" />
                    Like
                  </button>
                  <button
                    onClick={handleDislikeFeedItem}
                    disabled={!feedItem}
                    className="flex items-center px-6 py-3 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-medium border border-gray-200 dark:border-gray-600 rounded-2xl transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm hover:shadow-md"
                  >
                    <HandThumbDownIcon className="w-5 h-5 mr-2" />
                    Dislike
                  </button>
                  <button
                    onClick={handleSkipFeedItem}
                    disabled={!feedItem}
                    className="flex items-center px-6 py-3 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-medium border border-gray-200 dark:border-gray-600 rounded-2xl transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm hover:shadow-md"
                  >
                    <ForwardIcon className="w-5 h-5 mr-2" />
                    Skip
                  </button>
                  <button
                    onClick={() => setShowReportModal(true)}
                    disabled={!feedItem}
                    className="flex items-center px-6 py-3 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-medium border border-gray-200 dark:border-gray-600 rounded-2xl transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm hover:shadow-md"
                  >
                    <FlagIcon className="w-5 h-5 mr-2" />
                    Report
                  </button>
                  <button
                    onClick={() => setShowSelectPlaylistPopup(true)}
                    disabled={!feedItem}
                    className="flex items-center px-6 py-3 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-medium border border-gray-200 dark:border-gray-600 rounded-2xl transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm hover:shadow-md"
                  >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Add to Playlist
                  </button>
                </div>
              </div>

              {/* Ad Section */}
              <AdByRandomProvider className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-200 dark:border-gray-700 p-6" />

              {/* Video Lists Grid */}
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                {/* Liked Videos */}
                <div
                  className="bg-white dark:bg-[#1e2533]/90 backdrop-blur-sm rounded-3xl shadow-lg 
                                border border-gray-200 dark:border-white/5 overflow-hidden 
                                transition-all duration-300 hover:shadow-xl"
                >
                  {/* Header */}
                  <div
                    className="px-6 py-4 border-b border-gray-100 dark:border-white/5 
                                  bg-gray-50 dark:bg-[#1e2533]/80"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                          Liked Videos
                        </h2>
                        <span
                          className="px-2.5 py-0.5 text-sm font-medium text-gray-600 dark:text-gray-400 
                                        bg-gray-100 dark:bg-white/5 border border-gray-200 dark:border-white/10 
                                        rounded-full"
                        >
                          {likedFeedItems.length} videos
                        </span>
                      </div>
                      <span
                        className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
                                      bg-green-500/10 text-green-500 dark:text-green-400 
                                      border border-green-500/20"
                      >
                        <HandThumbUpIcon className="w-4 h-4 mr-1.5" />
                        Liked
                      </span>
                    </div>
                  </div>

                  {/* Videos List */}
                  <div
                    className="max-h-[40rem] overflow-auto scrollbar-thin scrollbar-track-transparent 
                                  dark:scrollbar-track-gray-900 scrollbar-thumb-gray-300 
                                  dark:scrollbar-thumb-gray-700"
                  >
                    {likedFeedItems.length > 0 ? (
                      <ul className="divide-y divide-gray-100 dark:divide-white/5">
                        {likedFeedItems.map((likedFeedItem, idx) => (
                          <li
                            key={idx}
                            className="group hover:bg-gray-50 dark:hover:bg-white/5 
                                        transition-all duration-200"
                          >
                            <div className="p-4">
                              <div className="flex space-x-4">
                                {/* Thumbnail */}
                                <div className="relative flex-shrink-0">
                                  <img
                                    className="h-24 w-40 object-cover rounded-xl bg-gray-100 dark:bg-gray-800"
                                    src={
                                      likedFeedItem.image || FALLBACK_IMAGE_URL
                                    }
                                    alt={likedFeedItem.title}
                                    onError={(e) => {
                                      const img = e.target as HTMLImageElement;
                                      if (img.src !== FALLBACK_IMAGE_URL) {
                                        img.src = FALLBACK_IMAGE_URL;
                                      }
                                    }}
                                  />
                                  <div
                                    className="absolute inset-0 bg-black/20 rounded-xl 
                                                group-hover:bg-black/0 transition-all duration-200"
                                  />
                                </div>

                                {/* Video Info */}
                                <div className="flex-1 min-w-0 py-1">
                                  <h3
                                    className="text-base font-medium text-gray-900 dark:text-white truncate 
                                               group-hover:text-blue-500 dark:group-hover:text-blue-400 
                                               transition-colors duration-200"
                                  >
                                    {likedFeedItem.title}
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                                    {likedFeedItem.description}
                                  </p>
                                  <div className="flex items-center space-x-4 mt-2">
                                    <span className="flex items-center text-xs text-gray-500 dark:text-gray-500">
                                      <ClockIcon className="w-4 h-4 mr-1" />
                                      {"Unknown duration"}
                                    </span>
                                    <span className="flex items-center text-xs text-gray-500 dark:text-gray-500">
                                      <EyeIcon className="w-4 h-4 mr-1" />
                                      {"0"} views
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="flex flex-col items-center justify-center py-16">
                        <div className="bg-gray-100 dark:bg-white/5 p-4 rounded-full mb-4">
                          <HandThumbUpIcon className="w-8 h-8 text-gray-400 dark:text-gray-600" />
                        </div>
                        <p className="text-gray-700 dark:text-gray-400 font-medium">
                          No liked videos yet
                        </p>
                        <p className="text-gray-500 dark:text-gray-600 text-sm mt-1">
                          Videos you like will appear here
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {/* Disliked Videos */}
                <div
                  className="bg-white dark:bg-[#1e2533]/90 backdrop-blur-sm rounded-3xl shadow-lg 
                                border border-gray-200 dark:border-white/5 overflow-hidden 
                                transition-all duration-300 hover:shadow-xl"
                >
                  {/* Header */}
                  <div
                    className="px-6 py-4 border-b border-gray-100 dark:border-white/5 
                                  bg-gray-50 dark:bg-[#1e2533]/80"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                          Disliked Videos
                        </h2>
                        <span
                          className="px-2.5 py-0.5 text-sm font-medium text-gray-600 dark:text-gray-400 
                                        bg-gray-100 dark:bg-white/5 border border-gray-200 dark:border-white/10 
                                        rounded-full"
                        >
                          {dislikedFeedItems.length} videos
                        </span>
                      </div>
                      <span
                        className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
                                      bg-red-500/10 text-red-500 dark:text-red-400 
                                      border border-red-500/20"
                      >
                        <HandThumbDownIcon className="w-4 h-4 mr-1.5" />
                        Disliked
                      </span>
                    </div>
                  </div>

                  {/* Videos List */}
                  <div
                    className="max-h-[40rem] overflow-auto scrollbar-thin scrollbar-track-transparent 
                                  dark:scrollbar-track-gray-900 scrollbar-thumb-gray-300 
                                  dark:scrollbar-thumb-gray-700"
                  >
                    {dislikedFeedItems.length > 0 ? (
                      <ul className="divide-y divide-gray-100 dark:divide-white/5">
                        {dislikedFeedItems.map((dislikedFeedItem, idx) => (
                          <li
                            key={idx}
                            className="group hover:bg-gray-50 dark:hover:bg-white/5 
                                          transition-all duration-200"
                          >
                            <div className="p-4">
                              <div className="flex space-x-4">
                                {/* Thumbnail */}
                                <div className="relative flex-shrink-0">
                                  <img
                                    className="h-24 w-40 object-cover rounded-xl bg-gray-100 dark:bg-gray-800"
                                    src={
                                      dislikedFeedItem.image ||
                                      FALLBACK_IMAGE_URL
                                    }
                                    alt={dislikedFeedItem.title}
                                    onError={(e) => {
                                      const img = e.target as HTMLImageElement;
                                      if (img.src !== FALLBACK_IMAGE_URL) {
                                        img.src = FALLBACK_IMAGE_URL;
                                      }
                                    }}
                                  />
                                  <div
                                    className="absolute inset-0 bg-black/20 rounded-xl 
                                                group-hover:bg-black/0 transition-all duration-200"
                                  />
                                </div>

                                {/* Video Info */}
                                <div className="flex-1 min-w-0 py-1">
                                  <h3
                                    className="text-base font-medium text-gray-900 dark:text-white truncate 
                                               group-hover:text-blue-500 dark:group-hover:text-blue-400 
                                               transition-colors duration-200"
                                  >
                                    {dislikedFeedItem.title}
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                                    {dislikedFeedItem.description}
                                  </p>
                                  <div className="flex items-center space-x-4 mt-2">
                                    <span className="flex items-center text-xs text-gray-500 dark:text-gray-500">
                                      <ClockIcon className="w-4 h-4 mr-1" />
                                      {"Unknown duration"}
                                    </span>
                                    <span className="flex items-center text-xs text-gray-500 dark:text-gray-500">
                                      <EyeIcon className="w-4 h-4 mr-1" />
                                      {"0"} views
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="flex flex-col items-center justify-center py-16">
                        <div className="bg-gray-100 dark:bg-white/5 p-4 rounded-full mb-4">
                          <HandThumbDownIcon className="w-8 h-8 text-gray-400 dark:text-gray-600" />
                        </div>
                        <p className="text-gray-700 dark:text-gray-400 font-medium">
                          No disliked videos yet
                        </p>
                        <p className="text-gray-500 dark:text-gray-600 text-sm mt-1">
                          Videos you dislike will appear here
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Playlists Sidebar */}
            <div className="lg:col-span-1 space-y-6">
              {/* Quick Actions */}
              <div className="flex flex-wrap gap-3">
                <button
                  onClick={() => setShowPlaylistPopup(true)}
                  className="flex-1 inline-flex items-center justify-center px-4 py-2.5 
                            text-sm font-medium text-blue-600 dark:text-blue-400 
                            bg-blue-50 dark:bg-blue-500/10 hover:bg-blue-100 
                            dark:hover:bg-blue-500/20 rounded-xl transition-all duration-200"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  New Playlist
                </button>
                <button
                  onClick={() => setShowSelectPlaylistPopup(true)}
                  className="flex-1 inline-flex items-center justify-center px-4 py-2.5 
                            text-sm font-medium text-gray-700 dark:text-white 
                            bg-gray-50 dark:bg-white/5 hover:bg-gray-100 
                            dark:hover:bg-white/10 rounded-xl transition-all duration-200"
                >
                  <ShareIcon className="w-4 h-4 mr-2" />
                  Share Playlists
                </button>
              </div>

              {/* My Playlists */}
              <div
                className="bg-white dark:bg-[#1e2533]/90 rounded-3xl shadow-lg 
                            border border-gray-200 dark:border-white/5 overflow-hidden"
              >
                <div className="p-4 space-y-4">
                  <MyPlaylistList smallHeader />
                </div>
              </div>

              {/* Shared Playlists */}
              <div
                className="bg-white dark:bg-[#1e2533]/90 rounded-3xl shadow-lg 
                            border border-gray-200 dark:border-white/5 overflow-hidden"
              >
                <div className="p-4 space-y-4">
                  <SharedPlaylistList smallHeader />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals with updated styling */}
        <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        {showSelectPlaylistPopup && feedItem && (
          <SelectPlaylistPopup
            feedItem={feedItem}
            show={showSelectPlaylistPopup}
            onClose={() => setShowSelectPlaylistPopup(false)}
          />
        )}
        {feedItem && (
          <ReportFeedItemModal
            show={showReportModal}
            setShow={setShowReportModal}
            feedItem={feedItem}
          />
        )}
        <Footer />
      </div>
    </PageWrapper>
  );
}
