// Docs: https://developer.spotify.com/documentation/embeds/references/iframe-api

/**
 * Initiate Spotify IFrame API.
 */
export default function initiateSpotify(
  uri,
  onPlaybackUpdate,
  elementId = "embed-iframe",
  setIFrameAPI = (val) => {
    return val;
  }
) {
  window.onSpotifyIframeApiReady = (IFrameAPI) => {
    const findElement = (retries = 0) => {
      const element = document.getElementById(elementId);

      if (element) {
        const options = {
          uri,
        };
        const callback = (EmbedController) => {
          EmbedController.addListener("playback_update", onPlaybackUpdate);
        };
        IFrameAPI.createController(element, options, callback);
        if (setIFrameAPI) setIFrameAPI(IFrameAPI);
      } else if (retries < 10) {
        // Retry after 100ms, up to 10 times (1 second total)
        setTimeout(() => findElement(retries + 1), 100);
      } else {
        console.error(
          `Element with id "${elementId}" not found after multiple retries`
        );
      }
    };

    findElement();
  };
}

/**
 * Update a given Spotify player's URI.
 */
export function updateSpotifyPlayerUri({ elementId, uri }) {
  const e = new Event("updatePlayerUri");
  e.elementId = elementId;
  e.uri = uri;
  window.dispatchEvent(e);
}

/**
 * Play a given Spotify player.
 */
export function spotifyPlayerPlay({ elementId }) {
  const e = new Event("play");
  e.elementId = elementId;
  window.dispatchEvent(e);
}

/**
 * Pause a given Spotify player.
 */
export function spotifyPlayerPause({ elementId }) {
  const e = new Event("pause");
  e.elementId = elementId;
  window.dispatchEvent(e);
}

/**
 * Create a Spotify player.
 */
export function createSpotifyPlayer({
  elementId,
  spotifyIFrameAPI,
  uri,
  onPlaybackUpdate,
}) {
  const element = document.getElementById(elementId);
  const options = {
    uri,
  };
  const callback = (EmbedController) => {
    EmbedController.addListener("playback_update", onPlaybackUpdate);
    window.addEventListener("updatePlayerUri", (e) => {
      if (e.elementId === elementId) {
        EmbedController.loadUri(e.uri);
      }
    });
    window.addEventListener("play", (e) => {
      if (e.elementId === elementId && !EmbedController.loading) {
        EmbedController.play();
      }
    });
    window.addEventListener("pause", (e) => {
      if (e.elementId === elementId && !EmbedController.loading) {
        EmbedController.pause();
      }
    });
  };
  if (element) {
    spotifyIFrameAPI.createController(element, options, callback);
  }
}

/**
 * Initiate Spotify IFrame API(used by mobile feed).
 */
export function initiateSpotifyMobileFeed(
  setSpotifyIFrameAPI = (val) => {
    return val;
  }
) {
  window.onSpotifyIframeApiReady = (spotifyIFrameAPI) => {
    setSpotifyIFrameAPI(spotifyIFrameAPI);
  };
}

// // Docs: https://developer.spotify.com/documentation/embeds/references/iframe-api

// /**
//  * Initiate Spotify IFrame API.
//  */
// export default function initiateSpotify(
//   uri,
//   onPlaybackUpdate,
//   elementId = "embed-iframe",
//   setIFrameAPI = (val) => {
//     return val;
//   },
// ) {
//   window.onSpotifyIframeApiReady = (IFrameAPI) => {
//     console.log("IFrameAPI", IFrameAPI);
//     console.log("elid", elementId);
//     const element = document.getElementById(elementId);
//     console.log("element", element);
//     const options = {
//       uri,
//     };
//     const callback = (EmbedController) => {
//       EmbedController.addListener("playback_update", onPlaybackUpdate);
//     };
//     IFrameAPI.createController(element, options, callback);
//     if (setIFrameAPI) setIFrameAPI(IFrameAPI);
//   };
// }

// /**
//  * Update a given Spotify player's URI.
//  */
// export function updateSpotifyPlayerUri({ elementId, uri }) {
//   const e = new Event("updatePlayerUri");
//   e.elementId = elementId;
//   e.uri = uri;
//   window.dispatchEvent(e);
// }

// /**
//  * Play a given Spotify player.
//  */
// export function spotifyPlayerPlay({ elementId }) {
//   const e = new Event("play");
//   e.elementId = elementId;
//   window.dispatchEvent(e);
// }

// /**
//  * Pause a given Spotify player.
//  */
// export function spotifyPlayerPause({ elementId }) {
//   const e = new Event("pause");
//   e.elementId = elementId;
//   window.dispatchEvent(e);
// }

// /**
//  * Create a Spotify player.
//  */
// export function createSpotifyPlayer({
//   elementId,
//   spotifyIFrameAPI,
//   uri,
//   onPlaybackUpdate,
// }) {
//   const element = document.getElementById(elementId);
//   const options = {
//     uri,
//   };
//   const callback = (EmbedController) => {
//     EmbedController.addListener("playback_update", onPlaybackUpdate);
//     window.addEventListener("updatePlayerUri", (e) => {
//       if (e.elementId === elementId) {
//         EmbedController.loadUri(e.uri);
//       }
//     });
//     window.addEventListener("play", (e) => {
//       if (e.elementId === elementId && !EmbedController.loading) {
//         EmbedController.play();
//       }
//     });
//     window.addEventListener("pause", (e) => {
//       if (e.elementId === elementId && !EmbedController.loading) {
//         EmbedController.pause();
//       }
//     });
//   };
//   if (element) {
//     spotifyIFrameAPI.createController(element, options, callback);
//   }
// }

// /**
//  * Initiate Spotify IFrame API(used by mobile feed).
//  */
// export function initiateSpotifyMobileFeed(
//   setSpotifyIFrameAPI = (val) => {
//     return val;
//   },
// ) {
//   window.onSpotifyIframeApiReady = (spotifyIFrameAPI) => {
//     setSpotifyIFrameAPI(spotifyIFrameAPI);
//   };
// }
