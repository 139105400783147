import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { register } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import { setTokens } from "../../services/localStorage";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../navigation";

interface NewRegisterProps {
  email?: string;
}

const NewRegister: React.FC<NewRegisterProps> = ({ email = "" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [emailValue, setEmailValue] = useState(email);

  const registerErrorMessages = useAppSelector(
    (state) => state.auth.registerErrorMessages
  );

  const handleRegister = async () => {
    try {
      const response = await toast.promise(
        dispatch(register({ 
          email: emailValue, 
          password1, 
          password2 
        })).unwrap(),
        {
          pending: "Creating your account...",
          success: "Account created successfully!",
          error: {
            render({ data }: any) {
              return data?.detail || "Registration failed";
            },
          },
        }
      );
      if (response) {
        const { access, refresh } = response;
        setTokens({ access, refresh });
        navigate(APP_URLS.VIDEOS);
      }
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleRegister();
  };

  return (
    <form onSubmit={handleSubmit} aria-label="Registration Form">
      <div className="mt-6">
        <TextInput
          value={emailValue}
          label="Email"
          id="email"
          type="email"
          required
          onChange={(event) => setEmailValue(event.target.value)}
          placeholder="Email"
          aria-label="Email"
          errorMessages={registerErrorMessages?.email}
        />

        <TextInput
          value={password1}
          label="Password"
          id="password1"
          type="password"
          required
          onChange={(event) => setPassword1(event.target.value)}
          placeholder="Password"
          aria-label="Password"
          errorMessages={registerErrorMessages?.password1}
        />

        <TextInput
          value={password2}
          label="Confirm Password"
          id="password2"
          type="password"
          required
          onChange={(event) => setPassword2(event.target.value)}
          placeholder="Confirm Password"
          aria-label="Confirm Password"
          errorMessages={registerErrorMessages?.password2}
        />

        <p className="text-sm text-gray-400 mt-4 mb-6 text-center">
          By creating an account you agree to be bound by the <button onClick={() => navigate(APP_URLS.TERMS_OF_SERVICE)} className="text-blue-400 hover:text-blue-300 hover:underline transition-colors">Terms of Service</button> and <button onClick={() => navigate(APP_URLS.PRIVACY_POLICY)} className="text-blue-400 hover:text-blue-300 hover:underline transition-colors">Privacy Policy</button>
        </p>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
            aria-label="Register"
          >
            Register
          </button>
        </div>
      </div>
    </form>
  );
};

export default NewRegister; 