import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ModalEditCreatePlaylist from "../../components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "../../components/playlist/SelectPlaylistPopup";
import PageWrapper from "../../components/common/pageWrapper";
import Track from "../../components/common/track";
import { isDispatchResponseError } from "../../redux/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "../../components/playlist/MyPlaylistList";
import SharedPlaylistList from "../../components/playlist/SharedPlaylistList";
import Header from "../../components/common/header";
import { retrieveConnectedAdvertisers } from "../../redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "../../components/ads/AdByRandomProvider";
import { generateSpotifyUrl } from "../../helpers/spotify";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "../../redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "../../types/feed";
import initiateSpotify, {
  initiateSpotifyMobileFeed,
} from "../../helpers/spotifyIFrameAPI";
import { ReportFeedItemModal } from "../../components/report/ReportFeedItemModal";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

import SpotifyIcon from "../../components/icons/SpotifyIcon";
import ScrollableMobileFeedElement from "components/feed/ScrollableMobileFeedElement";
import {
  TRANSITION_STYLE_PROPERTY,
  getComponentHeight,
  generateFeedItemHtmlId,
} from "components/feed/utils";
/**
 * Music page.
 */
export default function Music() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  console.log("user", user);
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const feedItems = useAppSelector((state) => state.feedItems.feedItems);
  const currFeedItemIdx = useAppSelector(
    (state) => state.feedItems.currFeedItemIdx
  );
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [spotifyIFrameAPI, setSpotifyIFrameAPI] = useState<any>(null);
  const [playing, setPlaying] = useState(false);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  let watchFeedItemTriggered = false;
  let prevPositionSeconds = 0;
  let prevProgressSeconds = 0;

  /**
   * Reloads the page.
   */
  function reloadPage() {
    // We need to refresh the page so that a new spotify player can be created
    window.location.reload();
  }

  /**
   * Mark feedItem as liked in database
   */
  const handleLikeFeedItem = async () => {
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as disliked in database
   */
  const handleDislikeFeedItem = async () => {
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as skipped in database
   */
  const handleSkipFeedItem = async () => {
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as watched in database
   */
  const handleWatchFeedItem = async () => {
    // handleWatchFeedItem is run whenever Spotify player changes its playback state.
    // Without the if statement here the backend would be flooded with requests to flag
    // the feedItem as watched
    if (!watchFeedItemTriggered) {
      await dispatch(watchFeedItem(feedItem?.id || ""));
      watchFeedItemTriggered = true;
    }
  };

  /**
   * Render a list of liked tracks.
   */
  const displayLikedFeedItems = () => {
    if (likedFeedItems.length > 0) {
      return likedFeedItems.map((likedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Track track={likedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No liked tracks added yet!
        </p>
      );
    }
  };

  /**
   * Render a list of disliked tracks.
   */
  const displayDislikedFeedItems = () => {
    if (dislikedFeedItems.length > 0) {
      return dislikedFeedItems.map((dislikedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Track track={dislikedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No disliked tracks added yet!
        </p>
      );
    }
  };

  /**
   * Update watched_for state in database.
   */
  function updateWatchedFor() {
    if (progressSeconds > 0 && feedItem) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        })
      );
      setProgressSeconds(0);
    }
  }

  const renderedFeedItems = useMemo(
    function renderFeedItems() {
      // console.log("feedItems_1_Render", feedItems);
      return feedItems.map((feedItem, idx) => {
        console.log("feedItem_1_MAp", feedItem);
        const renderedItemHeight = getComponentHeight();
        const id = generateFeedItemHtmlId(feedItem, idx);
        return (
          <></>
          // <ScrollableMobileFeedElement
          //   key={id}
          //   id={id}
          //   className="absolute w-full aspect-video rounded bg-gray-100"
          //   style={{
          //     ...TRANSITION_STYLE_PROPERTY,
          //     height: renderedItemHeight + "px",
          //     top: renderedItemHeight * idx,
          //   }}
          //   data={{
          //     playing: idx === currFeedItemIdx ? playing : false,
          //     feedItem,
          //   }}
          //   spotifyIFrameAPI={spotifyIFrameAPI}
          // />
        );
      });
    },
    [playing, feedItems, spotifyIFrameAPI]
  );

  // useEffect(() => {
  //   // Append Spotify script used by SpotifyTrack component
  //   initiateSpotifyMobileFeed(setSpotifyIFrameAPI);
  //   const script = document.createElement("script");
  //   script.src = "https://open.spotify.com/embed/iframe-api/v1";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  /**
   * Retrieve data and set up Spotify iframe API.
   */
  useEffect(() => {
    document.title = "Music | BrainCargo®";

    // Retrieve data
    dispatch(
      getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.TRACK } })
    );
    dispatch(
      getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.TRACK } })
    );
    dispatch(
      getDislikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.TRACK } })
    );
    dispatch(retrieveConnectedAdvertisers());

    // Set up Spotify iframe API
    const script = document.createElement("script");
    script.src = "https://open.spotify.com/embed/iframe-api/v1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Once a feedItem is loaded, set up Spotify player.
   */
  useEffect(() => {
    initiateSpotify(
      generateSpotifyUrl(feedItem?.spotify_track_id || ""),
      ({
        data,
      }: {
        data: {
          duration: number;
          isBuffering: boolean;
          isPaused: boolean;
          position: number;
        };
      }) => {
        if (!data.isPaused) {
          handleWatchFeedItem();
        }
        const positionSeconds = data.position / 1000;
        const newProgressSeconds = positionSeconds - prevPositionSeconds;
        if (newProgressSeconds > 0) {
          if (newProgressSeconds < 1) {
            // if newProgressSeconds >= 1 then we can assume that the user has seeked forward
            // We need to ignore that case
            setProgressSeconds(prevProgressSeconds + newProgressSeconds);
            prevProgressSeconds = prevProgressSeconds + newProgressSeconds;
          }
        }
        prevPositionSeconds = positionSeconds;
      }
    );
  }, [feedItem]);

  /**
   * Update watched_for state in database when user leaves the page.
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [progressSeconds]);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 lg:py-10">
          {/* Header Section */}
          <div
            className="bg-gradient-to-r from-emerald-600 to-emerald-800 dark:from-emerald-800 dark:to-emerald-900 
                        rounded-2xl shadow-lg overflow-hidden"
          >
            <div className="p-6 sm:p-8 lg:p-10">
              <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-2">
                Music
              </h1>
              <p className="text-emerald-100 dark:text-emerald-200 text-base sm:text-lg">
                Discover and manage your music collection
              </p>
            </div>
          </div>

          {/* Main Content */}
          <div className="mt-6 sm:mt-8">
            <div className="space-y-6 sm:space-y-8">
              {/* Music Player Section */}
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 sm:gap-8">
                <div className="lg:col-span-2 space-y-6">
                  {/* Spotify Player */}
                  <div
                    className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-4 sm:p-6 
                                  border border-gray-200 dark:border-gray-700"
                  >
                    {feedItem ? (
                      <div
                        id="embed-iframe"
                        className="w-10% aspect-video rounded-xl overflow-hidden"
                        aria-label="Spotify Player"
                      ></div>
                    ) : (
                      <div
                        className="aspect-video flex items-center justify-center 
                                      bg-gray-50 dark:bg-gray-900/50 rounded-xl"
                      >
                        <div className="text-center">
                          <div className="animate-pulse mb-4">
                            <div className="w-12 h-12 bg-gray-200 dark:bg-gray-700 rounded-full mx-auto"></div>
                          </div>
                          <p className="text-gray-500 dark:text-gray-400 font-medium">
                            No track available at this time
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Action Buttons */}
                  <div className="flex flex-wrap gap-3">
                    <ActionButton
                      onClick={handleLikeFeedItem}
                      disabled={!feedItem}
                      icon={<HandThumbUpIcon className="w-5 h-5" />}
                      label="Like"
                      variant="success"
                    />
                    <ActionButton
                      onClick={handleDislikeFeedItem}
                      disabled={!feedItem}
                      icon={<HandThumbDownIcon className="w-5 h-5" />}
                      label="Dislike"
                      variant="neutral"
                    />
                    <ActionButton
                      onClick={handleSkipFeedItem}
                      disabled={!feedItem}
                      icon={<ForwardIcon className="w-5 h-5" />}
                      label="Skip"
                      variant="neutral"
                    />
                    <ActionButton
                      onClick={() => setShowReportModal(true)}
                      disabled={!feedItem}
                      icon={<FlagIcon className="w-5 h-5" />}
                      label="Report"
                      variant="danger"
                    />
                    <ActionButton
                      onClick={() => setShowSelectPlaylistPopup(true)}
                      disabled={!feedItem}
                      icon={<PlusIcon className="w-5 h-5" />}
                      label="Add to Playlist"
                      variant="info"
                    />
                  </div>

                  {/* Advertisement */}
                  <AdByRandomProvider className="w-full" />

                  {/* Liked and Disliked Tracks */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <TrackList
                      title="Liked Tracks"
                      count={likedFeedItems.length}
                      items={likedFeedItems}
                      emptyMessage="No liked tracks yet!"
                      variant="success"
                    />
                    <TrackList
                      title="Disliked Tracks"
                      count={dislikedFeedItems.length}
                      items={dislikedFeedItems}
                      emptyMessage="No disliked tracks yet!"
                      variant="danger"
                    />
                  </div>
                </div>

                {/* Playlists Sidebar */}
                <div className="space-y-6">
                  <div
                    className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg 
                                  border border-gray-200 dark:border-gray-700"
                  >
                    <MyPlaylistList smallHeader />
                  </div>
                  <div
                    className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg 
                                  border border-gray-200 dark:border-gray-700"
                  >
                    <SharedPlaylistList smallHeader />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        {showSelectPlaylistPopup && feedItem && (
          <SelectPlaylistPopup
            feedItem={feedItem}
            show={showSelectPlaylistPopup}
            onClose={() => setShowSelectPlaylistPopup(false)}
            headerText="Add track to playlist"
          />
        )}
        {feedItem && (
          <ReportFeedItemModal
            show={showReportModal}
            setShow={setShowReportModal}
            feedItem={feedItem}
          />
        )}
      </div>
    </PageWrapper>
  );
}

// New Component: Action Button
interface ActionButtonProps {
  onClick: () => void;
  disabled: boolean;
  icon: React.ReactNode;
  label: string;
  variant: "success" | "neutral" | "danger" | "info";
}

function ActionButton({
  onClick,
  disabled,
  icon,
  label,
  variant,
}: ActionButtonProps) {
  const variantClasses = {
    success:
      "bg-emerald-50 dark:bg-emerald-900/30 text-emerald-700 dark:text-emerald-300 hover:bg-emerald-100 dark:hover:bg-emerald-900/50",
    neutral:
      "bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600",
    danger:
      "bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-300 hover:bg-red-100 dark:hover:bg-red-900/50",
    info: "bg-blue-50 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 hover:bg-blue-100 dark:hover:bg-blue-900/50",
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        inline-flex items-center gap-2 px-4 py-2.5 rounded-xl
        transition-all duration-200 disabled:opacity-50
        ${variantClasses[variant]}
      `}
    >
      {icon}
      <span className="font-medium">{label}</span>
    </button>
  );
}

// New Component: Track List
interface TrackListProps {
  title: string;
  count: number;
  items: any[];
  emptyMessage: string;
  variant: "success" | "danger";
}

function TrackList({
  title,
  count,
  items,
  emptyMessage,
  variant,
}: TrackListProps) {
  const variantClasses = {
    success:
      "bg-emerald-50 dark:bg-emerald-900/30 text-emerald-700 dark:text-emerald-300",
    danger: "bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-300",
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-200 dark:border-gray-700">
      <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          {title}
        </h3>
        <span
          className={`px-2.5 py-0.5 text-sm rounded-full ${variantClasses[variant]}`}
        >
          {count}
        </span>
      </div>
      <div
        className="divide-y divide-gray-200 dark:divide-gray-700 max-h-[40rem] overflow-auto 
                    scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600"
      >
        {items.length > 0 ? (
          items.map((item, index) => (
            <div
              key={index}
              className="p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
            >
              {/* Your existing track item content */}
            </div>
          ))
        ) : (
          <p className="py-4 text-gray-500 text-sm font-medium text-center">
            {emptyMessage}
          </p>
        )}
      </div>
    </div>
  );
}
