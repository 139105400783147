import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { phoneRegister } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../navigation";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const OTPRegister: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [phoneError, setPhoneError] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [code, setCode] = useState("");
  const [agreedToSMS, setAgreedToSMS] = useState(true);

  const registerErrorMessages = useAppSelector(
    (state) => state.auth.registerErrorMessages
  );

  const validatePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber.trim()) {
      setPhoneError("Phone number is required");
      return false;
    }
    if (phoneNumber.length < 10) {
      setPhoneError("Please enter a valid phone number");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handlePhoneChange = (value: string, data: any) => {
    if (!value) {
      setPhone("");
      setCountryCode("1");
      return;
    }

    try {
      const dialCode = (data?.dialCode || "1").replace(/\+/g, "");
      setCountryCode(dialCode);

      let cleanedValue = value.replace(/[^\d]/g, "");

      if (cleanedValue.startsWith(dialCode)) {
        cleanedValue = cleanedValue.slice(dialCode.length);
      }

      if (cleanedValue.length > 0) {
        let formattedValue = cleanedValue;
        if (cleanedValue.length > 3) {
          formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(
            3
          )}`;
        }
        if (cleanedValue.length > 6) {
          formattedValue = `${formattedValue.slice(
            0,
            7
          )}-${formattedValue.slice(7)}`;
        }
        cleanedValue = formattedValue;
      }

      setPhone(cleanedValue);
    } catch (error) {
      console.error("Error processing phone number:", error);
      setPhoneError("Error processing phone number");
    }
  };

  const handleRegister = async () => {
    try {
      if (!validatePhoneNumber(phone)) {
        return;
      }

      if (!agreedToSMS) {
        setPhoneError("Please agree to receive SMS messages");
        return;
      }

      if (!countryCode || !phone) {
        setPhoneError("Invalid phone number");
        return;
      }

      const fullPhoneNumber = `+${countryCode}${phone}`;
      await toast.promise(
        dispatch(phoneRegister({ phone: fullPhoneNumber })).unwrap(),
        {
          pending: "Sending verification code...",
          success: "Verification code sent!",
          error: {
            render({ data }: any) {
              if (data?.phone?.[0]) {
                setPhoneError(data.phone[0]);
                return data.phone[0];
              }
              return data?.detail || "Failed to send verification code";
            },
          },
        }
      );
      setShowOTP(true);
    } catch (error: any) {
      console.error("Registration error:", error);
      if (error?.phone?.[0]) {
        setPhoneError(error.phone[0]);
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleRegister();
  };

  const handleSMSTermsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(APP_URLS.SMS_OPT_OUT, "_blank");
  };

  return (
    <form onSubmit={handleSubmit} aria-label="Phone Registration Form">
      <div className="mb-4">
        <style>
          {`
            .react-international-phone-country-selector-button {
              width: 48px !important;
              padding: 0 8px !important;
              border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
              background: white !important;
            }
            
            .react-international-phone-country-selector-button__button-content > span:not(.react-international-phone-country-selector-button__flag) {
              display: none !important;
            }

            .react-international-phone-input-container {
              display: flex !important;
              background: white !important;
              border-radius: 0.5rem !important;
            }

            .react-international-phone-input {
              background: white !important;
              color: black !important;
            }

            .react-international-phone-country-selector-button__button {
              border: none !important;
            }

            .react-international-phone-input::placeholder {
              color: #6B7280 !important;
            }
          `}
        </style>
        <label className="block text-stone-200 text-sm font-bold mb-2">
          Phone Number
        </label>
        <PhoneInput
          defaultCountry="us"
          value={phone}
          onChange={handlePhoneChange}
          inputClassName={`
            w-full px-4 py-2 bg-white text-black
            border rounded-lg focus:outline-none focus:border-purple-500
            ${showOTP ? "opacity-50 cursor-not-allowed" : ""}
            ${phoneError ? "border-red-500" : ""}
          `}
          disabled={showOTP}
          placeholder="Enter phone number"
          disableDialCodeAndPrefix={true}
          style={{ border: "none" }}
        />
        {(phoneError || registerErrorMessages?.phone) && (
          <p className="text-red-500 text-xs mt-1">
            {phoneError ||
              registerErrorMessages?.phone ||
              "Invalid phone number"}
          </p>
        )}

        {!showOTP && (
          <div className="mt-4">
            <p className="text-sm text-gray-400 mt-4 mb-6 text-left">
              By logging in to youraccount you agree to be bound by the{" "}
              <button
                onClick={() => navigate(APP_URLS.TERMS_OF_SERVICE)}
                className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
              >
                Terms of Service
              </button>{" "}
              and{" "}
              <button
                onClick={() => navigate(APP_URLS.PRIVACY_POLICY)}
                className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
              >
                Privacy Policy
              </button>
            </p>

            <label className="flex items-start gap-3 cursor-pointer">
              <input
                type="checkbox"
                checked={agreedToSMS}
                onChange={(e) => setAgreedToSMS(e.target.checked)}
                className="mt-1 w-4 h-4 rounded border-2 border-gray-700 bg-gray-800/50
                         text-blue-500 focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-0
                         transition-all duration-200"
              />
              <span className="text-gray-300 text-sm leading-relaxed">
                I agree to receive SMS messages from BrainCargo® as described in
                the{" "}
                <button
                  type="button"
                  onClick={handleSMSTermsClick}
                  className="text-blue-400 hover:text-blue-300 underline font-medium transition-colors"
                >
                  SMS Terms
                </button>
              </span>
            </label>
          </div>
        )}

        {showOTP && (
          <TextInput
            label="Enter Verification Code"
            id="code"
            value={code}
            type="text"
            required
            onChange={(event) => setCode(event.target.value)}
            placeholder="XXXXXX"
          />
        )}
      </div>

      <div className="mt-6">
        <button
          type="submit"
          className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
          aria-label="Register"
        >
          {showOTP ? "Verify Phone" : "Register"}
        </button>
      </div>
    </form>
  );
};

export default OTPRegister;
