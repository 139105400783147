import React from "react";
import { useAppSelector } from "../../redux/hooks";

const Settings = () => {
  const user = useAppSelector((state) => state.user.user);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      
      <div className="bg-gray-800 rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Account Settings</h2>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Email
            </label>
            <div className="text-white">{user?.email || "Not available"}</div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Username
            </label>
            <div className="text-white">{user?.username || "Not available"}</div>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-800 rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Preferences</h2>
        
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              id="darkMode"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              defaultChecked
            />
            <label htmlFor="darkMode" className="ml-2 block text-sm text-white">
              Dark Mode
            </label>
          </div>
          
          <div className="flex items-center">
            <input
              id="notifications"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              defaultChecked
            />
            <label htmlFor="notifications" className="ml-2 block text-sm text-white">
              Enable Notifications
            </label>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-800 rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Privacy</h2>
        
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              id="dataSharing"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="dataSharing" className="ml-2 block text-sm text-white">
              Allow Data Sharing
            </label>
          </div>
          
          <div className="flex items-center">
            <input
              id="activityTracking"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              defaultChecked
            />
            <label htmlFor="activityTracking" className="ml-2 block text-sm text-white">
              Activity Tracking
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings; 