import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Label,
  RangeSlider,
  Spinner,
  TextInput,
} from "flowbite-react";
import Accordion from "../../components/common/accordion";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  retrieveAdvertisers,
  retrieveConnectedAdvertisers,
  setConnectedAdvertisers,
} from "../../redux/brainCoin/brainCoinSlice";
import { Advertiser, ConnectedAdvertiser } from "../../types/brainCoin";
import { displayErrors } from "../../helpers/errors";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";

/**
 * Component for displaying a collapsible connected advertisers accordion.
 */
export default function ConnectedAdvertisersAccordion() {
  const dispatch = useAppDispatch();
  const [accordionItems, setAccordionItems] = useState<
    Array<{ header: JSX.Element; body: JSX.Element }>
  >([]);
  const [accordionOpenArray, setAccordionOpenArray] = useState<boolean[]>([]);
  const [editedConnectedAdvertisers, setEditedConnectedAdvertisers] = useState<
    ConnectedAdvertiser[]
  >([]);
  const advertisers = useAppSelector((state) => state.brainCoin.advertisers);
  const connectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.connectedAdvertisers
  );
  const pendingRetrieveAdvertisers = useAppSelector(
    (state) => state.brainCoin.pendingRetrieveAdvertisers
  );
  const pendingRetrieveConnectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.pendingRetrieveConnectedAdvertisers
  );
  const pendingSetConnectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.pendingSetConnectedAdvertisers
  );
  const retrieveAdvertiserErrorMessages = useAppSelector(
    (state) => state.brainCoin.retrieveAdvertiserErrorMessages
  );
  const retrieveConnectedAdvertiserErrorMessages = useAppSelector(
    (state) => state.brainCoin.retrieveConnectedAdvertiserErrorMessages
  );
  const setConnectedAdvertiserErrorMessages = useAppSelector(
    (state) => state.brainCoin.setConnectedAdvertiserErrorMessages
  );

  /**
   * Generate accordion items based on advertisers and editedConnectedAdvertisers.
   */
  function generateAccordionItems() {
    const items = advertisers.map((advertiser, idx) => {
      const editedConnectedAdvertiser = editedConnectedAdvertisers.find(
        (item) => item.advertiser === advertiser.id
      );
      const adPercentage =
        editedConnectedAdvertiser?.ad_percentage === undefined
          ? "0%"
          : editedConnectedAdvertiser?.ad_percentage.toString();

      function setAdPercentage(value: string) {
        const newEditedConnectedAdvertisers = [...editedConnectedAdvertisers];
        const editedConnectedAdvertiserIndex =
          newEditedConnectedAdvertisers.findIndex(
            (item) => item.advertiser === editedConnectedAdvertiser?.advertiser
          );

        newEditedConnectedAdvertisers[
          editedConnectedAdvertiserIndex
        ].ad_percentage = value;
        setEditedConnectedAdvertisers([...newEditedConnectedAdvertisers]);
      }

      function handleAdPercentageChange(
        e: React.ChangeEvent<HTMLInputElement>,
        addPercentSymbol = false
      ) {
        let value: string = e.target.value;

        // Make sure the value is in 0-100
        let valueNumber = parseFloat(value);
        if (valueNumber < 0) valueNumber = 0;
        if (valueNumber > 100) valueNumber = 100;

        // Convert valueNumber to string in order to filter out undesired symbols
        if (isNaN(valueNumber)) {
          value = "0";
        } else if (addPercentSymbol) {
          value = valueNumber.toString() + "%";
        } else {
          value = valueNumber.toString();
        }
        setAdPercentage(value);
      }

      function addPercentSymbolToAdPercentage() {
        setAdPercentage(adPercentage.replaceAll("%", "") + "%");
      }

      function removePercentSymbolFromAdPercentage() {
        setAdPercentage(adPercentage.replaceAll("%", ""));
      }

      return {
        header: (
          <div className="flex items-center justify-between w-full py-2 px-1">
            <div className="flex items-center space-x-4">
              <Checkbox
                checked={accordionOpenArray[idx]}
                onChange={() => handleAccordionClick(idx)}
                className="w-5 h-5 rounded border-gray-600 
                          checked:bg-blue-500 checked:border-blue-500
                          focus:ring-blue-500 focus:ring-2 focus:ring-offset-0"
              />
              <span className="text-gray-200 font-medium text-lg">
                {advertiser.name}
              </span>
            </div>
            <div className="flex items-center space-x-2 text-gray-400">
              <CurrencyDollarIcon className="w-5 h-5" />
              <span>{advertiser.brain_coin_amount} coins</span>
            </div>
          </div>
        ),
        body: (
          <div className="space-y-6 p-4 bg-gray-800/50 rounded-xl">
            <div className="space-y-2">
              <Label
                className="text-gray-300"
                value="Advertisement Percentage"
              />
              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <RangeSlider
                    min={0}
                    max={100}
                    step={1}
                    value={parseFloat(adPercentage)}
                    onChange={(e) => handleAdPercentageChange(e, true)}
                    className="w-full"
                    sizing="lg"
                  />
                  <div className="flex justify-between mt-2 text-sm text-gray-400">
                    <span>0%</span>
                    <span>50%</span>
                    <span>100%</span>
                  </div>
                </div>
                <div className="w-24">
                  <input
                    type="text"
                    value={adPercentage}
                    onChange={handleAdPercentageChange}
                    onFocus={removePercentSymbolFromAdPercentage}
                    onBlur={addPercentSymbolToAdPercentage}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 
                             rounded-lg text-gray-200 text-center
                             focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
              </div>
            </div>
          </div>
        ),
      };
    });
    setAccordionItems(items);
  }

  /**
   * Generate accordionOpenArray based on advertisers and connectedAdvertisers.
   */
  function generateAccordionOpenArray(
    advertisers: Advertiser[],
    connectedAdvertisers: ConnectedAdvertiser[]
  ) {
    const connectedAdvertiserIds = connectedAdvertisers.map(
      (item) => item.advertiser
    );
    setAccordionOpenArray(
      advertisers.map((item) => connectedAdvertiserIds.includes(item.id))
    );
  }

  /**
   * Open/close accordion item.
   */
  function handleAccordionClick(idx: number) {
    const newAccordionOpenArray = [...accordionOpenArray];
    newAccordionOpenArray[idx] = !newAccordionOpenArray[idx];
    setAccordionOpenArray(newAccordionOpenArray);
  }

  /**
   * Translate editedConnectedAdvertisers to payload and make a request to set connected advertisers.
   */
  function handleSubmit() {
    const data: ConnectedAdvertiser[] = [];

    // Prepare payload based on checked accordion items
    accordionOpenArray.forEach((item, idx) => {
      const adPercentage =
        parseFloat(String(editedConnectedAdvertisers[idx].ad_percentage)) *
        0.01;
      if (item === true && adPercentage !== 0) {
        data.push({
          ...editedConnectedAdvertisers[idx],
          // ad_percentage needs to be set to percentage representation(ex. 50% -> 0.5)
          ad_percentage: adPercentage,
        });
      }
    });
    dispatch(setConnectedAdvertisers(data));
  }

  /**
   * Render errors from redux state.
   */
  function renderErrors() {
    if (
      retrieveAdvertiserErrorMessages ||
      setConnectedAdvertiserErrorMessages ||
      retrieveConnectedAdvertiserErrorMessages
    ) {
      const errors: Array<string[]> = [];
      Object.keys(retrieveAdvertiserErrorMessages).forEach((key) => {
        errors.push(retrieveAdvertiserErrorMessages[key]);
      });
      Object.keys(setConnectedAdvertiserErrorMessages).forEach((key) => {
        errors.push(setConnectedAdvertiserErrorMessages[key]);
      });
      Object.keys(retrieveConnectedAdvertiserErrorMessages).forEach((key) => {
        errors.push(retrieveConnectedAdvertiserErrorMessages[key]);
      });
      return errors.map((error) => displayErrors(error));
    }
  }

  useEffect(() => {
    // Generate accordion items on mount and whenever accordionOpenArray or editedConnectedAdvertisers change
    generateAccordionItems();
  }, [accordionOpenArray, editedConnectedAdvertisers]);

  useEffect(() => {
    // Generate editedConnectedAdvertisers based on advertisers and connectedAdvertisers
    const newEditedConnectedAdvertisers = advertisers.map((advertiser) => {
      let connectedAdvertiser = connectedAdvertisers.find(
        (item) => item.advertiser === advertiser.id
      );
      let adPercentage = connectedAdvertiser?.ad_percentage;

      // Value returned from backend is represented as float(50% -> 0.5) - it needs to be adjusted
      if (adPercentage === undefined) {
        adPercentage = "0%";
      } else {
        adPercentage = String(Math.round((adPercentage as number) * 100)) + "%";
      }

      connectedAdvertiser = {
        advertiser: advertiser.id,
        brain_coin_amount: advertiser.brain_coin_amount,
        ad_percentage: adPercentage,
        ...(!!connectedAdvertiser && { id: connectedAdvertiser.id }),
      };
      return connectedAdvertiser;
    });

    setEditedConnectedAdvertisers([...newEditedConnectedAdvertisers]);
    generateAccordionOpenArray(advertisers, connectedAdvertisers);
  }, [advertisers, connectedAdvertisers]);

  useEffect(() => {
    // Retrieve advertisers and connected advertisers on mount
    dispatch(retrieveAdvertisers());
    dispatch(retrieveConnectedAdvertisers());
  }, []);

  return (
    <div className="space-y-6">
      {pendingRetrieveAdvertisers || pendingRetrieveConnectedAdvertisers ? (
        <div className="flex justify-center items-center py-8">
          <div className="flex flex-col items-center space-y-4">
            <Spinner size="xl" className="text-blue-500" />
            <p className="text-gray-400">Loading advertisers...</p>
          </div>
        </div>
      ) : (
        <>
          <Accordion
            items={accordionItems}
            openArray={accordionOpenArray}
            handleClick={handleAccordionClick}
          />

          {renderErrors()}

          <Button
            onClick={handleSubmit}
            className="w-full bg-blue-600 hover:bg-blue-700 
                     focus:ring-4 focus:ring-blue-500/50 
                     transition-all duration-200 py-2.5"
            disabled={pendingSetConnectedAdvertisers}
          >
            {pendingSetConnectedAdvertisers ? (
              <div className="flex items-center space-x-2">
                <Spinner size="sm" />
                <span>Updating...</span>
              </div>
            ) : (
              "Save Changes"
            )}
          </Button>
        </>
      )}
    </div>
  );
}
