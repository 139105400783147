import { useEffect, useRef } from "react";
import { FeedItem } from "types/feed";
import { handleTouchEnd, handleTouchMove, handleTouchStart } from "../components/feed/handlers";
import { updateFeedItemZIndexes } from "../components/feed/utils";

interface TouchInteractionProps {
  feedItems: FeedItem[];
  currFeedItemIdx: number;
  playing: boolean;
  overlayRef: React.RefObject<HTMLDivElement>;
  setShowPlaylistsDrawer: (show: boolean) => void;
  setPlaying: (playing: boolean) => void;
  setCurrFeedItemIdx: (idx: number) => void;
  setShowLikePopup: (show: boolean) => void;
  setShowDislikePopup: (show: boolean) => void;
  handleLikeFeedItem: () => Promise<boolean>;
  handleDislikeFeedItem: () => Promise<boolean>;
  handleAddToDefaultPlaylist: () => Promise<boolean>;
  handleSkipFeedItem: () => Promise<boolean>;
  onNearEnd?: () => void;
}

export function useTouchInteractions({
  feedItems,
  currFeedItemIdx,
  playing,
  overlayRef,
  setShowPlaylistsDrawer,
  setPlaying,
  setCurrFeedItemIdx,
  setShowLikePopup,
  setShowDislikePopup,
  handleLikeFeedItem,
  handleDislikeFeedItem,
  handleAddToDefaultPlaylist,
  handleSkipFeedItem,
  onNearEnd,
}: TouchInteractionProps) {
  // Touch interaction state
  const currX = useRef(0);
  const currY = useRef(0);
  const startX = useRef(0);
  const startY = useRef(0);
  const prevTouchDate = useRef<Date | null>(null);
  const holdMs = useRef(0);
  const holdInterval = useRef<NodeJS.Timeout | null>(null);
  const tapTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Avoid infinite fetching of feed items if there are no items available
    if (feedItems.length === 0) return;

    // Check if near end of feed
    if (currFeedItemIdx >= feedItems.length - 1 && onNearEnd) {
      onNearEnd();
    }

    // Update every feed item element's z-index
    updateFeedItemZIndexes({ feedItemIdx: currFeedItemIdx, feedItems });

    // Set up gesture handlers
    function onTouchStart(e: TouchEvent) {
      const result = handleTouchStart({
        e,
        holdMs: holdMs.current,
        startY: startY.current,
        startX: startX.current,
        currY: currY.current,
        currX: currX.current,
        playing,
        setShowPlaylistsDrawer,
        setPlaying,
        feedItems,
      });
      holdMs.current = result.holdMs;
      holdInterval.current = result.holdInterval;
      startX.current = result.startX;
      startY.current = result.startY;
      currX.current = result.currX;
      currY.current = result.currY;
      tapTimeout.current = result.tapTimeout;
    }

    function onTouchMove(e: TouchEvent) {
      const result = handleTouchMove({
        e,
        currX: currX.current,
        currY: currY.current,
        startX: startX.current,
        startY: startY.current,
        feedItems,
        currFeedItemIdx,
        tapTimeout: tapTimeout.current,
      });
      currX.current = result.currX;
      currY.current = result.currY;
    }

    function onTouchEnd(e: TouchEvent) {
      const result = handleTouchEnd({
        startY: startY.current,
        startX: startX.current,
        currX: currX.current,
        currY: currY.current,
        holdInterval: holdInterval.current,
        tapTimeout: tapTimeout.current,
        prevTouchDate: prevTouchDate.current,
        currFeedItemIdx,
        feedItems,
        setCurrFeedItemIdx,
        setShowLikePopup,
        setShowDislikePopup,
        handleLikeFeedItem,
        handleDislikeFeedItem,
        handleAddToDefaultPlaylist,
        handleSkipFeedItem,
        setPlaying,
      });
      prevTouchDate.current = result.prevTouchDate;
    }

    if (overlayRef.current) {
      overlayRef.current.addEventListener("touchmove", onTouchMove);
      overlayRef.current.addEventListener("touchstart", onTouchStart);
      overlayRef.current.addEventListener("touchend", onTouchEnd);
    }

    return () => {
      if (overlayRef.current) {
        overlayRef.current.removeEventListener("touchmove", onTouchMove);
        overlayRef.current.removeEventListener("touchstart", onTouchStart);
        overlayRef.current.removeEventListener("touchend", onTouchEnd);
      }
    };
  }, [feedItems, currFeedItemIdx, playing]);
} 