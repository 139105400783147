import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAccessToken, isTokenValid } from "../../services/localStorage";
import { APP_URLS } from "../../navigation";
import "react-international-phone/style.css";
import { WalletLoginModal } from "components/auth/WalletLoginModal";
import Footer from "components/Footer/Footer";
import { LoginMethodSelector } from "components/auth/LoginMethodSelector";
import { LoginMethod, getFeatureFlags, isLoginMethodEnabled } from "../../config/featureFlags";
import OTPLogin from "./OTPLogin";
import TraditionalLogin from "./TraditionalLogin";
import NewRegister from "./NewRegister";
import OTPRegister from "./OTPRegister";
import BrainCoinImage from '../../images/Landing/BrainCoin.png';
import AuthLayout from "../../components/auth/AuthLayout";

export default function Login() {
  const navigate = useNavigate();
  const { seqAddress, pendingConnect } = useAppSelector(
    (state) => state.wallet
  );

  const [agreed, setAgreed] = useState(false);
  const [currentLoginMethod, setCurrentLoginMethod] = useState<LoginMethod>(
    getFeatureFlags().loginMethod
  );

  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [showFAQDialog, setShowFAQDialog] = useState(false);

  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(null);

  const [showContactInfo, setShowContactInfo] = useState(false);

  const [isLoginFieldEmail, setIsLoginFieldEmail] = useState(true);
  const [phone, setPhone] = useState("");

  const [isRegistering, setIsRegistering] = useState(false);
  
  // Get the phone authentication feature flag
  const { enablePhoneAuth } = getFeatureFlags();

  // If phone auth is disabled, ensure we're using email
  useEffect(() => {
    if (!enablePhoneAuth && !isLoginFieldEmail) {
      setIsLoginFieldEmail(true);
    }
  }, [enablePhoneAuth, isLoginFieldEmail]);


  useEffect(() => {
    document.title = "Login | BrainCargo®";
    const accessToken = getAccessToken();
    if (accessToken && isTokenValid(accessToken)) {
      navigate(APP_URLS.VIDEOS);
    }
  }, [navigate]);

  const handlePolicyNavigation = (url: string | URL | undefined) => {
    window.open(url, "_blank");
  };


  const handleLoginMethodChange = (method: LoginMethod) => {
    setCurrentLoginMethod(method);
  };

  const toggleLoginMethod = () => {
    // Only toggle if phone auth is enabled
    if (enablePhoneAuth) {
      setIsLoginFieldEmail(prev => !prev);
    }
  };

  const toggleLoginRegister = () => {
    setIsRegistering(prev => !prev);
  };

  const renderLoginComponent = () => {
    switch (currentLoginMethod) {
      case LoginMethod.WALLET:
        return (
          <WalletLoginModal
            sequenceWalletAddress={seqAddress}
            pendingConnect={pendingConnect}
            disabled={!agreed}
            buttonClassName={`
              w-full py-4 px-6 bg-blue-500 text-white rounded-xl font-medium
              transition-all duration-300 flex items-center justify-center gap-3
              ${
                !agreed
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-blue-600 hover:shadow-lg hover:shadow-blue-500/25 active:scale-[0.98]"
              }
            `}
          />
        );
      case LoginMethod.OTP:
        // If phone auth is disabled, always use email OTP
        return <OTPLogin isSignInFieldEmail={!enablePhoneAuth || isLoginFieldEmail} />;
      case LoginMethod.TRADITIONAL:
        return (
          <>
            <div className="flex items-center justify-between w-full mb-6">
              <div className="relative inline-flex items-center bg-gray-800/50 rounded-full p-1">
                <button
                  onClick={() => setIsLoginFieldEmail(true)}
                  className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
                    isLoginFieldEmail
                      ? "bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] text-white shadow-lg"
                      : "text-gray-400 hover:text-gray-300"
                  }`}
                >
                  Login with Email
                </button>
                {enablePhoneAuth && (
                  <button
                    onClick={() => setIsLoginFieldEmail(false)}
                    className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
                      !isLoginFieldEmail
                        ? "bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] text-white shadow-lg"
                        : "text-gray-400 hover:text-gray-300"
                    }`}
                  >
                    Login with Phone
                  </button>
                )}
              </div>
            </div>
            <div className="w-full">
              {isLoginFieldEmail ? (
                <TraditionalLogin />
              ) : (
                <OTPLogin 
                  isSignInFieldEmail={false} 
                  onSwitchToRegister={(phone) => {
                    setPhone(phone);
                    setIsLoginFieldEmail(false);
                    setIsRegistering(true);
                  }}
                />
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <AuthLayout>
        {/* Logo Section */}
        <div className="text-center mb-6">
          <h1 className="text-4xl md:text-5xl text-stone-200 font-extrabold tracking-tight mb-8">
            BrainCargo®
          </h1>
          <div className="relative w-32 h-32 md:w-40 md:h-40 mx-auto">
            <img
              src={BrainCoinImage}
              className="w-full h-full object-contain animate-float"
              alt="BrainCoin"
            />
            <div className="absolute inset-0 bg-gradient-radial from-blue-500/20 to-transparent rounded-full blur-xl -z-10" />
          </div>
        </div>

        {/* Login Section */}
        <div className="space-y-6">
          {/* Login Card */}
          <div className="bg-gray-900/50 rounded-2xl p-6 md:p-8">
            <div className="flex flex-col items-center gap-4">
              {isRegistering ? (
                <>
                  <div className="flex items-center justify-between w-full mb-6">
                    <div className="relative inline-flex items-center bg-gray-800/50 rounded-full p-1">
                      <button
                        onClick={() => setIsLoginFieldEmail(true)}
                        className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
                          isLoginFieldEmail
                            ? "bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] text-white shadow-lg"
                            : "text-gray-400 hover:text-gray-300"
                        }`}
                      >
                        Register with Email
                      </button>
                      {enablePhoneAuth && (
                        <button
                          onClick={() => setIsLoginFieldEmail(false)}
                          className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
                            !isLoginFieldEmail
                              ? "bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] text-white shadow-lg"
                              : "text-gray-400 hover:text-gray-300"
                          }`}
                        >
                          Register with Phone
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    {isLoginFieldEmail ? (
                      <NewRegister />
                    ) : (
                      <OTPRegister />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center justify-between w-full mb-6">
                    <div className="relative inline-flex items-center bg-gray-800/50 rounded-full p-1">
                      <button
                        onClick={() => setIsLoginFieldEmail(true)}
                        className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
                          isLoginFieldEmail
                            ? "bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] text-white shadow-lg"
                            : "text-gray-400 hover:text-gray-300"
                        }`}
                      >
                        Login with Email
                      </button>
                      {enablePhoneAuth && (
                        <button
                          onClick={() => setIsLoginFieldEmail(false)}
                          className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
                            !isLoginFieldEmail
                              ? "bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] text-white shadow-lg"
                              : "text-gray-400 hover:text-gray-300"
                          }`}
                        >
                          Login with Phone
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    {isLoginFieldEmail ? (
                      <TraditionalLogin />
                    ) : (
                      <OTPLogin 
                        isSignInFieldEmail={false} 
                        onSwitchToRegister={(phone) => {
                          setPhone(phone);
                          setIsLoginFieldEmail(false);
                          setIsRegistering(true);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Register Link */}
          <p className="text-sm text-center text-stone-400">
            {isRegistering ? (
              <>
                Already have an account?{" "}
                <button
                  onClick={() => setIsRegistering(false)}
                  className="font-medium text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                >
                  Login
                </button>
              </>
            ) : (
              <>
                Don't have an account?{" "}
                <button
                  onClick={() => setIsRegistering(true)}
                  className="font-medium text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                >
                  Register
                </button>
              </>
            )}
          </p>

          {/* Forgot Password Link */}
          {!isRegistering && (
            <p className="text-sm text-center text-stone-400">
              <button
                onClick={() => navigate(APP_URLS.FORGOT_PASSWORD)}
                className="font-medium text-blue-400 hover:text-blue-300 hover:underline transition-colors"
              >
                Forgot Password?
              </button>
            </p>
          )}
        </div>
      </AuthLayout>
    </>
  );
}
