import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

interface FeedDataTableHeaderProps {
  labels: { [key: string]: string };
  ordering: string;
  onOrderingChange: (newOrdering: string) => void;
}

const FeedDataTableHeader: React.FC<FeedDataTableHeaderProps> = ({
  labels,
  ordering,
  onOrderingChange,
}) => {
  const handleOrderingClick = (key: string) => {
    if (ordering === key) {
      // Toggle ordering if already ordered by this key
      onOrderingChange("-" + ordering);
    } else if (ordering === "-" + key) {
      // Toggle ordering if already ordered by this key
      onOrderingChange(ordering.replaceAll("-", ""));
    } else {
      // Set ordering to this key
      onOrderingChange(key);
    }
  };

  return (
    <thead className="text-xs uppercase bg-gray-400/50">
      <tr>
        {Object.entries(labels).map(([key, label]) => {
          let icon = null;
          if (ordering === key) {
            icon = (
              <ChevronDownIcon
                className="w-4"
                aria-hidden="true"
                aria-label="Sorted ascending"
              />
            );
          }
          if (ordering === "-" + key) {
            icon = (
              <ChevronUpIcon
                className="w-4"
                aria-hidden="true"
                aria-label="Sorted descending"
              />
            );
          }

          return (
            <th className="p-2" key={key}>
              <button
                onClick={() => handleOrderingClick(key)}
                className="flex flex-row gap-1"
                aria-label={`Sort by ${label}`}
              >
                {icon}
                {label}
              </button>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default FeedDataTableHeader; 