import React, { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { history } from "./helpers/history";
import Navigation from "./navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { AlchemyAccountProvider } from "@account-kit/react";
import { config, queryClient } from "./config";
import { AlchemyClientState } from "@account-kit/core";
import { getAccessToken } from "services/localStorage";

function App(props: PropsWithChildren<{ initialState?: AlchemyClientState }>) {
  history.navigate = useNavigate();
  history.location = useLocation();
  const token = getAccessToken();
  console.log("Token@App", token);

  useEffect(() => {
    console.log("Token@App", token);
    console.log("Token@App", config);
  }, [token]);

  return (
    <div className="App">
      <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <AlchemyAccountProvider
            config={config}
            queryClient={queryClient}
            initialState={props.initialState}
          >
            <Navigation />
          </AlchemyAccountProvider>
        </QueryClientProvider>
    </div>
  );
}

export default App;
