import { APP_URLS } from "../../../navigation";
import React, { ReactNode } from "react";

interface DesktopNavBarItemProps {
  href: string;
  icon: ReactNode;
  label: string;
  isActive?: boolean;
}

/**
 * Component that renders a list item used in DesktopNavBar item.
 */
export default function DesktopNavBarItem({
  href,
  icon,
  label,
  isActive = false,
}: DesktopNavBarItemProps) {
  const isCurrentPath = (url: string) => {
    if (
      url === APP_URLS.FEEDBACK &&
      window.location.href.includes(APP_URLS.FEEDBACK_LIST)
    ) {
      return true;
    }
    return window.location.href.includes(url);
  };

  const currentActive = isActive || isCurrentPath(href);

  return (
    <div className="relative group">
      {/* Active Indicator */}
      {currentActive && (
        <div className="absolute left-0 top-1/2 -translate-y-1/2 w-1 h-8 bg-blue-500 rounded-r-full" />
      )}

      <a
        href={href}
        className={`
          flex items-center gap-3 p-2.5 mx-2 rounded-xl
          transition-all duration-200 group
          ${
            currentActive
              ? "bg-gray-100 dark:bg-gray-800/40 text-blue-600 dark:text-blue-400"
              : "text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800/30"
          }
        `}
        aria-label={label}
        role="button"
        tabIndex={0}
      >
        {/* Icon with animation */}
        <div
          className={`
          flex-shrink-0 w-5 h-5
          transition-all duration-200
          ${
            currentActive
              ? "text-blue-600 dark:text-blue-400 scale-110"
              : "text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-300"
          }
        `}
        >
          {icon}
        </div>

        {/* Label with animation */}
        <span
          className={`
          text-sm font-medium whitespace-nowrap
          transition-all duration-200
          ${
            currentActive
              ? "text-gray-900 dark:text-white"
              : "text-gray-600 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          }
        `}
        >
          {label}
        </span>

        {/* Hover Effect */}
        <div
          className={`
          absolute inset-0 rounded-xl transition-all duration-200
          opacity-0 group-hover:opacity-100
          ${
            currentActive
              ? "bg-blue-500/5 dark:bg-blue-500/10"
              : "bg-gray-500/5 dark:bg-gray-500/10"
          }
        `}
        />
      </a>
    </div>
  );
}
