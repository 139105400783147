import React, { useEffect, useState } from "react";
import { Label, TextInput, ToggleSwitch } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { displayErrors } from "../../helpers/errors";
import {
  addItemToPlaylists,
  addPlaylist,
  getMyPlaylists,
  getPlaylist,
  updatePlaylist,
} from "../../redux/playlist/playlistSlice";
import { isDispatchResponseError } from "../../redux/utils";
import Modal from "../../components/common/modal";
import { PayloadAction } from "@reduxjs/toolkit";
import { useDebounce } from "use-debounce";
import { fetchUsersForSharing } from "../../redux/users/usersSlice";
import { User } from "../../types/user";
import { FeedItem } from "../../types/feed";
import {
  AddItemToPlaylistPayload,
  Playlist,
  PlaylistPayload,
} from "../../types/playlist";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  show: boolean;
  onClose: () => void;
  playlistId?: string;
  itemId?: FeedItem["id"];
}

export default function ModalEditCreatePlaylist({
  show,
  onClose,
  playlistId,
  itemId,
}: Props) {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users.users);
  const addPlaylistErrors = useAppSelector(
    (state) => state.playlist.addPlaylistErrors
  );
  const updatePlaylistErrors = useAppSelector(
    (state) => state.playlist.updatePlaylistErrors
  );
  const playlist = useAppSelector((state) => state.playlist.playlist);
  const sharePlaylistsWithUsersErrors = useAppSelector(
    (state) => state.playlist.sharePlaylistsWithUsersErrors
  );
  const [name, setName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [selectedUserIds, setSelectedUserIds] = useState<Array<User["id"]>>([]);
  const [sharedChecked, setSharedChecked] = useState(false);

  /**
   * Update or create a playlist.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const payloadEditCreatePlaylist: PlaylistPayload = {
      name,
    };

    let shared_with: { id: string }[] = [];
    if (sharedChecked) {
      shared_with = selectedUserIds.map((item) => ({ id: item }));
    }
    payloadEditCreatePlaylist.shared_with = shared_with;

    let response: PayloadAction<any> | undefined;
    if (playlistId) {
      response = await dispatch(
        updatePlaylist({
          playlistId,
          data: payloadEditCreatePlaylist,
        })
      );
    } else {
      response = await dispatch(addPlaylist(payloadEditCreatePlaylist));
    }

    if (!isDispatchResponseError(response)) {
      if (itemId) {
        // Add item to playlist
        const editedOrCreatedPlaylist = response.payload as Playlist;
        const addItemToPlaylistPayload: AddItemToPlaylistPayload = {
          playlist_ids: [editedOrCreatedPlaylist.id],
          feed_item_id: itemId,
        };

        const addItemToPlaylistsResponse = await dispatch(
          addItemToPlaylists(addItemToPlaylistPayload)
        );
        if (!isDispatchResponseError(addItemToPlaylistsResponse)) {
          await dispatch(getMyPlaylists({ queryParams: { page_size: "100" } }));
          setName("");
          setSharedChecked(false);
          setSelectedUserIds([]);
          onClose();
        }
      } else {
        await dispatch(getMyPlaylists({ queryParams: { page_size: "100" } }));
        setName("");
        setSharedChecked(false);
        setSelectedUserIds([]);
        onClose();
      }
    }
  };

  /**
   * Update selected users whom the playlist is to be shared with.
   */
  const handleChangeSelectedUsers = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedUserId = e.target.value;
    const checked = e.target.checked;
    let newSelectedUserIds = [...selectedUserIds];
    if (checked) {
      newSelectedUserIds = [...newSelectedUserIds, selectedUserId];
    } else {
      newSelectedUserIds = newSelectedUserIds.filter(
        (item) => item !== selectedUserId
      );
    }
    setSelectedUserIds(newSelectedUserIds);
  };

  /**
   * Fetch playlist if editing an existing playlist.
   */
  useEffect(() => {
    if (playlistId) {
      dispatch(getPlaylist({ id: playlistId }));
    }
  }, [playlistId]);

  /**
   * Prepopulate form fields if editing an existing playlist.
   */
  useEffect(() => {
    if (playlist) {
      setName(playlist.name);
      if (playlist.shared_with) {
        setSharedChecked(true);
      } else {
        setSharedChecked(false);
      }
      setSelectedUserIds(playlist.shared_with.map((item) => item.id));
    }
  }, [playlist]);

  /**
   * Fetch users based on search query.
   */
  useEffect(() => {
    dispatch(fetchUsersForSharing(debouncedSearchQuery));
  }, [debouncedSearchQuery]);

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 
                    transition-all duration-200 ${
                      show ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
    >
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div
        className="relative w-full max-w-md bg-white dark:bg-[#1e2533] 
                    rounded-2xl shadow-xl transform transition-all duration-200
                    border border-gray-200 dark:border-white/5"
      >
        {/* Header */}
        <div
          className="flex items-center justify-between p-4 
                     border-b border-gray-200 dark:border-white/5"
        >
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {playlistId ? "Edit Playlist" : "Create Playlist"}
          </h3>
          <button
            onClick={onClose}
            className="p-1 rounded-lg text-gray-400 hover:text-gray-500 
                     dark:text-gray-500 dark:hover:text-gray-400
                     hover:bg-gray-100 dark:hover:bg-white/5
                     transition-all duration-200"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Body */}
        <div className="p-4 space-y-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Playlist Name */}
            <div>
              <Label
                htmlFor="name"
                className="block mb-1.5 text-sm font-medium 
                             text-gray-700 dark:text-gray-200"
              >
                Playlist name
              </Label>
              <TextInput
                id="playlist_name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="My Favorite Playlist"
                className="w-full px-3 py-2 bg-white dark:bg-[#1e2533]/50
                         border border-gray-300 dark:border-white/10
                         rounded-lg focus:ring-2 focus:ring-blue-500/20
                         dark:text-white text-sm"
                aria-label="Playlist name"
              />
              {displayErrors(addPlaylistErrors.name)}
              {displayErrors(updatePlaylistErrors.name)}
            </div>

            {/* Share Toggle */}
            <div
              className="flex items-center justify-between p-3 
                         bg-gray-50 dark:bg-white/5 rounded-lg"
            >
              <div>
                <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                  Shared
                </h4>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  Allow selected users to view this collection
                </p>
              </div>
              <ToggleSwitch
                checked={sharedChecked}
                onChange={setSharedChecked}
                className="ml-4"
              />
            </div>

            {/* User Selection */}
            {sharedChecked && (
              <div className="space-y-3">
                <TextInput
                  placeholder="Search users..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full"
                />
                <div
                  className="border dark:border-white/10 rounded-lg 
                             max-h-[200px] overflow-y-auto"
                >
                  <table className="w-full">
                    <thead className="bg-gray-50 dark:bg-white/5 sticky top-0">
                      <tr>
                        <th
                          className="px-4 py-3 text-left text-xs font-medium 
                                   text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                        >
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td className="px-4 py-3">
                            <label className="flex items-center space-x-3">
                              <input
                                type="checkbox"
                                value={user.id}
                                checked={selectedUserIds.includes(user.id)}
                                onChange={handleChangeSelectedUsers}
                                className="w-4 h-4 text-blue-600 rounded 
                                         border-gray-300 dark:border-white/10
                                         focus:ring-blue-500/20"
                              />
                              <span className="text-sm text-gray-900 dark:text-white">
                                {user.username}
                              </span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </form>
        </div>

        {/* Footer */}
        <div
          className="flex justify-end gap-3 p-4 border-t 
                     border-gray-200 dark:border-white/5"
        >
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200
                     bg-gray-100 dark:bg-white/5 rounded-lg
                     hover:bg-gray-200 dark:hover:bg-white/10
                     transition-all duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={!name}
            className="px-4 py-2 text-sm font-medium text-white
                     bg-blue-600 rounded-lg
                     hover:bg-blue-700 
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition-all duration-200"
          >
            {playlistId ? "Save Changes" : "Create Playlist"}
          </button>
        </div>
      </div>
    </div>
  );
}
