import React from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  errorMessages?: string[];
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  type = "text",
  value,
  onChange,
  placeholder = "",
  errorMessages = [],
  containerClassName = "mb-4 w-full",
  labelClassName = "block text-stone-200 text-sm font-bold mb-2",
  inputClassName = "block w-full px-4 py-3 text-black bg-white border rounded-lg focus:outline-none focus:border-purple-500 appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
  errorClassName = "text-red-500 text-xs mt-1",
  ...props
}) => {
  const displayErrors = (errors: string[] | string) => {
    const errorArray = Array.isArray(errors) ? errors : [errors];
    return errorArray.map((error, index) => (
      <p key={index} className={errorClassName}>
        {error}
      </p>
    ));
  };

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={inputClassName}
        required
        {...props}
      />
      {displayErrors(errorMessages)}
    </div>
  );
};

export default TextInput;
