import React, { useEffect } from "react";
import { Step, Stepper } from "react-form-stepper";
import { useAppSelector } from "../../redux/hooks";
import NeuralNetworks from "./NeuralNetworks";
import ImportDataset from "./ImportDataset";
import NewRegister from "../Login/NewRegister";
import MainQuiz from "./MainQuiz";

const STEPPER_STYLES = {
  completedBgColor: "#4E80EF",
  completedTextColor: "#ffffff",
  activeBgColor: "#3562E2",
  activeTextColor: "#ffffff",
  inactiveBgColor: "#e0e0e0",
  inactiveTextColor: "#ffffff",
  size: "2em",
  circleFontSize: "1rem",
  labelFontSize: "0.875rem",
  borderRadius: "50%",
  fontWeight: 500,
};

const REGISTRATION_STEPS = [
  { idx: 0, label: "Register", page: <NewRegister /> },
  { idx: 1, label: "Pick Neural Network", page: <NeuralNetworks /> },
  { idx: 2, label: "Upload/Import Dataset", page: <ImportDataset /> },
  { idx: 3, label: "Media Preference Quiz", page: <MainQuiz /> },
];

/**
 * Onboarding page
 */
export default function Onboarding() {
  const activeStepIndex = useAppSelector(
    (state) => state.auth.activeStepIndex,
  );

  /**
   * Render list of steps
   */
  const displaySteps = () => {
    return REGISTRATION_STEPS.map((step, idx) => (
      <Step key={idx} label={step.label} />
    ));
  };

  /**
   * Render page corresponding to the active step
   */
  const displayPage = () => {
    return REGISTRATION_STEPS[activeStepIndex].page;
  };

  useEffect(() => {
    document.title = "Onboarding | BrainCargo®";
  }, []);

  return (
    <div className="flex bg-gray-100 h-screen">
      <div className="flex flex-col mx-auto">
        {/* <Stepper
            activeStep={activeStepIndex}
            className="w-auto"
            styleConfig={STEPPER_STYLES}
          >
            {displaySteps()}
          </Stepper> */}
        {displayPage()}
      </div>
    </div>
  );
}
