import React from 'react';

interface PaginationControlsProps {
  currentPage: number;
  onPageChange: (newPage: number) => void;
  totalResults: number;
  currentResults: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
  onPageChange,
  totalResults,
  currentResults,
  hasNextPage,
  hasPreviousPage,
}) => {
  return (
    <div className="mt-4 text-gray-400 text-sm flex justify-between items-center">
      <div>
        Showing {currentResults} of {totalResults} results
      </div>
      <div className="flex gap-4">
        <button
          className="text-gray-400 hover:text-white disabled:opacity-50"
          disabled={!hasPreviousPage}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          className="text-gray-400 hover:text-white disabled:opacity-50"
          disabled={!hasNextPage}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaginationControls; 