import React from 'react';
import { Button, Modal } from 'flowbite-react';
import { useLogout } from "@account-kit/react";
import { removeTokens } from "../../services/localStorage";
import { APP_URLS } from "../../navigation";
import { history } from "../../helpers/history";
import { getRefreshToken } from "services/localStorage";
import { post } from "services/apiService";

interface LogoutModalProps {
  showModal: boolean;
  onClose: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ showModal, onClose }) => {
  const { logout } = useLogout();

  const blacklistRefreshToken = async () => {
    try {
      const refreshToken = getRefreshToken();
      await post("/api/register/blacklist/token/", {
        refresh_token: refreshToken,
      });
    } catch (error) {
      console.error("Error blacklisting refresh token:", error);
    }
  };

  const handleConfirmLogout = async () => {
    try {
      await blacklistRefreshToken();
      localStorage.removeItem("lastLoginTime");
      logout();
      removeTokens();
      history.navigate(APP_URLS.LOGIN);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      popup
      size="sm"
      className="bg-gray-700"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-300">
            Are you sure you want to logout?
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleConfirmLogout}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal; 