import { StripeNFTProduct } from "../../types/payments";
import NoImage from "../../images/No-Image.png";
import React from "react";
import { Spinner } from "flowbite-react";
import { CurrencyDollarIcon, SparklesIcon } from "@heroicons/react/24/outline";

interface Props {
  data: StripeNFTProduct;
  onClick: () => void;
  selected: boolean;
  isLoading?: boolean;
}

export default function NFTProductClickable({
  data,
  onClick,
  selected,
  isLoading,
}: Props) {
  const [imageLoading, setImageLoading] = React.useState(true);

  function getImageSrc() {
    return data.images[0] || NoImage;
  }

  function formatPrice(price: number) {
    return "$" + (price / 100).toLocaleString();
  }

  return (
    <div
      className={`
        relative bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden
        border border-gray-700/50 transition-all duration-300 w-full
        ${selected ? "ring-2 ring-blue-500" : "hover:border-blue-500/50"}
      `}
    >
      <div className="flex flex-col sm:flex-row h-full">
        {/* Image Section */}
        <div className="relative w-full sm:w-1/3">
          <div className="relative aspect-square">
            <img
              src={data.images[0] || NoImage}
              alt={data.name}
              className="w-full h-full object-cover"
            />
            {/* Price Tag */}
            <div className="absolute top-3 right-3 z-10">
              <div className="bg-green-500 text-white px-4 py-2 rounded-full text-base font-bold shadow-lg">
                ${(data.price.unit_amount / 100).toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="w-full sm:w-2/3 p-5 flex flex-col justify-between">
          <div className="space-y-4">
            {/* Title */}
            <h3 className="text-xl sm:text-2xl font-bold text-white">
              {data.name}
            </h3>

            {/* BRAIN Reward */}
            <div className="bg-blue-900/30 rounded-xl p-4">
              <div className="flex items-center gap-3">
                <SparklesIcon className="w-6 h-6 text-blue-400" />
                <span className="text-blue-300 text-lg">
                  Receive{" "}
                  <span className="font-bold text-blue-200">
                    {data.brain} BRAIN
                  </span>
                </span>
              </div>
            </div>
          </div>

          {/* Purchase Button */}
          <button
            onClick={onClick}
            disabled={isLoading}
            className="mt-4 w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 
                     disabled:bg-blue-800/50 disabled:cursor-not-allowed
                     text-white rounded-xl font-medium transition-all duration-200
                     text-lg transform hover:-translate-y-0.5"
          >
            {isLoading ? (
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent"></div>
                <span>Processing...</span>
              </div>
            ) : (
              "Purchase NFT"
            )}
          </button>
        </div>
      </div>

      {/* Selected Badge */}
      {selected && (
        <div
          className="absolute top-3 left-3 bg-blue-500 text-white 
                       px-4 py-2 rounded-full text-sm font-bold shadow-lg"
        >
          Selected
        </div>
      )}

      {/* Loading Overlay */}
      {isLoading && (
        <div
          className="absolute inset-0 bg-black/60 backdrop-blur-sm z-20 
                       flex items-center justify-center"
        >
          <div className="flex flex-col items-center gap-3">
            <div
              className="animate-spin rounded-full h-12 w-12 border-4 
                           border-blue-500 border-t-transparent"
            ></div>
            <span className="text-white font-medium">Processing...</span>
          </div>
        </div>
      )}
    </div>
  );
}
