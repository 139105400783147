import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ModalEditCreatePlaylist from "../../components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "../../components/playlist/SelectPlaylistPopup";
import PageWrapper from "../../components/common/pageWrapper";
import NewsItem from "../../components/common/news";
import { isDispatchResponseError } from "../../redux/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "../../components/playlist/MyPlaylistList";
import SharedPlaylistList from "../../components/playlist/SharedPlaylistList";
import Header from "../../components/common/header";
import { retrieveConnectedAdvertisers } from "../../redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "../../components/ads/AdByRandomProvider";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "../../redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "../../types/feed";
import { ReportFeedItemModal } from "../../components/report/ReportFeedItemModal";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";

/**
 * News page
 */
export default function News() {
  const dispatch = useAppDispatch();
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [readFor, setReadFor] = useState(0);
  const [blurDate, setBlurDate] = useState<Date | null>(null);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);

  /**
   * Mark feedItem as liked in the database
   */
  const handleLikeFeedItem = async () => {
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random feedItem
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } })
      );
    }
  };

  /**
   * Mark feedItem as disliked in the database
   */
  const handleDislikeFeedItem = async () => {
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random news item
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } })
      );
    }
  };

  /**
   * Mark feedItem as skipped in the database
   */
  const handleSkipFeedItem = async () => {
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random news
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } })
      );
    }
  };

  /**
   * Mark feedItem as seen in the database
   */
  const onClickReadMore = () => {
    dispatch(watchFeedItem(feedItem?.id || ""));
    setBlurDate(new Date());
  };

  /**
   * Render a list of liked news
   */
  const displayLikedFeedItems = () => {
    if (likedFeedItems.length > 0) {
      return likedFeedItems.map((likedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <NewsItem news={likedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No Liked News added yet!
        </p>
      );
    }
  };

  /**
   * Update how long user has watched this media
   */
  function updateWatchedFor() {
    if (feedItem && readFor > 0) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: readFor,
          watched: true,
        })
      );
      setReadFor(0);
    }
  }

  /**
   * Render a list of disliked news
   */
  const displayDislikedFeedItems = () => {
    if (dislikedFeedItems.length > 0) {
      return dislikedFeedItems.map((dislikedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <NewsItem news={dislikedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No Disliked News added yet!
        </p>
      );
    }
  };

  /**
   * Retrieve data
   */
  useEffect(() => {
    document.title = "News | BrainCargo®";

    dispatch(
      getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } })
    );

    dispatch(
      getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.NEWS } })
    );

    dispatch(
      getDislikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.NEWS } })
    );
    dispatch(retrieveConnectedAdvertisers());
  }, []);

  /**
   * Update watchedFor when user leaves the page
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    const onVisibilityChange = function (e: Event) {
      if (!document.hidden && blurDate) {
        const now = new Date();
        const seconds = (now.getTime() - blurDate.getTime()) / 1000;
        setReadFor(readFor + seconds);
        setBlurDate(null);
      }
    };

    window.addEventListener("beforeunload", onUnload);
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [readFor, blurDate]);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-[#1E2028] transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 space-y-6">
          {/* Header */}
          <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-blue-600 to-indigo-600">
            <div className="absolute inset-0 bg-grid-white/10 [mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]" />
            <div className="relative px-6 py-12 sm:px-10 sm:py-16">
              <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4">
                News Feed
              </h1>
              <p className="text-lg text-blue-100">
                Discover and curate your personalized news content
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Main Content */}
            <div className="lg:col-span-2 space-y-6">
              {/* Current News Item */}
              <div className="bg-[#252832] rounded-2xl shadow-lg transition-all duration-200 overflow-hidden">
                {feedItem ? (
                  <div className="p-6">
                    <div className="flex flex-col md:flex-row gap-6">
                      <div className="w-full md:w-1/3 aspect-video md:aspect-square relative rounded-xl overflow-hidden">
                        <img
                          src={feedItem.image || "/placeholder-news.jpg"}
                          className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                          alt={feedItem.title}
                        />
                      </div>
                      <div className="flex-1 space-y-4">
                        <h2 className="text-2xl font-bold text-white line-clamp-2">
                          {feedItem.title}
                        </h2>
                        <p className="text-gray-300 line-clamp-3">
                          {feedItem.description}
                        </p>
                        <a
                          className="inline-flex items-center text-blue-400 hover:text-blue-300 font-medium group"
                          href={feedItem.bing_news_url}
                          rel="noreferrer"
                          target="_blank"
                          onClick={onClickReadMore}
                        >
                          Read more
                          <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2 transform transition-transform group-hover:translate-x-1" />
                        </a>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-wrap items-center gap-3 mt-6 pt-6 border-t border-gray-700/50">
                      <div className="flex gap-2">
                        <button
                          onClick={handleLikeFeedItem}
                          disabled={!feedItem}
                          className="inline-flex items-center px-4 py-2 bg-blue-500/10 
                                   text-blue-400 rounded-lg hover:bg-blue-500/20 
                                   transition-all duration-200 disabled:opacity-50"
                        >
                          <HandThumbUpIcon className="w-5 h-5 mr-2" />
                          Like
                        </button>
                        <button
                          onClick={handleDislikeFeedItem}
                          disabled={!feedItem}
                          className="inline-flex items-center px-4 py-2 bg-gray-500/10 
                                   text-gray-300 rounded-lg hover:bg-gray-500/20 
                                   transition-all duration-200 disabled:opacity-50"
                        >
                          <HandThumbDownIcon className="w-5 h-5 mr-2" />
                          Dislike
                        </button>
                      </div>

                      <div className="flex gap-2 ml-auto">
                        <button
                          onClick={handleSkipFeedItem}
                          disabled={!feedItem}
                          className="inline-flex items-center px-4 py-2 bg-gray-500/10 
                                   text-gray-300 rounded-lg hover:bg-gray-500/20 
                                   transition-all duration-200 disabled:opacity-50"
                        >
                          <ForwardIcon className="w-5 h-5 mr-2" />
                          Skip
                        </button>
                        <button
                          onClick={() => setShowReportModal(true)}
                          disabled={!feedItem}
                          className="inline-flex items-center px-4 py-2 bg-red-500/10 
                                   text-red-400 rounded-lg hover:bg-red-500/20 
                                   transition-all duration-200 disabled:opacity-50"
                        >
                          <FlagIcon className="w-5 h-5 mr-2" />
                          Report
                        </button>
                        <button
                          onClick={() => setShowSelectPlaylistPopup(true)}
                          disabled={!feedItem}
                          className="inline-flex items-center px-4 py-2 bg-green-500/10 
                                   text-green-400 rounded-lg hover:bg-green-500/20 
                                   transition-all duration-200 disabled:opacity-50"
                        >
                          <PlusIcon className="w-5 h-5 mr-2" />
                          Add to Playlist
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-12 text-center">
                    <div className="animate-pulse flex flex-col items-center">
                      <div className="rounded-full bg-gray-700 h-16 w-16 mb-4"></div>
                      <p className="text-gray-400 font-medium">Loading news...</p>
                    </div>
                  </div>
                )}
              </div>

              {/* Advertisement Section */}
              <div className="bg-[#252832] rounded-xl shadow-lg p-6">
                <AdByRandomProvider className="w-full" />
              </div>

              {/* Liked and Disliked News Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Liked News */}
                <div className="bg-[#252832] rounded-xl shadow-lg">
                  <div className="px-6 py-4 border-b border-gray-700/50 flex items-center justify-between">
                    <h3 className="text-lg font-semibold text-white">
                      Liked News
                    </h3>
                    <span className="px-2.5 py-0.5 text-sm bg-blue-500/10 text-blue-400 rounded-full">
                      {likedFeedItems.length}
                    </span>
                  </div>
                  <div className="divide-y divide-gray-700/50 max-h-[40rem] overflow-auto 
                              scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent p-4">
                    {displayLikedFeedItems()}
                  </div>
                </div>

                {/* Disliked News */}
                <div className="bg-[#252832] rounded-xl shadow-lg">
                  <div className="px-6 py-4 border-b border-gray-700/50 flex items-center justify-between">
                    <h3 className="text-lg font-semibold text-white">
                      Disliked News
                    </h3>
                    <span className="px-2.5 py-0.5 text-sm bg-red-500/10 text-red-400 rounded-full">
                      {dislikedFeedItems.length}
                    </span>
                  </div>
                  <div className="divide-y divide-gray-700/50 max-h-[40rem] overflow-auto 
                              scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent p-4">
                    {displayDislikedFeedItems()}
                  </div>
                </div>
              </div>
            </div>

            {/* Playlists Sidebar */}
            <div className="space-y-6 lg:sticky lg:top-6">
              <div className="bg-[#252832] rounded-xl shadow-lg p-4">
                <MyPlaylistList smallHeader />
              </div>
              <div className="bg-[#252832] rounded-xl shadow-lg">
                <SharedPlaylistList smallHeader />
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        {showSelectPlaylistPopup && feedItem && (
          <SelectPlaylistPopup
            feedItem={feedItem}
            show={showSelectPlaylistPopup}
            onClose={() => setShowSelectPlaylistPopup(false)}
            headerText="Add news to playlist"
          />
        )}
        {feedItem && (
          <ReportFeedItemModal
            show={showReportModal}
            setShow={setShowReportModal}
            feedItem={feedItem}
          />
        )}
        <Footer />
      </div>
    </PageWrapper>
  );
}
