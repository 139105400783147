import React, { useEffect } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import { useAppSelector } from "../../redux/hooks";
import SelectDataSetsTable from "../../components/dataSets/SelectDataSetsTable";
import { APP_URLS } from "../../navigation";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";

/**
 * Dataset list page.
 */
export default function DataSets() {
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    document.title = "Datasets | BrainCargo®";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-all duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
          {/* Header Section */}
          <div
            className="bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-700 
                         dark:to-indigo-700 rounded-t-2xl p-6 sm:p-8 shadow-lg"
          >
            <MainHeader title="Datasets" />
            <p className="text-blue-50 text-base sm:text-lg mt-2">
              Manage and explore your data collections
            </p>
          </div>

          {/* Main Content */}
          <div
            className="bg-white dark:bg-gray-800 rounded-b-2xl shadow-lg 
                         transition-all duration-200 border border-gray-200 dark:border-gray-700"
          >
            <div className="p-6">
              <div className="prose dark:prose-invert max-w-none mb-8">
                <p className="text-gray-600 dark:text-gray-300">
                  Below you will find a list of all datasets uploaded to the
                  BrainCargo® database. You can upload new datasets and select
                  existing ones to use them in other parts of the application.
                </p>
              </div>

              <SelectDataSetsTable headerText="" />

              {user?.is_superuser && (
                <div className="mt-8 pt-6 border-t dark:border-gray-700">
                  <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                      Feed Data
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300">
                      Click the following link to see all data we have collected
                      related to your watch history:{" "}
                      <a
                        href={APP_URLS.FEED_DATA_USER_DETAILS.replace(
                          ":userId",
                          user.id
                        )}
                        className="inline-flex items-center text-blue-600 dark:text-blue-400 
                                 font-medium hover:text-blue-700 dark:hover:text-blue-300 
                                 transition-colors duration-200"
                      >
                        Feed Data
                        <svg
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
