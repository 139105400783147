import React from "react";
import PageWrapper from "components/common/pageWrapper";
import {
  EnvelopeIcon,
  PhoneIcon,
  ClockIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Footer from "components/Footer/Footer";
import MainHeader from "components/MainHeader/MainHeader";

export default function Support() {
  const contactInfo = {
    phone: "+1 (555) 123-4567",
    email: "support@braincargo.com",
    address: "123 Tech Plaza, Silicon Valley, CA 94025",
  };

  const supportHours = {
    weekday: "Monday through Friday",
    time: "9:00 AM to 6:00 PM EST",
  };

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gradient-to-b dark:from-[#1a1f2b] dark:to-[#151923]">
        <MainHeader />
        <div className="max-w-7xl mx-auto px-4 py-12 pt-16">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-4 dark:text-white">
              We're Here to Help
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
              Get the support you need, when you need it. Our team is ready to
              assist you.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="mb-16">
            <Link
              to="/faq"
              className="group p-6 rounded-xl border dark:border-white/5 bg-white/5 
                       backdrop-blur-sm hover:bg-white/10 transition-all duration-200 block"
            >
              <div className="flex items-start gap-4">
                <div
                  className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center 
                             justify-center flex-shrink-0"
                >
                  <QuestionMarkCircleIcon className="w-6 h-6 text-blue-500" />
                </div>
                <div className="flex-1">
                  <h3
                    className="text-lg font-semibold mb-2 dark:text-white group-hover:text-blue-500 
                              transition-colors"
                  >
                    Frequently Asked Questions
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Find quick answers to common questions
                  </p>
                </div>
                <ArrowRightIcon
                  className="w-5 h-5 text-gray-400 group-hover:text-blue-500 
                                       transition-colors"
                />
              </div>
            </Link>
          </div>

          {/* Contact Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            {/* Phone Support */}
            <div className="p-6 rounded-xl border dark:border-white/5 bg-white/5 backdrop-blur-sm">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center">
                  <PhoneIcon className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold dark:text-white">
                    Phone Support
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Talk to us directly
                  </p>
                </div>
              </div>
              <a
                href={`tel:${contactInfo.phone}`}
                className="text-xl font-medium text-blue-500 hover:text-blue-600 
                         transition-colors block mb-4"
              >
                {contactInfo.phone}
              </a>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Available during business hours
              </p>
            </div>

            {/* Email Support */}
            <div className="p-6 rounded-xl border dark:border-white/5 bg-white/5 backdrop-blur-sm">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center">
                  <EnvelopeIcon className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold dark:text-white">
                    Email Support
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Write to us anytime
                  </p>
                </div>
              </div>
              <a
                href={`mailto:${contactInfo.email}`}
                className="text-xl font-medium text-blue-500 hover:text-blue-600 
                         transition-colors block mb-4"
              >
                {contactInfo.email}
              </a>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                24/7 response within 24 hours
              </p>
            </div>
          </div>

          {/* Location & Hours */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Location */}
            <div className="p-6 rounded-xl border dark:border-white/5 bg-white/5 backdrop-blur-sm">
              <div className="flex items-center gap-4 mb-6">
                <div className="w-10 h-10 rounded-full bg-blue-500/10 flex items-center justify-center">
                  <MapPinIcon className="w-5 h-5 text-blue-500" />
                </div>
                <h3 className="text-xl font-semibold dark:text-white">
                  Our Location
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                {contactInfo.address}
              </p>
              <a
                href={`https://maps.google.com/?q=${encodeURIComponent(
                  contactInfo.address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 transition-colors inline-flex items-center gap-2"
              >
                View on Map
                <ArrowRightIcon className="w-4 h-4" />
              </a>
            </div>

            {/* Business Hours */}
            <div className="p-6 rounded-xl border dark:border-white/5 bg-white/5 backdrop-blur-sm">
              <div className="flex items-center gap-4 mb-6">
                <div className="w-10 h-10 rounded-full bg-blue-500/10 flex items-center justify-center">
                  <ClockIcon className="w-5 h-5 text-blue-500" />
                </div>
                <h3 className="text-xl font-semibold dark:text-white">
                  Business Hours
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-2">
                {supportHours.weekday}
              </p>
              <p className="text-gray-600 dark:text-gray-400">
                {supportHours.time}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
