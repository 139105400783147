import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateUser } from "../../redux/user/userSlice";
import {
  retrieveQuizAnswers,
  retrieveQuizzes,
} from "../../redux/quiz/quizSlice";
import { fetchCheckoutSessions } from "../../redux/payments/paymentsSlice";
import PageWrapper from "../../components/common/pageWrapper";
import {
  Button,
  Spinner,
  ToggleSwitch,
  TextInput,
  Modal,
} from "flowbite-react";
import SpotifyConnection from "../../components/connections/SpotifyConnection";
import YoutubeConnection from "../../components/connections/YoutubeConnection";
import ShazamConnection from "../../components/connections/ShazamConnection";
import NFTProductSelectionModal from "../../components/nft/NFTProductSelectionModal";
import { UserIcon } from "@heroicons/react/24/solid";
import { history } from "../../helpers/history";
import { APP_URLS } from "../../navigation";
import { Quiz } from "../../types/quiz";
import { removeTokens } from "../../services/localStorage";
import { formatDate } from "../../helpers/date";
import NFTRedeemableModal from "../../components/nft/NFTRedeemableModal";
import { useLogout } from "@account-kit/react";
import { ConnectWalletModal } from "../../components/wallet/ConnectWalletModal";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";
import { QuizShimmerLoader } from "../../components/common/ShimmerLoader";

export default function Profile({
  sequenceWalletAddress,
  pendingConnect,
}: {
  sequenceWalletAddress: string | null | undefined;
  pendingConnect: boolean;
}) {
  // Redux state
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const quizzes = useAppSelector((state) => state.quiz.quizzes);
  const quizAnswers = useAppSelector((state) => state.quiz.answers);
  const pendingRetrieveQuizzes = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizzes
  );
  const pendingRetrieveQuizAnswers = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizAnswers
  );

  // User related state
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableUser, setEditableUser] = useState({
    username: user?.username || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    phone: user?.phone || "",
    is_touch_interface_enabled: user?.is_touch_interface_enabled || false,
  });

  // Quiz related state
  const [mainQuiz, setMainQuiz] = useState<Quiz | null>(null);

  // NFT related state
  const [showNFTProductSelectionModal, setShowNFTProductSelectionModal] =
    useState(false);
  const [showRedeemableModal, setShowRedeemableModal] = useState(false);

  const { logout } = useLogout();

  // Add state for logout modal
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  /**
   * Navigate to quiz page
   */
  function onClickTakeQuiz() {
    console.log("mainQuiz", mainQuiz);
    if (mainQuiz) {
      const url = APP_URLS.PROFILE_QUIZ.replace(":id", mainQuiz.id);
      history.navigate(url);
    }
  }

  /**
   * Render take/retake quiz button
   */
  function renderQuizButton() {
    const pending = pendingRetrieveQuizAnswers || pendingRetrieveQuizzes;

    if (pending) {
      return <QuizShimmerLoader />;
    }

    return (
      <div className="bg-[#1E2028] rounded-xl p-6 w-full">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          {/* Left Section - Title and Description */}
          <div className="space-y-2">
            <h3 className="text-xl font-semibold text-white">
              Media Preference Quiz
            </h3>
            <p className="text-gray-400 text-sm">
              Take our quiz to personalize your experience
            </p>
          </div>

          {/* Right Section - Button and Last Taken */}
          <div className="flex flex-col items-end gap-2">
            <button
              onClick={onClickTakeQuiz}
              disabled={pending}
              className={`
                px-6 py-2.5 rounded-lg font-medium text-sm
                transition-all duration-200 flex items-center gap-2
                ${
                  pending
                    ? "bg-blue-600/50 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:-translate-y-0.5"
                }
                text-white
              `}
            >
              {pending ? (
                <>
                  <div
                    className="animate-spin rounded-full h-4 w-4 border-2 
                                border-white border-t-transparent"
                  ></div>
                  <span>Loading...</span>
                </>
              ) : (
                <>
                  {quizAnswers.length === 0 ? (
                    <>
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                      <span>Take Quiz</span>
                    </>
                  ) : (
                    <>
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                      <span>Retake Quiz</span>
                    </>
                  )}
                </>
              )}
            </button>

            {quizAnswers.length !== 0 && (
              <div className="flex items-center gap-2 text-sm text-gray-400">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>
                  Last taken: {formatDate(quizAnswers[0].updated_at || "")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Update the logout click handler to show modal
   */
  const onLogoutClick = () => {
    setShowLogoutModal(true);
  };

  /**
   * Add handler for confirming logout
   */
  const handleConfirmLogout = () => {
    localStorage.removeItem("lastLoginTime");
    logout();
    removeTokens();
    history.navigate(APP_URLS.LOGIN);
    setShowLogoutModal(false);
  };

  /**
   * Retrieve main quiz
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(retrieveQuizzes({ queryParams: { main_quiz: "true" } }));
      } catch (error) {
        console.error("Failed to retrieve quizzes:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  /**
   * Retrieve main quiz answers and extract main quiz data
   */
  useEffect(() => {
    console.log("quizzes", quizzes, "::", quizzes.results.length);

    if (quizzes.results.length > 0) {
      const newMainQuiz = quizzes.results[0];
      // Retrieve answers to the quiz
      dispatch(retrieveQuizAnswers({ quizId: newMainQuiz.id }));
      setMainQuiz(newMainQuiz);
    }
  }, [quizzes]);

  useEffect(() => {
    if (user) {
      setEditableUser({
        username: user.username || "",
        email: user.email || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        phone: user.phone || "",
        is_touch_interface_enabled: user.is_touch_interface_enabled || false,
      });
    }
  }, [user]);

  /**
   * Handle touch interface toggle change
   */
  const handleTouchInterfaceChange = (isEnabled: boolean) => {
    setEditableUser((prev) => ({
      ...prev,
      is_touch_interface_enabled: isEnabled,
    }));
  };

  /**
   * Handle input changes
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Save changes
   */
  const handleSaveChanges = () => {
    toast.promise(dispatch(updateUser(editableUser)), {
      pending: "Saving changes...",
      success: "Changes saved successfully!",
      error: "Failed to save changes.",
    });
    setIsEditMode(false);
  };

  useEffect(() => {
    dispatch(fetchCheckoutSessions());
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
        <Modal
          show={showLogoutModal}
          onClose={() => setShowLogoutModal(false)}
          popup
          size="sm"
          className="bg-gray-700"
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <h3 className="mb-5 text-lg font-normal text-gray-300">
                Are you sure you want to logout?
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={handleConfirmLogout}>
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => setShowLogoutModal(false)}>
                  No, cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="container mx-auto px-4 py-8">
          <div className="mb-8">
            <MainHeader title="Profile" />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-4">
              <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <div className="flex flex-col items-center">
                  <div className="relative group">
                    <div className="w-32 h-32 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 p-1">
                      <div className="w-full h-full rounded-full bg-white dark:bg-gray-800 flex items-center justify-center">
                        <UserIcon className="w-16 h-16 text-gray-600 dark:text-gray-300" />
                      </div>
                    </div>
                  </div>

                  <h2 className="mt-4 text-xl font-bold text-gray-800 dark:text-white">
                    {editableUser.first_name} {editableUser.last_name}
                  </h2>
                  <p className="text-gray-500 dark:text-gray-400">
                    {editableUser.username}
                  </p>

                  <div className="mt-6 flex gap-4">
                    <Button
                      onClick={() => setIsEditMode(!isEditMode)}
                      className="rounded-full transition-all duration-200"
                      color={isEditMode ? "gray" : "info"}
                    >
                      {isEditMode ? "Cancel" : "Edit Profile"}
                    </Button>
                    <Button
                      onClick={onLogoutClick}
                      color="failure"
                      className="px-4 py-2 rounded-full transition-all duration-200"
                    >
                      Logout
                    </Button>
                  </div>
                </div>

                <div className="mt-8 space-y-4">
                  {isEditMode ? (
                    <form className="space-y-4">
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Username
                        </label>
                        <TextInput
                          name="username"
                          value={editableUser.username}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Email
                        </label>
                        <TextInput
                          name="email"
                          value={editableUser.email}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Phone
                        </label>
                        <TextInput
                          name="phone"
                          value={editableUser.phone}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                          placeholder="+1 (555) 555-5555"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          First Name
                        </label>
                        <TextInput
                          name="first_name"
                          value={editableUser.first_name}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Last Name
                        </label>
                        <TextInput
                          name="last_name"
                          value={editableUser.last_name}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Touch Interface
                        </span>
                        <ToggleSwitch
                          checked={editableUser.is_touch_interface_enabled}
                          onChange={handleTouchInterfaceChange}
                        />
                      </div>

                      <Button
                        onClick={handleSaveChanges}
                        color="success"
                        className="w-full mt-6"
                      >
                        Save Changes
                      </Button>
                    </form>
                  ) : (
                    <div className="space-y-4">
                      <InfoItem
                        label="Username"
                        value={editableUser.username}
                      />
                      <InfoItem
                        label="Email"
                        value={editableUser.email}
                      />
                      <InfoItem
                        label="Phone"
                        value={editableUser.phone || "Not set"}
                      />
                      <InfoItem
                        label="First Name"
                        value={editableUser.first_name}
                      />
                      <InfoItem
                        label="Last Name"
                        value={editableUser.last_name}
                      />
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Touch Interface
                        </span>
                        <ToggleSwitch
                          checked={editableUser.is_touch_interface_enabled}
                          onChange={handleTouchInterfaceChange}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="lg:col-span-8 space-y-8">
              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  Media Preference Quiz
                </h3>
                <div className="flex items-center justify-between">
                  {renderQuizButton()}
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  Connected Accounts
                </h3>
                <div className="grid gap-4">
                  <ConnectionItem
                    title="Spotify"
                    component={<SpotifyConnection />}
                  />
                  <ConnectionItem
                    title="YouTube"
                    component={<YoutubeConnection />}
                  />
                  <ConnectionItem
                    title="Shazam"
                    component={<ShazamConnection />}
                  />
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  Wallet
                </h3>
                <div className="flex justify-center">
                  <ConnectWalletModal
                    sequenceWalletAddres={sequenceWalletAddress}
                    pendingConnect={pendingConnect}
                  />
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  NFT Management
                </h3>
                <div className="flex flex-col gap-6">
                  <div className="flex justify-center gap-4">
                    <Button
                      onClick={() => setShowNFTProductSelectionModal(true)}
                      disabled={!user?.eth_address}
                      color="info"
                      className="rounded-full px-6"
                    >
                      Buy NFT
                    </Button>
                    <Button
                      onClick={() => setShowRedeemableModal(true)}
                      disabled={!user?.eth_address}
                      color="success"
                      className="rounded-full px-6"
                    >
                      Redeem NFT
                    </Button>
                  </div>
                  {user && user?.eth_address ? (
                    <>
                      <NFTProductSelectionModal
                        show={showNFTProductSelectionModal}
                        setShow={setShowNFTProductSelectionModal}
                        walletAddress={user?.eth_address}
                      />
                      <NFTRedeemableModal
                        show={showRedeemableModal}
                        setShow={setShowRedeemableModal}
                      />
                    </>
                  ) : null}
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  AI Training
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Coming soon...
                </p>
              </section>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}

// Helper Components
const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div className="flex justify-between items-center py-2 border-b border-gray-200 dark:border-gray-700">
    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {label}
    </span>
    <span className="text-sm text-gray-600 dark:text-gray-400">{value}</span>
  </div>
);

const ConnectionItem = ({
  title,
  component,
}: {
  title: string;
  component: React.ReactNode;
}) => (
  <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {title}
    </span>
    {component}
  </div>
);
