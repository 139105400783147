import { QueryParams } from "../types/api";
import { API_BASE_URL, deleteRequest, get, post } from "./apiService";

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

/**
 * Send a GET request to the API to retrieve datasets
 */
export const retrieveDataSets = async (
  pageUrl?: string | null,
  queryParams?: QueryParams,
) => {
  // pageUrl is the url of a specific page of results.
  // If it's not provided, we'll just get the first page of results.
  const url = pageUrl ? pageUrl.replace(API_BASE_URL, "") : "/api/userdatasets/";
  return await get(url, undefined, undefined, queryParams);
};

/**
 * Send a POST request to the API to create a new dataset
 */
export const createDataSet = async (data: FormData) => {
  return await post("/api/userdatasets/", data, false, "multipart/form-data");
};

/**
 * Send a DELETE request to the API to delete a dataset
 */
export const removeDataSet = async (id: string) => {
  return await deleteRequest(`/api/userdatasets/${id}`);
};

/**
 * Send a POST request to the API to assign datasets to the user
 */
export const selectDataSets = async (data: { user_data_sets: string[] }) => {
  return await post("/api/userdatasets/select/", data);
};
