import React from "react";

interface ShimmerProps {
  className?: string;
}

export const ShimmerLoader = ({ className = "" }: ShimmerProps) => (
  <div className={`relative overflow-hidden ${className}`}>
    <div
      className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent 
                    animate-[shimmer_2.5s_ease-in-out_infinite] -translate-x-full"
    />
    <div className="h-full w-full bg-white/5 rounded-xl" />
  </div>
);

export const QuizShimmerLoader = () => (
  <div className="bg-[#1E2028] rounded-xl p-6 w-full">
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
      {/* Title and Description Shimmer */}
      <div className="space-y-3 flex-1">
        <ShimmerLoader className="h-7 w-48" />
        <ShimmerLoader className="h-4 w-72" />
      </div>

      {/* Button Shimmer */}
      <div className="flex flex-col items-end gap-2">
        <div className="relative">
          <div
            className="absolute -inset-1 bg-gradient-to-r from-blue-500/20 to-purple-500/20 
                         rounded-lg opacity-50 blur-lg animate-pulse"
          />
          <ShimmerLoader className="relative h-10 w-32 rounded-lg" />
        </div>
      </div>
    </div>
  </div>
);

export const QuizFormShimmerLoader = () => (
  <div className="space-y-8">
    {/* Question Card Shimmer */}
    <div className="bg-[#1E2028] rounded-xl p-6">
      {/* Question Header */}
      <div className="flex items-start space-x-4 mb-6">
        <div className="flex-1 space-y-4">
          <ShimmerLoader className="h-6 w-32 rounded-full" />
          <ShimmerLoader className="h-8 w-3/4" />
        </div>
        <ShimmerLoader className="h-24 w-24 rounded-lg" />
      </div>

      {/* Answer Options */}
      <div className="space-y-4">
        {[...Array(4)].map((_, i) => (
          <div
            key={i}
            className="relative flex items-center p-4 rounded-lg 
                     bg-gray-800/30 border border-gray-700/50"
          >
            <ShimmerLoader className="h-5 w-5 rounded-full mr-4" />
            <ShimmerLoader className="h-6 flex-1" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export const NFTRedeemableShimmerLoader = () => (
  <div className="space-y-4">
    {[...Array(2)].map((_, i) => (
      <div key={i} className="bg-[#1E2028] rounded-xl p-4 w-full">
        <div className="flex flex-col space-y-4">
          {/* Header Section */}
          <div className="flex items-start gap-4">
            <ShimmerLoader className="w-16 h-16 rounded-lg" />
            <div className="flex-1 space-y-2">
              <ShimmerLoader className="h-6 w-48" />
              <ShimmerLoader className="h-4 w-32" />
            </div>
          </div>

          {/* Info Section */}
          <div className="flex gap-4">
            <ShimmerLoader className="h-12 flex-1 rounded-lg" />
            <ShimmerLoader className="h-12 flex-1 rounded-lg" />
          </div>

          {/* Button */}
          <ShimmerLoader className="h-10 w-full rounded-lg" />
        </div>
      </div>
    ))}
  </div>
);

export const FeedItemDetailsLoader = () => (
  <div className="w-full max-w-3xl mx-auto p-6 space-y-8">
    {/* Soft Image Placeholder */}
    <div className="flex justify-center">
      <div className="relative">
        <div
          className="absolute -inset-4 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10 
                       rounded-2xl opacity-50 blur-xl animate-pulse"
        ></div>
        <div className="relative h-48 w-48 rounded-xl bg-[#252d3d]/50 p-0.5 backdrop-blur-sm">
          <ShimmerLoader className="h-full w-full rounded-xl" />
        </div>
      </div>
    </div>

    {/* Gentle Content Cards */}
    <div className="bg-[#252d3d]/50 rounded-xl border border-white/5 overflow-hidden backdrop-blur-sm">
      {[...Array(7)].map((_, i) => (
        <div
          key={i}
          className="flex items-center justify-between py-4 px-6 border-b border-white/5
                        hover:bg-white/5 transition-all duration-300 ease-in-out"
        >
          <div className="space-y-3 flex-1 mr-8">
            <ShimmerLoader className="h-4 w-24" />
            <div className="flex space-x-3">
              {[...Array(3)].map((_, j) => (
                <div
                  key={j}
                  className="h-1.5 rounded-full bg-gradient-to-r from-white/5 to-white/10 
                               animate-[pulse_2s_ease-in-out_infinite]"
                  style={{ width: `${Math.random() * 60 + 20}px` }}
                />
              ))}
            </div>
          </div>
          <ShimmerLoader className="h-4 w-48" />
        </div>
      ))}
    </div>

    {/* Soft Button Placeholder */}
    <div className="p-6 bg-[#1e2533]/30 rounded-xl backdrop-blur-sm">
      <div className="relative">
        <div
          className="absolute -inset-1 bg-gradient-to-r from-blue-500/20 to-purple-500/20 
                       rounded-xl opacity-50 blur-lg animate-pulse"
        ></div>
        <ShimmerLoader className="relative h-10 w-full rounded-xl" />
      </div>
    </div>
  </div>
);

export const DataTableLoader = () => (
  <div className="space-y-6">
    {/* Soft Header */}
    <div className="flex justify-between items-center">
      <div className="space-y-3">
        <ShimmerLoader className="h-6 w-48" />
        <div className="flex space-x-3">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="h-1 rounded-full bg-gradient-to-r from-white/5 to-white/10 
                           animate-[pulse_2s_ease-in-out_infinite]"
              style={{ width: `${Math.random() * 40 + 20}px` }}
            />
          ))}
        </div>
      </div>
      <ShimmerLoader className="h-6 w-32" />
    </div>

    {/* Gentle Table */}
    <div className="bg-[#1e2533]/30 rounded-xl border border-white/5 overflow-hidden backdrop-blur-sm">
      {/* Table Header */}
      <div className="grid grid-cols-6 gap-4 p-4 border-b border-white/5">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="space-y-3">
            <ShimmerLoader className="h-5" />
            <div
              className="h-0.5 w-1/2 rounded-full bg-white/5 
                           animate-[pulse_2s_ease-in-out_infinite]"
            />
          </div>
        ))}
      </div>

      {/* Table Rows */}
      {[...Array(5)].map((_, i) => (
        <div
          key={i}
          className="grid grid-cols-6 gap-4 p-4 border-b border-white/5
                        hover:bg-white/5 transition-all duration-300 ease-in-out"
        >
          {[...Array(6)].map((_, j) => (
            <ShimmerLoader key={j} className="h-5" />
          ))}
        </div>
      ))}
    </div>

    {/* Soft Pagination */}
    <div className="flex justify-between items-center pt-4">
      <ShimmerLoader className="h-6 w-32" />
      <div className="flex gap-3">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="relative">
            <div
              className="absolute -inset-0.5 bg-gradient-to-r from-white/10 to-white/5 
                           rounded-lg opacity-50 blur-sm animate-pulse"
            />
            <ShimmerLoader className="relative h-8 w-8 rounded-lg" />
          </div>
        ))}
      </div>
    </div>
  </div>
);
