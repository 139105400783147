import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Modal from "../../components/common/modal";
import { Button, Spinner, Card } from "flowbite-react";
import { fetchCheckoutSessions } from "../../redux/payments/paymentsSlice";
import { CheckoutSession } from "../../types/payments";
import NFTRedeemableItem from "./NFTRedeemableItem";
import { NFTRedeemableShimmerLoader } from "../../components/common/ShimmerLoader";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

export default function NFTRedeemableModal({ show, setShow }: Props) {
  const dispatch = useAppDispatch();
  const checkoutSessions = useAppSelector(
    (state) => state.payments.checkoutSessions
  );
  const pendingFetchCheckoutSessions = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutSessions
  );

  function onClose() {
    setShow(false);
  }

  return (
    <Modal
      show={show}
      onClose={() => setShow(false)}
      hideFooter
      body={
        <div className="bg-[#1a1b1e] rounded-xl">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-8 text-center rounded-t-xl">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-3">
              Your Redeemable NFTs
            </h2>
            <p className="text-blue-100 text-base sm:text-lg">
              View and redeem your purchased NFTs
            </p>
          </div>

          {/* Content Section */}
          <div className="p-6 sm:p-8 max-h-[calc(100vh-200px)] overflow-y-auto">
            {pendingFetchCheckoutSessions ? (
              <NFTRedeemableShimmerLoader />
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[60vh] overflow-y-auto p-4">
                {checkoutSessions.length === 0 ? (
                  <div className="col-span-full text-center text-gray-500 py-8">
                    You don't have any NFTs to redeem
                  </div>
                ) : (
                  checkoutSessions.map(
                    (session: CheckoutSession, index: number) => (
                      <div key={index} className="flex">
                        <NFTRedeemableItem data={session} />
                      </div>
                    )
                  )
                )}
              </div>
            )}

            <div className="flex justify-center mt-8 pt-4 border-t">
              <Button color="gray" onClick={onClose} size="lg" className="px-8">
                Close
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
}
