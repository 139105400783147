import React from 'react';

interface AuthLayoutProps {
  children: React.ReactNode;
  showDevBanner?: boolean;
}

export default function AuthLayout({ children, showDevBanner = true }: AuthLayoutProps) {
  return (
    <div className="relative min-h-screen bg-[#02041a] flex-grow flex-col">
      {/* Development Banner */}
      {showDevBanner && (
        <div className="fixed top-0 w-full bg-amber-500 text-black font-bold p-2 text-center text-sm md:text-base lg:text-lg md:p-3 z-50 border-b border-amber-600">
          This is a development server, all data will be lost, this is for
          experimentation and dev only
        </div>
      )}

      {/* Main Container with Common Border */}
      <div className="flex-1 flex items-center justify-center px-4 py-20 md:py-24">
        <div className="w-full max-w-xl mx-auto relative">
          {/* Glass Container with Border */}
          <div className="relative rounded-3xl overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 to-purple-500/10 backdrop-blur-3xl" />

            {/* Content Container */}
            <div className="relative border border-white/10 rounded-3xl bg-gray-900/40 backdrop-blur-xl p-8 md:p-10">
              {children}
            </div>
          </div>

          {/* Decorative Elements */}
          <div className="absolute -z-10 inset-0 blur-3xl">
            <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 via-purple-500/10 to-pink-500/10 opacity-30" />
          </div>
        </div>
      </div>
    </div>
  );
} 