import { CheckoutSession } from "../../types/payments";
import React, { useState } from "react";
import { Button, Spinner } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCheckoutSessions,
  redeemNFT,
} from "../../redux/payments/paymentsSlice";
import { isDispatchResponseError } from "../../redux/utils";
import NFTDetailsModal from "./NFTDetailsModal";
import {
  SparklesIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

interface Props {
  data: CheckoutSession;
}

/**
 * A component that displays an NFT product(CheckoutSession) and allows it to be clicked.
 */
export default function NFTRedeemableItem({ data }: Props) {
  console.log("NFTRedeemableItem@data", data);
  const dispatch = useAppDispatch();
  const [isClicked, setIsClicked] = React.useState(false);
  const pendingFetchCheckoutSessions = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutSessions
  );
  const pendingRedeemNFT = useAppSelector(
    (state) => state.payments.pendingRedeemNFT
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  function getImageSrc() {
    let image;
    console.log("getImageSrc@data.nft_product.images", data.nft_product.images);
    if (data.nft_product.images) {
      image = data.nft_product.images;
    }

    if (!image) {
      return require("../../images/No-Image.png");
    }
    return image;
  }

  async function handleRedeemNFT() {
    console.log("handleRedeemNFT data", data);
    setIsClicked(true);
    const response = await dispatch(redeemNFT(data.id));
    console.log("handleRedeemNFT response", response);
    if (!isDispatchResponseError(response)) {
      console.log("fetching checkout sessions");
      dispatch(fetchCheckoutSessions());
    }
    setIsClicked(false);
  }

  function renderBrainInfo() {
    if (!data.is_processed) {
      return (
        <>
          If you redeem this NFT, you will also receive{" "}
          <span className="font-medium">
            {data.nft_product.brain.toLocaleString()} BRAIN
          </span>
        </>
      );
    } else if (data.is_refunded) {
      return (
        <span className="font-medium">
          This purchase has been refunded due to an error on our end
        </span>
      );
    } else {
      return (
        <span className="font-medium">
          You have redeemed this NFT and received{" "}
          {data.nft_product.brain.toLocaleString()} BRAIN
        </span>
      );
    }
  }

  function generateOpenSeaLink() {
    console.log(
      "generateOpenSeaLink@data",
      data.minted_nft_id,
      data.wallet_address
    );
    try {
      if (!data.minted_nft_id || !data.wallet_address) {
        return (
          <div className="text-gray-500 text-sm">
            {!data.minted_nft_id
              ? "OpenSea link will be available after minting"
              : "Contract address not available"}
          </div>
        );
      }

      const baseUrl = process.env.REACT_APP_OPENSEA_NFT_ADDRESS;
      console.log("generateOpenSeaLink@baseUrl", baseUrl);
      if (!baseUrl) {
        throw new Error("OpenSea base URL not configured");
      }

      const openSeaUrl = `${baseUrl}${data.wallet_address}/${data.minted_nft_id}`;
      console.log("generateOpenSeaLink@openSeaUrl", openSeaUrl);
      return (
        <div className="mt-2">
          <a
            href={openSeaUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800 font-medium transition-colors"
            onClick={(e) => {
              if (!data.minted_nft_id || !data.wallet_address) {
                e.preventDefault();
                return;
              }
            }}
          >
            <img
              src="/path-to-opensea-logo.svg"
              alt="OpenSea"
              className="w-4 h-4"
            />
            <span>View on OpenSea</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        </div>
      );
    } catch (error) {
      console.error("Error generating OpenSea link:", error);
      return (
        <div className="text-red-500 text-sm">
          Error generating OpenSea link
        </div>
      );
    }
  }

  return (
    <div className="bg-[#1E2028] rounded-xl overflow-hidden p-4 w-full">
      <div className="flex flex-col space-y-4">
        {/* Header Section with Image and Title */}
        <div className="flex items-start gap-4">
          {/* Image */}
          <div className="relative w-16 h-16">
            <img
              src={getImageSrc()}
              alt={data.nft_product.name}
              className="w-full h-full object-cover rounded-lg"
            />
            {/* Redeemed Badge */}
            <div
              className="absolute -top-2 -right-2 bg-green-500 text-white 
                         px-2 py-0.5 rounded-full text-xs font-medium"
            >
              Redeemed
            </div>
          </div>

          {/* Title Section */}
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-white">
              {data.nft_product.name}
            </h3>
            <p className="text-gray-400 text-sm">(created by Stripe CLI)</p>
          </div>
        </div>

        {/* BRAIN and NFT ID Info */}
        <div className="flex gap-4">
          {/* BRAIN Reward */}
          <div className="bg-[#1A1B1F] rounded-lg p-3 flex items-center gap-2 flex-1">
            <SparklesIcon className="w-5 h-5 text-blue-400" />
            <div className="text-sm">
              <span className="text-gray-400">Received </span>
              <span className="text-white font-medium">0 BRAIN</span>
            </div>
          </div>

          {/* NFT ID */}
          <div className="bg-[#1A1B1F] rounded-lg p-3 flex items-center justify-between flex-1">
            <div className="text-sm text-gray-400">
              NFT ID: <span className="text-white">#{data.minted_nft_id}</span>
            </div>
            {data.minted_nft_id && (
              <a
                href={`https://opensea.io/assets/${data.minted_nft_id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300 text-sm flex items-center gap-1"
              >
                View on OpenSea
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </a>
            )}
          </div>
        </div>

        {/* Status and Button */}
        <div className="space-y-3">
          {/* Status Message */}
          <div className="flex items-center gap-2 text-green-400 text-sm">
            <CheckCircleIcon className="w-4 h-4" />
            NFT successfully redeemed
          </div>

          {/* Action Button */}
          <button
            onClick={handleRedeemNFT}
            disabled={data.is_processed || pendingRedeemNFT}
            className={`
              w-full py-2.5 px-4 rounded-lg font-medium text-white text-sm
              transition-colors duration-200
              ${
                data.is_processed
                  ? "bg-gray-600 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              }
            `}
          >
            {pendingRedeemNFT ? (
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                <span>Processing...</span>
              </div>
            ) : data.is_processed ? (
              "Already Redeemed"
            ) : (
              "Redeem NFT"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
