import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  clearFeedItems,
  dislikeFeedItem,
  getFeedItem,
  getRandomFeedItems,
  likeFeedItem,
  skipFeedItem,
} from "redux/feed/feedItemsSlice";
import { FeedItem } from "types/feed";
import { isDispatchResponseError } from "redux/utils";
import { AddItemToPlaylistPayload } from "types/playlist";
import { addItemToPlaylists, getPlaylist } from "redux/playlist/playlistSlice";
import { QueryParams } from "types/api";

interface FeedItemManagerProps {
  feedItemId?: string;
  playlistId?: string;
  onFeedItemsChange: (items: FeedItem[]) => void;
  onLoadingChange: (loading: boolean) => void;
  onFirstFetchComplete: () => void;
  currFeedItemIdx: number;
}

export function useFeedItemManager({
  feedItemId,
  playlistId,
  onFeedItemsChange,
  onLoadingChange,
  onFirstFetchComplete,
  currFeedItemIdx,
}: FeedItemManagerProps) {
  const dispatch = useAppDispatch();
  const [feedItems, setFeedItems] = useState<FeedItem[]>([]);

  const feedFilterTypes = useAppSelector(
    (state) => state.feedFilterTypes.feedFilterTypes
  );
  const selectedFeedDataSet = useAppSelector(
    (state) => state.dataSets.selectedFeedDataSet
  );
  const defaultPlaylist = useAppSelector((state) =>
    state.playlist.myPlaylists.find((playlist) => playlist.is_default)
  );

  const retrieveFeedItems = async () => {
    if (feedItemId) {
      return dispatch(getFeedItem({ id: feedItemId }));
    } else {
      const queryParams: QueryParams = {};
      if (feedFilterTypes) {
        queryParams["types"] = feedFilterTypes.join(",");
      }
      if (selectedFeedDataSet) {
        queryParams["dataset"] = selectedFeedDataSet.id;
      }
      return dispatch(
        getRandomFeedItems({
          queryParams,
        })
      );
    }
  };

  const fetchFeedItems = async () => {
    onLoadingChange(true);
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      const payload = response.payload;
      const newFeedItems = (Array.isArray(payload) ? payload : [payload]) as FeedItem[];
      setFeedItems(newFeedItems);
      onFeedItemsChange(newFeedItems);
    }
    onLoadingChange(false);
    onFirstFetchComplete();
  };

  const fetchAdditionalFeedItems = async () => {
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      const newItems = [...feedItems, ...(response.payload as FeedItem[])];
      setFeedItems(newItems);
      onFeedItemsChange(newItems);
    }
  };

  const fetchPlaylistFeedItems = async () => {
    if (playlistId) {
      onLoadingChange(true);
      const response = await dispatch(getPlaylist({ id: playlistId }));
      if (!isDispatchResponseError(response) && response.payload) {
        const newFeedItems = response.payload.items.map(
          (item: any) => item.feed_item as FeedItem
        );
        setFeedItems(newFeedItems);
        onFeedItemsChange(newFeedItems);
      }
      onLoadingChange(false);
      onFirstFetchComplete();
    }
  };

  const handleLikeFeedItem = async () => {
    if (feedItems[currFeedItemIdx]) {
      const response = await dispatch(
        likeFeedItem(feedItems[currFeedItemIdx].id || "")
      );
      return !isDispatchResponseError(response);
    }
    return false;
  };

  const handleDislikeFeedItem = async () => {
    if (feedItems[currFeedItemIdx]) {
      const response = await dispatch(
        dislikeFeedItem(feedItems[currFeedItemIdx].id || "")
      );
      return !isDispatchResponseError(response);
    }
    return false;
  };

  const handleAddToDefaultPlaylist = async () => {
    if (defaultPlaylist && feedItems[currFeedItemIdx]) {
      const data: AddItemToPlaylistPayload = {
        playlist_ids: [defaultPlaylist.id],
        feed_item_id: feedItems[currFeedItemIdx].id,
      };
      const response = await dispatch(addItemToPlaylists(data));
      return !isDispatchResponseError(response);
    }
    return false;
  };

  const handleSkipFeedItem = async () => {
    if (feedItems[currFeedItemIdx]) {
      const response = await dispatch(
        skipFeedItem(feedItems[currFeedItemIdx].id)
      );
      return !isDispatchResponseError(response);
    }
    return false;
  };

  useEffect(() => {
    dispatch(clearFeedItems());
    if (playlistId) {
      fetchPlaylistFeedItems();
    } else {
      fetchFeedItems();
    }
  }, [feedFilterTypes, selectedFeedDataSet]);

  return {
    feedItems,
    handleLikeFeedItem,
    handleDislikeFeedItem,
    handleAddToDefaultPlaylist,
    handleSkipFeedItem,
    fetchAdditionalFeedItems,
  };
} 