import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FeedItem } from "../types/feed";
import { getPlaylist } from "../redux/playlist/playlistSlice";
import { isDispatchResponseError } from "../redux/utils";
import { PlaylistItem } from "../types/playlist";

interface PlaylistIntegrationProps {
  playlistId?: string;
  onFeedItemsChange: (items: FeedItem[]) => void;
  onLoadingChange: (loading: boolean) => void;
  onFirstFetchComplete: () => void;
}

export function usePlaylistIntegration({
  playlistId,
  onFeedItemsChange,
  onLoadingChange,
  onFirstFetchComplete,
}: PlaylistIntegrationProps) {
  const dispatch = useAppDispatch();
  const [showPlaylistsDrawer, setShowPlaylistsDrawer] = useState(false);

  // Get the default playlist from the store
  const defaultPlaylist = useAppSelector((state) =>
    state.playlist.myPlaylists.find((playlist) => playlist.is_default)
  );

  /**
   * Fetch feed items from a playlist.
   */
  const fetchPlaylistFeedItems = async () => {
    if (playlistId) {
      onLoadingChange(true);
      const response = await dispatch(getPlaylist({ id: playlistId }));
      if (!isDispatchResponseError(response) && response.payload) {
        const items = response.payload.items as PlaylistItem[];
        const newFeedItems = items.map((item) => item.feed_item);
        onFeedItemsChange(newFeedItems);
      }
      onLoadingChange(false);
      onFirstFetchComplete();
    }
  };

  /**
   * Toggle the playlists drawer visibility
   */
  const togglePlaylistsDrawer = (show: boolean) => {
    setShowPlaylistsDrawer(show);
  };

  return {
    showPlaylistsDrawer,
    setShowPlaylistsDrawer: togglePlaylistsDrawer,
    fetchPlaylistFeedItems,
    defaultPlaylist,
  };
} 