import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import FeedbackDetailsComponent from "components/feedback/FeedbackDetails";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { retrieveFeedback } from "redux/feedback/feedbackSlice";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Feedback details page.
 */
export default function FeedbackDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const feedback = useAppSelector((state) => state.feedback.feedback);
  const pendingGetFeedback = useAppSelector(
    (state) => state.feedback.pendingGetFeedback
  );

  /**
   * Retrieve feedback.
   */
  useEffect(() => {
    if (id) {
      dispatch(retrieveFeedback({ id }));
    }
  }, [id]);

  useEffect(() => {
    document.title = "Feedback Details | BrainCargo®";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col dark:bg-gradient-to-b dark:from-[#1a1f2b] dark:to-[#151923]">
        {/* Header Section */}
        <div className="sticky top-0 z-50 dark:bg-[#1e2533]/80 border-b dark:border-white/5">
          <div className="max-w-[1400px] mx-auto">
            <MainHeader title="Feedback Details" />
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 px-4 py-6 md:px-6 lg:px-8 pt-16">
          <div className="max-w-[1400px] mx-auto">
            {/* Feedback Details Card */}
            <div
              className="bg-white dark:bg-[#1e2533]/80 rounded-xl border dark:border-white/5 
                          shadow-lg dark:shadow-black/20 backdrop-blur-sm 
                          transition-all duration-300"
            >
              {/* Card Header */}
              <div className="p-4 md:p-6 border-b dark:border-white/5">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Feedback Information
                </h2>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Detailed view of the selected feedback
                </p>
              </div>

              {/* Feedback Details Component */}
              <div className="p-4 md:p-6">
                <FeedbackDetailsComponent
                  feedback={feedback}
                  pending={pendingGetFeedback}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <Footer />
      </div>
    </PageWrapper>
  );
}
