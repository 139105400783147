import React, { useEffect } from "react";
import { getSharedPlaylists } from "../../redux/playlist/playlistSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ExpandablePlaylist from "../../components/playlist/ExpandablePlaylist";
import { UsersIcon } from "@heroicons/react/24/outline";

interface Props {
  smallHeader?: boolean;
}

/**
 * Component for displaying a list of playlists that have been shared with the user.
 */
export default function SharedPlaylistList({ smallHeader = false }: Props) {
  const dispatch = useAppDispatch();
  const sharedPlaylists = useAppSelector(
    (state) => state.playlist.sharedPlaylists
  );

  /**
   * Retrieve a list of playlists that have been shared with the user on mount.
   */
  useEffect(() => {
    dispatch(getSharedPlaylists({}));
  }, []);

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-white">
            Shared with Me
          </h3>
          <span
            className="px-2 py-0.5 text-xs font-medium text-gray-500 dark:text-gray-400 
                        bg-gray-100 dark:bg-white/5 rounded-full"
          >
            {sharedPlaylists.length} playlists
          </span>
        </div>
      </div>

      {/* Shared Playlists */}
      <div className="space-y-2">
        {sharedPlaylists.length > 0 ? (
          sharedPlaylists.map((playlist, idx) => (
            <ExpandablePlaylist key={idx} playlist={playlist} />
          ))
        ) : (
          <div className="text-center py-8">
            <div
              className="inline-flex items-center justify-center w-12 h-12 
                         bg-gray-100 dark:bg-white/5 rounded-full mb-3"
            >
              <UsersIcon className="w-6 h-6 text-gray-400 dark:text-gray-600" />
            </div>
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              No shared playlists
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
              Playlists shared with you will appear here
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
