import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import FeedDataUserDetailsComponent from "components/admin/feedData/details/FeedDataUserDetails";
import { useParams } from "react-router-dom";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * User feed data details page.
 */
export default function FeedDataUserDetails() {
  const { userId } = useParams();

  useEffect(() => {
    document.title = "Feed Data | BrainCargo®";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col bg-[#1a1f2b]">
        <div className="flex-1">
          {/* Header */}
          <div className="bg-[#1e2533] border-b border-gray-700/50">
            <MainHeader title="User Details" />
          </div>

          {/* Main Content */}
          <div className="p-6">
            <div className="max-w-[1400px] mx-auto">
              <FeedDataUserDetailsComponent userId={userId || ""} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </PageWrapper>
  );
}
