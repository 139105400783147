import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { useParams } from "react-router-dom";
import FeedDataVideoDetailsComponent from "components/admin/feedData/details/FeedDataVideoDetails";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Feed data video details page.
 */
export default function FeedDataVideoDetails() {
  const { videoId } = useParams();

  useEffect(() => {
    document.title = "Feed Data | BrainCargo®";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col bg-[#1a1f2b]">
        {/* Header */}

        <div className="bg-[#1e2533] border-b border-gray-700/50">
          <MainHeader title="Video Details" />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="max-w-[1400px] mx-auto">
            <div className="bg-[#1e2533] rounded-lg border border-gray-700/50">
              <FeedDataVideoDetailsComponent videoId={videoId || ""} />
            </div>
          </div>
           </div>


    <Footer />
      </div>
    </PageWrapper>
  );
}
