import Modal from "../../components/common/modal";
import { Spinner } from "flowbite-react";
import { displayErrors } from "../../helpers/errors";
import React, { useState } from "react";
import { submitFeedItemReport } from "../../redux/feed/feedItemReportsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isDispatchResponseError } from "../../redux/utils";
import { FeedItem } from "../../types/feed";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
  feedItem: FeedItem;
}

const REPORT_OPTIONS = [
  {
    id: "spam",
    label: "It's spam",
    icon: "🚫",
  },
  {
    id: "nudity",
    label: "Nudity or sexual activity",
    icon: "⚠️",
  },
  {
    id: "hate",
    label: "Hate speech or symbols",
    icon: "🚫",
  },
  {
    id: "violence",
    label: "Violence or dangerous organizations",
    icon: "⚠️",
  },
  {
    id: "false",
    label: "False information",
    icon: "❌",
  },
  {
    id: "scam",
    label: "Scam or fraud",
    icon: "🚨",
  },
  {
    id: "suicide",
    label: "Suicide or self-injury",
    icon: "⚕️",
  },
  {
    id: "illegal",
    label: "Unlawful/illegal content",
    icon: "⛔",
  },
];

/**
 * Modal used for reporting feed items.
 */
export function ReportFeedItemModal({ show, setShow, feedItem }: Props) {
  // Stage 0: select report option
  // Stage 1: display an input field if user clicked "Something else"
  // stage 2: confirmation
  const [stage, setStage] = useState(0);
  const [descriptionInput, setDescriptionInput] = useState("");
  const dispatch = useAppDispatch();
  const errorsSubmitFeedItemReport = useAppSelector(
    (state) => state.feedItemReports.errorsSubmitFeedItemReport
  );
  const pendingSubmitFeedItemReport = useAppSelector(
    (state) => state.feedItemReports.pendingSubmitFeedItemReport
  );

  /**
   * Reset modal.
   */
  const onClose = () => {
    setStage(0);
    setDescriptionInput("");
    setShow(false);
  };

  /**
   * Handle submitting the form.
   */
  const handleSubmit = async (description: string) => {
    const response = await dispatch(
      submitFeedItemReport({ description, feed_item: feedItem.id })
    );
    if (!isDispatchResponseError(response)) {
      setStage(2);
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideFooter={stage !== 1}
      onSubmit={() => handleSubmit(descriptionInput)}
      headerText="Report Content"
      body={
        <div className="min-w-[350px] sm:min-w-[400px] md:min-w-[450px]">
          {pendingSubmitFeedItemReport ? (
            <div className="flex flex-col items-center justify-center py-8 space-y-4">
              <Spinner size="xl" className="text-blue-600" />
              <p className="text-gray-500 dark:text-gray-400">
                Submitting your report...
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              {stage === 0 && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Why are you reporting this item?
                  </h3>
                  <div className="grid gap-3">
                    {REPORT_OPTIONS.map((option) => (
                      <button
                        key={option.id}
                        onClick={() => handleSubmit(option.label)}
                        className="flex items-center w-full p-3 text-left 
                                 bg-gray-50 dark:bg-gray-800 rounded-lg
                                 hover:bg-gray-100 dark:hover:bg-gray-700
                                 transition-colors duration-200"
                      >
                        <span className="text-xl mr-3">{option.icon}</span>
                        <span className="text-gray-700 dark:text-gray-300">
                          {option.label}
                        </span>
                      </button>
                    ))}
                    <button
                      onClick={() => setStage(1)}
                      className="flex items-center w-full p-3 text-left
                               bg-gray-50 dark:bg-gray-800 rounded-lg
                               hover:bg-gray-100 dark:hover:bg-gray-700
                               transition-colors duration-200"
                    >
                      <span className="text-xl mr-3">💭</span>
                      <span className="text-gray-700 dark:text-gray-300">
                        Something else
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {stage === 1 && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Please describe the issue
                  </h3>
                  <textarea
                    rows={4}
                    className="w-full px-3 py-2 text-gray-700 dark:text-gray-300
                             bg-gray-50 dark:bg-gray-800 rounded-lg
                             border border-gray-300 dark:border-gray-600
                             focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Tell us more about your concern..."
                    value={descriptionInput}
                    onChange={(e) => setDescriptionInput(e.target.value)}
                  />
                </div>
              )}

              {stage === 2 && (
                <div className="text-center py-6 space-y-4">
                  <div className="text-5xl mb-4">✅</div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Thank you for your report
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Our team will review your report and take appropriate
                    action.
                  </p>
                </div>
              )}

              {Object.keys(errorsSubmitFeedItemReport).length > 0 && (
                <div
                  className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 
                               rounded-lg border border-red-200 dark:border-red-800"
                >
                  {Object.keys(errorsSubmitFeedItemReport).map((key) => (
                    <div key={key} className="text-red-600 dark:text-red-400">
                      {displayErrors(errorsSubmitFeedItemReport[key])}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      }
    />
  );
}
