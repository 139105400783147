import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import NeuralNetworksTable from "../../components/neuralNetworks/NeuralNetworksTable";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";

/**
 * Neural Networks page
 */
export default function NeuralNetworks() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Neural Networks | BrainCargo®";
    // Simulate loading for demo - replace with actual data loading
    setTimeout(() => setIsLoading(false), 1500);
  }, []);

  // Shimmer loader component
  const ShimmerLoader = () => (
    <div className="animate-pulse space-y-4">
      {/* Header Shimmer */}
      <div className="h-12 bg-gray-200 dark:bg-gray-700 rounded-lg w-1/4"></div>

      {/* Description Shimmer */}
      <div className="space-y-2">
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4"></div>
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-2/3"></div>
      </div>

      {/* Table Header Shimmer */}
      <div className="mt-8 border dark:border-gray-700 rounded-lg overflow-hidden">
        <div className="bg-gray-50 dark:bg-gray-800 p-4 flex items-center justify-between">
          <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
          <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-32"></div>
        </div>

        {/* Table Rows Shimmer */}
        {[...Array(5)].map((_, idx) => (
          <div
            key={idx}
            className="border-t dark:border-gray-700 p-4 flex items-center space-x-4"
          >
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-8"></div>
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/3"></div>
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-16"></div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-all duration-200">
        <MainHeader title="Neural Networks" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-14">
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
            {/* Header Section */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900 p-6 sm:p-8">
              <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2">
                Neural Networks
              </h1>
              <p className="text-blue-100 text-sm sm:text-base max-w-3xl">
                Explore and manage neural networks in the ® database.
                Upload new networks or select existing ones for use across the
                application.
              </p>
            </div>

            {/* Main Content */}
            <div className="p-6">
              {isLoading ? (
                <ShimmerLoader />
              ) : (
                <div className="space-y-6">
                  <div className="bg-blue-50 dark:bg-blue-900/30 border border-blue-200 dark:border-blue-800 rounded-xl p-4">
                    <p className="text-blue-700 dark:text-blue-300 text-sm sm:text-base leading-relaxed">
                      Below you will find a list of all neural networks uploaded
                      to the BrainCargo® database. You can upload new neural
                      networks and select existing ones in order to use them in
                      other parts of the application.
                    </p>
                  </div>

                  <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <NeuralNetworksTable headerText="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
