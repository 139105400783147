import React from "react";
import { FeedItem as FeedItemType } from "types/feed";
import ScrollableMobileFeedElement from "../ScrollableMobileFeedElement";
import { generateFeedItemHtmlId, getComponentHeight, TRANSITION_STYLE_PROPERTY } from "../utils";

interface FeedItemProps {
  feedItem: FeedItemType;
  index: number;
  isPlaying: boolean;
  spotifyIFrameAPI: any;
}

/**
 * Component to render an individual feed item
 */
export function FeedItem({
  feedItem,
  index,
  isPlaying,
  spotifyIFrameAPI,
}: FeedItemProps) {
  const renderedItemHeight = getComponentHeight();
  const id = generateFeedItemHtmlId(feedItem, index);

  return (
    <ScrollableMobileFeedElement
      key={id}
      id={id}
      className="absolute w-full aspect-video rounded bg-gray-100"
      style={{
        ...TRANSITION_STYLE_PROPERTY,
        height: renderedItemHeight + "px",
        top: renderedItemHeight * index,
      }}
      data={{
        playing: isPlaying,
        feedItem,
      }}
      spotifyIFrameAPI={spotifyIFrameAPI}
    />
  );
} 