import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById("root")!;
const root = createRoot(container);

const persistor = persistStore(store);
// const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ""  ;
const googleClientId = "858594379362-5r3bg3steked2q09n6kqs3o1e2s63eh7.apps.googleusercontent.com"  ;
// console.log("googleClientId_test", googleClientId);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
    {}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    {}
    </GoogleOAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
