import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import { setTokens } from "../../services/localStorage";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../navigation";

interface TraditionalLoginProps {
  email?: string;
}

const TraditionalLogin: React.FC<TraditionalLoginProps> = ({ email = "" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [emailValue, setEmailValue] = useState(email);

  const loginErrorMessages = useAppSelector(
    (state) => state.auth.loginErrorMessages
  );

  const handleLogin = async () => {
    try {
      const response = await toast.promise(
        dispatch(login({ email: emailValue, password })).unwrap(),
        {
          pending: "Logging in...",
          success: "Logged in successfully!",
          error: {
            render({ data }: any) {
              return data?.detail || "Login failed";
            },
          },
        }
      );
      if (response) {
        const { access, refresh } = response;
        setTokens({ access, refresh });
        navigate(APP_URLS.VIDEOS);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form onSubmit={handleSubmit} aria-label="Traditional Login Form">
      <div className="mt-6">
        <TextInput
          value={emailValue}
          label="Email"
          id="email"
          type="email"
          required
          onChange={(event) => setEmailValue(event.target.value)}
          placeholder="Email"
          aria-label="Email"
          errorMessages={loginErrorMessages?.email}
        />

        <TextInput
          value={password}
          label="Password"
          id="password"
          type="password"
          required
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Password"
          aria-label="Password"
          errorMessages={loginErrorMessages?.password}
        />

        <p className="text-sm text-gray-400 mt-4 mb-6 text-left">
          By logging in to your account you agree to be bound by the <button onClick={() => navigate(APP_URLS.TERMS_OF_SERVICE)} className="text-blue-400 hover:text-blue-300 hover:underline transition-colors">Terms of Service</button> and <button onClick={() => navigate(APP_URLS.PRIVACY_POLICY)} className="text-blue-400 hover:text-blue-300 hover:underline transition-colors">Privacy Policy</button>
        </p>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
            aria-label="Login"
          >
            Login
          </button>
        </div>
      </div>
    </form>
  );
};

export default TraditionalLogin; 