import React, { useState } from "react";
import { Search } from "../Search/Search";
import "./MainHeader.css";
import LogoutModal from "./LogoutModal";
import NotificationButton from "./NotificationButton";
import ProfileDropdown from "./ProfileDropdown";
import BrainCoinImage from '../../images/Landing/BrainCoin.png';

interface MainHeaderProps {
  title?: string;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  title = "Dashboard",
}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const notificationCount = 9;

  return (
    <div>
      <LogoutModal 
        showModal={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
      />

      <header className="app-header">
        <div className="header-content">
          <div className="header-left flex items-center gap-6">
            <div className="flex items-center gap-2 mr-6">
              <img
                src={BrainCoinImage}
                alt="BrainCargo Logo"
                className="h-8 w-auto"
              />
              <span className="logo-text">
                BrainCargo®
              </span>
            </div>
            <Search />
          </div>

          <div className="header-right">
            <NotificationButton notificationCount={notificationCount} />
            <ProfileDropdown onLogoutClick={() => setShowLogoutModal(true)} />
          </div>
        </div>
      </header>
    </div>
  );
};

export default MainHeader;
