import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../navigation";

export default function SMSOptOut() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#02041a] py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-white mb-4">
            BrainCargo® Text Message (SMS) Opt-In Policy
          </h1>
          <p className="text-gray-400">Last Updated: March 14, 2024</p>
        </div>

        <div className="bg-gray-900/50 rounded-xl p-6 space-y-8">
          <section>
            <h2 className="text-xl font-semibold text-white mb-4">1. Introduction</h2>
            <p className="text-gray-300 leading-relaxed">
              Welcome to BrainCargo®'s Text Message (SMS) program. By opting in to receive text messages 
              from BrainCargo® (the "Service"), you agree to these terms and conditions ("SMS Terms"), 
              as well as our{" "}
              <button
                onClick={() => navigate(APP_URLS.PRIVACY_POLICY)}
                className="text-blue-400 hover:text-blue-300 underline"
              >
                Privacy Policy
              </button>{" "}
              and{" "}
              <button
                onClick={() => navigate(APP_URLS.TERMS_OF_SERVICE)}
                className="text-blue-400 hover:text-blue-300 underline"
              >
                Terms of Service
              </button>.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">2. How to Opt In</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                When you provide your mobile phone number to BrainCargo® (e.g., on a form, during checkout, 
                or through a landing page), you may be asked to consent to receive marketing, transactional, 
                or informational text messages.
              </p>
              <p>
                Consent is not required to purchase any products or services from BrainCargo®. Your consent 
                is voluntary.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">3. Message Frequency</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                By opting in, you agree to receive periodic text messages (e.g., shipping updates, delivery 
                notifications, promotional offers, account notifications).
              </p>
              <p>
                The number of messages per month may vary depending on your interactions, preferences, or 
                available promotions, but generally will not exceed 10 messages per month.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">4. Cost and Fees</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                While BrainCargo® does not charge a fee to send text messages, message and data rates may 
                apply from your mobile carrier.
              </p>
              <p>
                BrainCargo® is not responsible for any additional charges your mobile carrier may charge 
                you for text/SMS messages.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">5. Supported Carriers</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                Our messages may be sent using major U.S. or international wireless carriers. However, we 
                make no guarantee that all mobile carriers will be compatible with our Service.
              </p>
              <p>
                BrainCargo® will not be liable for delayed or undelivered messages.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">6. How to Opt Out</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                You can opt out of receiving further text messages at any time. Simply reply STOP to any 
                message you receive from us.
              </p>
              <p>
                After texting STOP, you will receive one final confirmation message stating that you have 
                successfully unsubscribed. You will not receive any additional messages unless you re-opt in.
              </p>
              <p>
                If you have any questions or need assistance, please contact Customer Service or follow the 
                instructions provided in the confirmation message.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">7. How to Get Help</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                If you need assistance, you can:
              </p>
              <ul className="list-disc pl-6 space-y-1">
                <li>Reply HELP to any BrainCargo® text</li>
                <li>Email us at support@braincargo.com</li>
              </ul>
              <p>Additional information can be found on our Help Center.</p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">8. Privacy and Data Protection</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                By opting in to the Service, you acknowledge that BrainCargo® will collect and use your 
                mobile phone number and certain limited metadata (e.g., time stamps, message status, etc.) 
                to provide the Service.
              </p>
              <p>
                We handle personal data in accordance with our{" "}
                <button
                  onClick={() => navigate(APP_URLS.PRIVACY_POLICY)}
                  className="text-blue-400 hover:text-blue-300 underline font-medium transition-colors"
                >
                  Privacy Policy
                </button>
                .
              </p>
              <p>
                We do not sell or share your mobile phone number with unauthorized third parties. We may 
                share your data as legally required or to fulfill our obligations to you (e.g., sending 
                shipment notifications through a third-party SMS provider).
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">9. Changes to SMS Terms</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>
                BrainCargo® reserves the right to modify or cancel the Service and these SMS Terms at any time.
              </p>
              <p>
                We will provide notice of major changes by posting an updated version of these SMS Terms on 
                our website. Your continued use of the Service indicates your acceptance of any changes.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-4">10. Contact Us</h2>
            <div className="text-gray-300 leading-relaxed space-y-2">
              <p>If you have any questions or concerns regarding these SMS Terms, please contact us at:</p>
              <div className="pl-4 border-l-2 border-gray-700 mt-2">
                <p>Email: <a href="mailto:support@BrainCargo.com" className="text-blue-400 hover:text-blue-300 underline">support@braincargo.com</a></p>
                <p>Address: BrainCargo®, 1309 Coffeen Avenue STE 1200 Sheridan Wyoming 82801</p>
              </div>
            </div>
          </section>
        </div>

        <div className="mt-8 text-center">
          <button
            onClick={() => navigate(-1)}
            className="text-blue-400 hover:text-blue-300 underline"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
} 