import React from 'react';
import { Bell } from "../icons/Bell";

interface NotificationButtonProps {
  notificationCount: number;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({ notificationCount }) => {
  return (
    <div className="notification-wrapper">
      <button className="notification-button">
        <Bell />
        {notificationCount > 0 && (
          <span className="notification-badge">
            {notificationCount}+
          </span>
        )}
      </button>
    </div>
  );
};

export default NotificationButton; 