import React, { useEffect, useState } from "react";
import { useAuthModal, useAccount, useLogout } from "@account-kit/react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setSequenceWalletAddress,
  disconnectWallet,
} from "../../redux/wallet/walletSlice";
import { getWalletAddress } from "../../services/localStorage";
import { get } from "../../services/apiService";
import BrainCoinImage from '../../images/Landing/BrainCoin.png';

export const ConnectWalletModal = ({
  sequenceWalletAddres,
  pendingConnect,
}: {
  sequenceWalletAddres: string | null | undefined;
  pendingConnect: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { seqAddress: storedAddress, isConnected } = useAppSelector(
    (state) => state.wallet
  );
  const { openAuthModal } = useAuthModal();
  const [loading, setLoading] = useState(true);
  const [brainCoinBalance, setBrainCoinBalance] = useState<string>("");

  const user = useAppSelector((state) => state.user.user);

  setTimeout(() => {
    setLoading(false);
  }, 6000);
  const { address } = useAccount({
    type: "LightAccount",
    onSuccess: async (account) => {
      console.log(account);
      if (address) {
        await dispatch(setSequenceWalletAddress(address));
      }
      toast.success("Wallet connected successfully!");
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { logout } = useLogout({
    onSuccess: () => {
      dispatch(disconnectWallet());
    },
    onError: (error) => console.error(error),
  });

  const getBrainCoinBalance = async () => {
    try {
      const response = await get(
        "/api/payments/integrate-external-wallet/get_braincoin_balance/",
        false,
        "application/json"
      );
      // Make sure we're accessing the correct property from the response
      const balance = response?.data?.balance || response?.data;

      if (balance !== undefined) {
        setBrainCoinBalance(balance.toString());
      } else {
        setBrainCoinBalance("undefined");
      }
    } catch (error) {
      console.error("Error fetching BrainCoin balance:", error);
      setBrainCoinBalance("undefined");
    }
  };

  // Add an effect to fetch balance when wallet is linked
  useEffect(() => {
    if (user && user?.eth_address) {
      getBrainCoinBalance();
    }
  }, [user]);

  useEffect(() => {
    const walletAddress = getWalletAddress();
    if (walletAddress) {
      dispatch(setSequenceWalletAddress(walletAddress));
    }
  }, []);

  // Update stored address when wallet address changes
  useEffect(() => {
    if (address && address !== storedAddress) {
      dispatch(setSequenceWalletAddress(address));
    }
    if (user && user?.eth_address) {
      dispatch(setSequenceWalletAddress(user?.eth_address));
    }
  }, [dispatch, user]);

  const handleConnect = async () => {
    try {
      await openAuthModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDisconnect = async () => {
    try {
      await dispatch(disconnectWallet());
      logout();
      toast.success("Wallet disconnected successfully!");
    } catch (error) {
      toast.error("Failed to disconnect wallet");
    }
  };

  const isWalletConnected = user && user?.eth_address;
  if (user && user?.eth_address) {
    return (
      <div
        className="bg-white/95 dark:bg-gray-800/95 rounded-xl p-6 
                    backdrop-blur-lg border border-gray-200 dark:border-gray-700 
                    shadow-xl w-full max-w-md mx-auto"
      >
        {loading || pendingConnect ? (
          <div className="flex items-center justify-center">
            <div className="h-16 w-16 relative">
              <div className="absolute inset-0 rounded-full border-t-2 border-blue-500 animate-spin"></div>
              <div className="absolute inset-0 rounded-full border-2 border-gray-200 dark:border-gray-700"></div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-4">
            <div
              className="h-16 w-16 bg-green-500/10 rounded-full flex items-center justify-center 
                          ring-2 ring-green-500/20"
            >
              <img
                src={BrainCoinImage}
                alt="BrainCoin"
                className="h-8 w-8"
              />
            </div>
            <div className="flex flex-col items-center gap-4 w-full">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Wallet Connected
              </h2>
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4 w-full">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600 dark:text-gray-400">
                    Balance
                  </span>
                  <img
                    src={BrainCoinImage}
                    alt="BrainCoin"
                    className="h-6 w-6"
                  />
                </div>
                <div className="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
                  {brainCoinBalance || "0"}
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    BRAIN
                  </span>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4 w-full">
                <span className="text-gray-600 dark:text-gray-400 text-sm block mb-2">
                  Wallet Address
                </span>
                <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800/50 rounded p-2">
                  <code className="text-gray-900 dark:text-white font-mono">
                    {user?.eth_address?.slice(0, 6)}...
                    {user?.eth_address?.slice(-4)}
                  </code>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(user?.eth_address || "");
                      toast.success("Address copied!");
                    }}
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 
                             transition-colors p-1.5 hover:bg-gray-200 dark:hover:bg-gray-700/50 rounded"
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <button
                onClick={handleDisconnect}
                className="mt-2 w-full px-4 py-2.5 bg-gradient-to-r from-red-500 to-red-600 
                         text-white rounded-lg hover:from-red-600 hover:to-red-700 
                         transition-all duration-200 flex items-center justify-center gap-2 
                         shadow-lg shadow-red-500/20"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Disconnect Wallet
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
  if (loading || pendingConnect) {
    return (
      <div className="bg-gray-800/50 rounded-xl p-6 backdrop-blur-sm border border-gray-700">
        <div className="flex flex-col items-center gap-4">
          <div className="h-16 w-16 relative">
            <div className="absolute inset-0 rounded-full border-t-2 border-blue-500 animate-spin"></div>
            <div className="absolute inset-0 rounded-full border-2 border-gray-700"></div>
          </div>
          <p className="text-2xl font-bold text-white">Loading Wallet...</p>
          <p className="text-gray-400 text-center">
            Please wait while we connect your wallet
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800/95 rounded-xl p-6 backdrop-blur-lg border border-gray-700 shadow-xl w-full max-w-md mx-auto">
      {isWalletConnected ? (
        <div className="flex flex-col items-center gap-4">
          {loading || pendingConnect ? (
            <div className="flex items-center justify-center">
              <div className="h-16 w-16 relative">
                <div className="absolute inset-0 rounded-full border-t-2 border-blue-500 animate-spin"></div>
                <div className="absolute inset-0 rounded-full border-2 border-gray-700"></div>
              </div>
            </div>
          ) : (
            <>
              <div className="h-16 w-16 bg-green-500/10 rounded-full flex items-center justify-center ring-2 ring-green-500/20">
                <img
                  src={BrainCoinImage}
                  alt="BrainCoin"
                  className="h-8 w-8"
                />
              </div>
              <div className="flex flex-col items-center gap-4 w-full">
                <h2 className="text-2xl font-bold text-white">
                  Wallet Connected
                </h2>
                <div className="bg-gray-700/50 rounded-lg p-4 w-full">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-gray-400">Balance</span>
                    <img
                      src={BrainCoinImage}
                      alt="BrainCoin"
                      className="h-6 w-6"
                    />
                  </div>
                  <div className="text-2xl font-bold text-white flex items-center gap-2">
                    {brainCoinBalance || "undefined"}
                    <span className="text-sm text-gray-400">BRAIN</span>
                  </div>
                </div>
                <div className="bg-gray-700/50 rounded-lg p-4 w-full">
                  <span className="text-gray-400 text-sm block mb-2">
                    Wallet Address
                  </span>
                  <div className="flex items-center justify-between bg-gray-800/50 rounded p-2">
                    <code className="text-white font-mono">
                      {user?.eth_address?.slice(0, 6)}...
                      {user?.eth_address?.slice(-4)}
                    </code>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(user?.eth_address || "");
                        toast.success("Address copied!");
                      }}
                      className="text-gray-400 hover:text-white transition-colors p-1.5 hover:bg-gray-700/50 rounded"
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <button
                  onClick={handleDisconnect}
                  className="mt-2 w-full px-4 py-2.5 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg hover:from-red-600 hover:to-red-700 transition-all duration-200 flex items-center justify-center gap-2 shadow-lg shadow-red-500/20"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  Disconnect Wallet
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center gap-8">
          <div className="h-24 w-24 bg-blue-500/10 rounded-full flex items-center justify-center ring-4 ring-blue-500/20">
            <svg
              className="h-12 w-12 text-blue-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          </div>
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white mb-3">
              Connect Wallet
            </h2>
            <p className="text-gray-400 text-lg">
              Connect your wallet to access BrainCoin® features
            </p>
          </div>
          <button
            onClick={handleConnect}
            className="w-full px-8 py-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-200 flex items-center justify-center gap-3 shadow-lg shadow-blue-500/20 text-lg font-medium"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
            Connect Wallet
          </button>
        </div>
      )}
    </div>
  );
};
