import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { getIsSmScreen } from "helpers/responsiveness";
import { APP_URLS } from "../../navigation";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getMyPlaylists,
  getSharedPlaylists,
} from "redux/playlist/playlistSlice";
import PlaylistListItem from "components/playlist/mobile/PlaylistListItem";
import { useIsSmScreen } from "hooks/useIsSmScreen";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import PlaylistListNewPlaylistItem from "components/playlist/mobile/PlaylistListNewPlaylistItem";

/**
 * Playlist List page for mobile
 */
export default function PlaylistListMobile() {
  const dispatch = useAppDispatch();
  const myPlaylists = useAppSelector((state) => state.playlist.myPlaylists);
  const sharedPlaylists = useAppSelector(
    (state) => state.playlist.sharedPlaylists,
  );

  /**
   * Fetch data
   */
  useEffect(() => {
    document.title = "Playlists | BrainCargo®";
    dispatch(getMyPlaylists({ queryParams: { page_size: "100" } }));
    dispatch(getSharedPlaylists({ queryParams: { page_size: "100" } }));
  }, []);

  if (!getIsSmScreen()) {
    // Force the user to desktop-compatible videos view
    window.location.href = APP_URLS.PLAYLISTS_DESKTOP;
  }

  return (
    <PageWrapper>
      <div>
        <div className="flex flex-col space-y-2 px-4 py-2">
          <h1 className="text-3xl font-bold text-gray-900">Playlists</h1>
          <div className="w-full flex flex-row rounded-xl border-gray-200 border-[1px] bg-white justify-center items-center">
            <MagnifyingGlassIcon className="inline h-6 text-gray-500 px-2" aria-hidden="true" />
            <input
              type="text"
              className="w-full border-0 rounded-r-full bg-transparent pl-0 focus:ring-0"
              placeholder="Search (placeholder)"
              aria-label="Search playlists"
            />
          </div>
        </div>
        <PlaylistListNewPlaylistItem />
        {myPlaylists.length > 0 &&
          myPlaylists.map((playlist) => (
            <PlaylistListItem playlist={playlist} key={playlist.id} />
          ))}
        {sharedPlaylists.length > 0 &&
          myPlaylists.map((playlist) => (
            <PlaylistListItem playlist={playlist} key={playlist.id} />
          ))}
      </div>
    </PageWrapper>
  );
}

<div className="font-bold text-2xl text-neutral-900 text-center">
  Playlist could not be found
</div>;
