import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { generateLoginOTP, verifyOTP } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import "react-international-phone/style.css";
import { setTokens } from "../../services/localStorage";
import { history } from "../../helpers/history";
import { APP_URLS } from "../../navigation";
import { PhoneInput } from "react-international-phone";
import { useNavigate } from "react-router-dom";

/**
 * Login page.
 */

interface OTPLoginProps {
  email?: string;
  phone?: string;
  isSignInFieldEmail?: boolean;
  otpToken?: string;
  onSwitchToRegister?: (phone: string) => void;
}

const OTPLogin: React.FC<OTPLoginProps> = ({
  email = "",
  phone = "",
  isSignInFieldEmail = false,
  otpToken = "",
  onSwitchToRegister,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const verifyOTPErrorMessages = useAppSelector(
    (state) => state.auth.verifyOTPErrorMessages
  );

  const generateOTPErrorMessages = useAppSelector(
    (state) => state.auth.generateOTPErrorMessages
  );

  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [code, setCode] = useState("");
  const [OTPToken, setOTPToken] = useState(otpToken);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [countryCode, setCountryCode] = useState("1");
  const [phoneError, setPhoneError] = useState("");
  const [agreedToSMS, setAgreedToSMS] = useState(true);

  // Reset states when switching between email and phone
  useEffect(() => {
    setShowOTPInput(false);
    setCode("");
    setOTPToken("");
    setPhoneError("");
  }, [isSignInFieldEmail]);

  // Reset OTP input when phone number changes
  useEffect(() => {
    if (!showOTPInput) return;
    setShowOTPInput(false);
    setCode("");
    setOTPToken("");
  }, [phoneNumber]);

  const handlePhoneChange = (value: string, data: any) => {
    if (!value) {
      setPhoneNumber("");
      setCountryCode("1"); // Reset to default
      setPhoneError(""); // Clear any previous errors
      return;
    }

    try {
      // Store the dial code without any plus signs
      const dialCode = (data?.dialCode || "1").replace(/\+/g, "");
      setCountryCode(dialCode);

      // Remove all non-digit characters
      let cleanedValue = value.replace(/[^\d]/g, "");

      // If the value starts with the country code, remove it
      if (cleanedValue.startsWith(dialCode)) {
        cleanedValue = cleanedValue.slice(dialCode.length);
      }

      // Format the phone number as user types XXX-XXX-XXXX
      if (cleanedValue.length > 0) {
        let formattedValue = cleanedValue;
        if (cleanedValue.length > 3) {
          formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(
            3
          )}`;
        }
        if (cleanedValue.length > 6) {
          formattedValue = `${formattedValue.slice(
            0,
            7
          )}-${formattedValue.slice(7)}`;
        }
        cleanedValue = formattedValue;
      }

      setPhoneNumber(cleanedValue);
      setPhoneError(""); // Clear any previous errors
    } catch (error) {
      console.error("Error processing phone number:", error);
      setPhoneError("Error processing phone number");
    }
  };

  const handleRequestCode = async () => {
    try {
      // Ensure we have both countryCode and phone before making the API call
      if (!countryCode || !phoneNumber) {
        setPhoneError("Please enter a valid phone number");
        return;
      }

      if (!agreedToSMS) {
        setPhoneError("Please agree to receive SMS messages");
        return;
      }

      // Make sure to send the full phone number with country code and plus sign
      const fullPhoneNumber = `+${countryCode}${phoneNumber.replace(/-/g, "")}`;
      const payload = isSignInFieldEmail
        ? { email }
        : { phone: fullPhoneNumber };

      // Clear any previous errors
      setPhoneError("");

      const response = await dispatch(generateLoginOTP(payload)).unwrap();

      console.log("OTP Response:", response);

      // Accept both 200 and 201 status codes, or if response is successful without status
      if (
        response.status === 200 ||
        response.status === 201 ||
        (response && !response.status)
      ) {
        setOTPToken(response.token || "");
        setShowOTPInput(true);
        // Show success toast
        toast.success("OTP sent successfully!");
      }
    } catch (error: any) {
      console.error("OTP request error:", error);
      // Handle user not found case and other error responses
      const errorData = error?.response?.data || error;

      if (errorData?.message) {
        setPhoneError(errorData.message);
        if (errorData?.user_not_found) {
          // Show the register button only for user not found case
          return;
        }
      } else if (errorData?.phone) {
        setPhoneError(
          Array.isArray(errorData.phone) ? errorData.phone[0] : errorData.phone
        );
      } else if (errorData?.detail) {
        setPhoneError(errorData.detail);
      } else {
        setPhoneError("Failed to send OTP");
      }

      // Show error toast with the appropriate message
      toast.error(
        errorData?.message || errorData?.detail || "Failed to send OTP"
      );
    }
  };

  const handleCodeVerify = async () => {
    try {
      // Ensure we have both countryCode and phone before making the API call
      if (!countryCode || !phoneNumber) {
        setPhoneError("Please enter a valid phone number");
        return;
      }
      // Make sure to send the full phone number with country code
      const fullPhoneNumber = `+${countryCode}${phoneNumber.replace(/-/g, "")}`;
      const payload = isSignInFieldEmail
        ? { email, otp: code, ...(OTPToken && { token: OTPToken }) }
        : {
            phone: fullPhoneNumber,
            otp: code,
            ...(OTPToken && { token: OTPToken }),
          };

      // Clear any previous errors
      setPhoneError("");

      const response = await dispatch(verifyOTP(payload)).unwrap();

      if (response) {
        const { access, refresh } = response;
        setTokens({ access, refresh });
        // Show success toast
        toast.success("Logged in successfully!");
        history.navigate(APP_URLS.VIDEOS);
      }
    } catch (error: any) {
      console.error("Login error:", error);
      // Handle error from the backend
      if (error?.phone) {
        setPhoneError(
          Array.isArray(error.phone) ? error.phone[0] : error.phone
        );
      } else if (error?.detail) {
        setPhoneError(error.detail);
      } else {
        setPhoneError("Verification failed");
      }
      // Show error toast
      toast.error(error?.detail || "Verification failed");
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (showOTPInput) {
      handleCodeVerify();
    } else {
      handleRequestCode();
    }
  };

  const handlePolicyNavigation = (url: string) => {
    window.open(url, "_blank");
  };

  const handleSMSTermsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handlePolicyNavigation(APP_URLS.SMS_OPT_OUT);
  };

  return (
    <form onSubmit={handleSubmit} aria-label="OTP Login Form">
      <div className="mt-6">
        {!isSignInFieldEmail && (
          <div className="mb-4">
            <style>
              {`
                /* Show only the flag button and hide the country code */
                .react-international-phone-country-selector-button {
                  width: 48px !important;
                  padding: 0 8px !important;
                  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
                  background: white !important;
                }
                
                /* Hide the country code text but keep the flag */
                .react-international-phone-country-selector-button__button-content > span:not(.react-international-phone-country-selector-button__flag) {
                  display: none !important;
                }

                /* Adjust input container for the flag */
                .react-international-phone-input-container {
                  display: flex !important;
                  background: white !important;
                  border-radius: 0.5rem !important;
                }

                /* Style the input field */
                .react-international-phone-input {
                  background: white !important;
                  color: black !important;
                }

                /* Remove any default borders */
                .react-international-phone-country-selector-button__button {
                  border: none !important;
                }

                /* Style the input placeholder */
                .react-international-phone-input::placeholder {
                  color: #6B7280 !important;
                }
              `}
            </style>
            <label className="block text-stone-200 text-sm font-bold mb-2">
              Phone Number
            </label>
            <PhoneInput
              defaultCountry="us"
              value={phoneNumber}
              onChange={handlePhoneChange}
              inputClassName={`
                w-full px-4 py-2 bg-white text-black
                border rounded-lg focus:outline-none focus:border-purple-500
                ${showOTPInput ? "opacity-50 cursor-not-allowed" : ""}
                ${phoneError ? "border-red-500" : ""}
              `}
              disabled={showOTPInput}
              placeholder="Enter phone number"
              disableDialCodeAndPrefix={true}
              style={{ border: "none" }}
            />
            {phoneError && (
              <div className="mt-2">
                <p className="text-red-500 text-sm">
                  {phoneError}
                  {phoneError.includes("create a new account") && (
                    <button
                      type="button"
                      onClick={() => {
                        const fullPhoneNumber = `+${countryCode}${phoneNumber.replace(
                          /-/g,
                          ""
                        )}`;
                        if (onSwitchToRegister) {
                          onSwitchToRegister(fullPhoneNumber);
                        }
                      }}
                      className="ml-2 text-blue-400 hover:text-blue-300 underline"
                    >
                      Register here
                    </button>
                  )}
                </p>
              </div>
            )}

            {/* SMS Opt-in Checkbox */}
            <div className="mt-4">
              <p className="text-sm text-gray-400 mt-4 mb-6 text-left">
                By creating an account you agree to be bound by the{" "}
                <button
                  onClick={() => navigate(APP_URLS.TERMS_OF_SERVICE)}
                  className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                >
                  Terms of Service
                </button>{" "}
                and{" "}
                <button
                  onClick={() => navigate(APP_URLS.PRIVACY_POLICY)}
                  className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                >
                  Privacy Policy
                </button>
              </p>
              <label className="flex items-start gap-3 cursor-pointer">
                <input
                  type="checkbox"
                  checked={agreedToSMS}
                  onChange={(e) => setAgreedToSMS(e.target.checked)}
                  className="mt-1 w-4 h-4 rounded border-2 border-gray-700 bg-gray-800/50
                           text-blue-500 focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-0
                           transition-all duration-200"
                />
                <span className="text-gray-300 text-sm leading-relaxed">
                  I agree to receive SMS messages from BrainCargo® as described
                  in the{" "}
                  <button
                    type="button"
                    onClick={handleSMSTermsClick}
                    className="text-blue-400 hover:text-blue-300 underline font-medium transition-colors"
                  >
                    SMS Terms
                  </button>
                </span>
              </label>
            </div>
          </div>
        )}

        {showOTPInput && (
          <TextInput
            value={code}
            label="Enter Verification Code"
            id="code"
            type="number"
            required
            onChange={(event) => setCode(event.target.value)}
            placeholder="Enter OTP Here"
            aria-label="Enter Verification Code"
            errorMessages={
              verifyOTPErrorMessages?.detail || verifyOTPErrorMessages.otp
            }
          />
        )}

        {showOTPInput && (
          <div
            onClick={handleRequestCode}
            className="flex justify-start py-2 text-sm text-white hover:underline cursor-pointer"
            role="button"
            aria-label="Resend OTP"
          >
            Resend OTP
          </div>
        )}

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
          >
            {showOTPInput ? "Verify Code" : "Send OTP"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default OTPLogin;
