import { Spinner } from "flowbite-react";
import { displayErrors } from "../../helpers/errors";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { retrieveQuizResults } from "../../redux/quiz/quizSlice";
import {
  QuizResults as QuizResultsType,
  QuizResultsAnswerOption,
  QuizResultsQuestion,
} from "../../types/quiz";

interface Props {
  quizId: string | null;
}

/**
 * Component responsible for rendering quiz results.
 */
export default function QuizResults({ quizId }: Props) {
  const dispatch = useAppDispatch();
  const quizResults = useAppSelector((state) => state.quiz.quizResults);
  const pendingRetrieveQuizResults = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizResults
  );
  const retrieveQuizResultsErrorMessages = useAppSelector(
    (state) => state.quiz.retrieveQuizResultsErrorMessages
  );

  /**
   * Calculates ratio of x to y as a percentage.
   */
  function calculateRatio(x: number, y: number) {
    return ((x / y) * 100).toFixed(2);
  }

  /**
   * Renders summary.
   */
  function renderQuizSummary(quizResults: QuizResultsType) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-6">
            Quiz Overview
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Total Users Card */}
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-500/10 rounded-lg">
                  <svg
                    className="w-6 h-6 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Total Users
                  </p>
                  <p className="text-2xl font-bold text-gray-900 dark:text-white">
                    {quizResults.completed_by_count}
                  </p>
                </div>
              </div>
            </div>

            {/* Total Attempts Card */}
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-purple-500/10 rounded-lg">
                  <svg
                    className="w-6 h-6 text-purple-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Total Attempts
                  </p>
                  <p className="text-2xl font-bold text-gray-900 dark:text-white">
                    {quizResults.attempt_count}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders a question.
   */
  function renderQuestion(question: QuizResultsQuestion) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">
            {question.text}
          </h3>
          <div className="space-y-6">
            {question.answer_options.map((option, idx) => (
              <div
                key={idx}
                className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4"
              >
                <div className="space-y-4">
                  {/* Answer Text */}
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600 dark:text-gray-300 font-medium">
                      {option.text}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {option.count} responses
                    </span>
                  </div>

                  {/* Progress Bar */}
                  <div className="relative w-full">
                    <div className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-blue-500 rounded-full transition-all duration-500"
                        style={{
                          width: `${calculateRatio(
                            option.count,
                            quizResults?.attempt_count || 1
                          )}%`,
                        }}
                      />
                    </div>
                    <span className="absolute right-0 -top-6 text-sm font-medium text-gray-500 dark:text-gray-400">
                      {calculateRatio(
                        option.count,
                        quizResults?.attempt_count || 1
                      )}
                      %
                    </span>
                  </div>

                  {/* Open Question Values */}
                  {option.open_question_values.length > 0 && (
                    <div className="mt-4 space-y-2">
                      <p className="text-sm font-medium text-gray-600 dark:text-gray-300">
                        User Responses:
                      </p>
                      <div className="space-y-2">
                        {option.open_question_values.map((text, idx) => (
                          <div
                            key={idx}
                            className="p-3 bg-white dark:bg-gray-700 rounded-lg text-sm text-gray-600 dark:text-gray-300"
                          >
                            {text}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Retrieves quiz results whenever quizId changes.
   */
  useEffect(() => {
    if (quizId) {
      dispatch(retrieveQuizResults({ quizId }));
    }
  }, [quizId]);

  /**
   * Renders a loading spinner if quiz results are being retrieved.
   */
  if (pendingRetrieveQuizResults) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="flex flex-col items-center gap-4">
          <Spinner size="lg" className="text-blue-500" />
          <p className="text-gray-500 dark:text-gray-400">
            Loading quiz results...
          </p>
        </div>
      </div>
    );
  }

  /**
   * Renders error messages if there are any.
   */
  if (
    retrieveQuizResultsErrorMessages &&
    Object.keys(retrieveQuizResultsErrorMessages).length > 0
  ) {
    return (
      <div className="p-4 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-xl">
        {Object.keys(retrieveQuizResultsErrorMessages).map((key) => (
          <div key={key} className="text-red-600 dark:text-red-400">
            {displayErrors(retrieveQuizResultsErrorMessages[key])}
          </div>
        ))}
      </div>
    );
  }

  /**
   * Renders error message if there are no quiz results.
   */
  if (quizResults === null) {
    return (
      <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded-xl">
        <p className="text-yellow-600 dark:text-yellow-400">
          No results available for this quiz
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {renderQuizSummary(quizResults)}
      {quizResults.questions.map((question, idx) => (
        <React.Fragment key={idx}>{renderQuestion(question)}</React.Fragment>
      ))}
    </div>
  );
}
