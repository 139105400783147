export enum LoginMethod {
  WALLET = 'wallet',
  OTP = 'otp',
  TRADITIONAL = 'traditional'
}

interface FeatureFlags {
  loginMethod: LoginMethod;
  enableGoogleLogin: boolean;
  enableFacebookLogin: boolean;
  enablePhoneAuth: boolean;
}

// Helper function to parse login method from environment variable
const parseLoginMethod = (value: string | undefined): LoginMethod => {
  if (!value) return LoginMethod.TRADITIONAL; // Default value
  
  const method = value.toLowerCase();
  switch (method) {
    case LoginMethod.WALLET:
      return LoginMethod.WALLET;
    case LoginMethod.OTP:
      return LoginMethod.OTP;
    case LoginMethod.TRADITIONAL:
      return LoginMethod.TRADITIONAL;
    default:
      console.warn(`Invalid login method "${value}" specified in environment variable. Using default.`);
      return LoginMethod.TRADITIONAL;
  }
};

// Default configuration with environment variables
const defaultFlags: FeatureFlags = {
  loginMethod: parseLoginMethod(process.env.REACT_APP_LOGIN_METHOD),
  enableGoogleLogin: process.env.REACT_APP_ENABLE_GOOGLE_LOGIN === 'true',
  enableFacebookLogin: process.env.REACT_APP_ENABLE_FACEBOOK_LOGIN === 'true',
  enablePhoneAuth: process.env.REACT_APP_ENABLE_PHONE_AUTH === 'true',
};

// You can override these values based on environment or other conditions
let currentFlags = { ...defaultFlags };

export const getFeatureFlags = (): FeatureFlags => currentFlags;

export const setFeatureFlags = (flags: Partial<FeatureFlags>) => {
  currentFlags = { ...currentFlags, ...flags };
};

// Helper function to check which login method is active
export const isLoginMethodEnabled = (method: LoginMethod): boolean => {
  return currentFlags.loginMethod === method;
}; 